import history from '@history';
import _ from '@lodash';
import * as FuseActions from 'app/store/actions/fuse';

export const SET_USER_DATA = '@USER/SET DATA';
export const REMOVE_USER_DATA = '@USER/REMOVE DATA';
export const USER_LOGGED_OUT = '@USER/LOGGED OUT';
export const SET_ROLE = '@USER/SET_ROLE';
export const SET_PERMISSION = '@USER/SET_PERMISSION';
export const SET_SYNC_STATUS = '@USER/SET_SYNC_STATUS';
export const SET_DISPENSING_SYSTEM = '@USER/SET_DISPENSING_SYSTEM';

/**
 * Set User Role
 */
export function setRole(role) {
  return (dispatch) => {
    dispatch({
      type: SET_ROLE,
      payload: { role },
    });
  };
}

/**
 * Set User Data
 */
export function setUserData(user) {
  return (dispatch) => {
    /*
        You can redirect the logged-in user to a specific route depending on his role
         */

    // history.location.state = {
    //     redirectUrl: user.redirectUrl // for home 'apps/academy'
    // }

    /*
        Set User Settings
         */
    // dispatch(FuseActions.setDefaultSettings(user.data.settings));

    /*
        Set User Data
         */
    dispatch({
      type: SET_USER_DATA,
      payload: user,
    });
  };
}

/**
 * Update User Settings
 */
export function updateUserSettings(settings) {
  return (dispatch, getState) => {
    const oldUser = getState().auth.user;
    const user = _.merge({}, oldUser, { data: { settings } });

    updateUserData(user, dispatch);

    return dispatch(setUserData(user));
  };
}

/**
 * Update User Shortcuts
 */
export function updateUserShortcuts(shortcuts) {
  return (dispatch, getState) => {
    const { user } = getState().auth;
    const newUser = {
      ...user,
      data: {
        ...user.data,
        shortcuts,
      },
    };

    updateUserData(newUser, dispatch);

    return dispatch(setUserData(newUser));
  };
}

/**
 * Remove User Data
 */
export function removeUserData() {
  return {
    type: REMOVE_USER_DATA,
  };
}

/**
 * Logout
 */
export function logoutUser() {
  return (dispatch, getState) => {
    const { user } = getState().auth;

    if (!user.role || user.role.length === 0) {
      // is guest
      return null;
    }

    dispatch(FuseActions.setInitialSettings());
    window.localStorage.setItem('isLogined', false);

    history.push({
      pathname: '/auth/login',
    });

    // jwtService.logout();

    return dispatch({
      type: USER_LOGGED_OUT,
    });
  };
}

/**
 * Update User Data
 */
function updateUserData(user, dispatch) {
  if (!user.role || user.role.length === 0) {
    // is guest
    return;
  }

  // jwtService
  // 			.updateUserData(user)
  // 			.then(() => {
  // 				dispatch(MessageActions.showMessage({ message: 'User data saved with api' }));
  // 			})
  // 			.catch(error => {
  // 				dispatch(MessageActions.showMessage({ message: error.message }));
  // 			});
}

/**
 * Set User Permission
 */
export function setPermission(permission) {
  return (dispatch) => {
    dispatch({
      type: SET_PERMISSION,
      payload: { permission },
    });
  };
}

export function setSyncStatus(syncStatus, syncVersion) {
  return (dispatch) => {
    dispatch({
      type: SET_SYNC_STATUS,
      payload: { syncStatus, syncVersion },
    });
  };
}

export function setDispensingSystem(dispensingSystem) {
  return (dispatch) => {
    dispatch({
      type: SET_DISPENSING_SYSTEM,
      payload: { dispensingSystem },
    });
  };
}
