import { HttpService } from 'app/services';
import * as AppActions from 'app/store/actions';
import { parseError } from 'helpers/Error';

export const CREATE_ROLE = '@ROLE/CREATE_ROLE';
export const CREATE_ROLE_SUCCESS = '@ROLE/CREATE_ROLE_SUCCESS';
export const CREATE_ROLE_FAIL = '@ROLE/CREATE_ROLE_FAIL';

export const CHANGE_ROLE_FORM = '@ROLE/CHANGE_ROLE_FORM';
export const CLEAR_CREATE_FORM = '@ROLE/CLEAR_CREATE_FORM';

export function createRole() {
  return (dispatch, getState) => {
    const { data } = getState().role.create;
    const permissions = data.permissions.map((i) => {
      return {
        key: i.key,
        value: [+i.create, +i.read, +i.update, +i.delete],
      };
    });
    const postData = {
      name: data.name,
      permissions,
      isChangeCommentRequired: data.isChangeCommentRequired,
    };

    dispatch({ type: CREATE_ROLE });
    HttpService.post(`api/roles`, postData)
      .then((resp) => {
        dispatch(
          AppActions.showMessage({
            message: 'role:ADD_ROLE_SUCCESS',
          }),
        );
        dispatch({ type: CREATE_ROLE_SUCCESS, payload: { roleId: resp.data.id } });
      })
      .catch((err) => {
        const { errorFields, errorMessages, errorTitle } = parseError(err);
        dispatch({
          type: CREATE_ROLE_FAIL,
          payload: { message: errorMessages.join('\n'), error: errorFields },
        });
        dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
      });
  };
}

export function clearCreateForm() {
  return (dispatch) => {
    dispatch({ type: CLEAR_CREATE_FORM });
  };
}

export function changeRoleCreateForm(data) {
  return (dispatch) => {
    dispatch({ type: CHANGE_ROLE_FORM, payload: { data } });
  };
}
