import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { startOfDay, addHours, addMinutes } from 'date-fns';
import { TIMEZONE_LIST } from 'app/main/pharmacy/constants';

const usePharmacyTimezone = () => {
  const { currentTimezone } = useSelector((state) => state.auth.user.data);
  const { offset, offsetMinutes } = useMemo(
    () => TIMEZONE_LIST.find((v) => v.value === currentTimezone),
    [currentTimezone],
  );

  function parseToPharmacyTimezone(date) {
    // MC-936700 Convert to mid-day then convert to pharmacy timezone
    const localTimezone = new Date().getTimezoneOffset() * -1;
    return addMinutes(addHours(startOfDay(new Date(date)), 12), localTimezone - offsetMinutes);
  }

  function parseToLocalTimezone(date) {
    /**
     * Need to calculate the difference between local timezone with pharmacy timezone
     * to make sure we have same day
     * Example: Local timezone GMT-6, Pharmacy timezone GMT+8, input: 2000-08-21T04:00:00.000Z (in pharmacy timezone)
     * ==> Without addMinutes: Sun Aug 20 2000 22:00:00 GMT-0600 => Wrong
     * ==> With addMinutes: Mon Aug 21 2000 12:00:00 GMT-0600 => Right
     */
    const localTimezone = new Date().getTimezoneOffset() * -1;
    return addMinutes(new Date(date), offsetMinutes - localTimezone);
  }

  return { parseToPharmacyTimezone, parseToLocalTimezone };
};

export default usePharmacyTimezone;
