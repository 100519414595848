import { combineReducers } from 'redux';
import list from './list.reducer';
import create from './create.reducer';
import search from './search.reducers';
import update from './update.reducer';
import quickAdd from './quickAdd.reducer';

const drugReducers = combineReducers({
  list,
  create,
  search,
  update,
  quickAdd,
});

export default drugReducers;
