import { get, flatten, camelCase } from 'lodash';

const parseError = (error) => {
  error = error || new Error('Unhandled Error');
  let errors = [error.message];
  const errorData = error.response;
  if (get(errorData, 'data.message')) {
    errors = [get(errorData, 'data.message')];
  }
  if (get(errorData, 'data.data') && get(errorData, 'data.data').length > 0) {
    errors = get(errorData, 'data.data').reduce((prev, cur) => {
      prev.push({
        field: camelCase(cur.key.replace('model.', '').replace(/\[\d+\]/gi, '')),
        errors: get(cur, 'value.errors').map((errDt) => {
          return (
            get(errDt, 'exception.Message') ||
            get(errDt, 'exception.InnerException.Message') ||
            get(errDt, 'errorMessage')
          );
        }),
      });
      return prev;
    }, []);
  }

  const errorByFields = errors[0].field
    ? errors.reduce((p, c) => {
        p[c.field] = c.errors.join('\n');
        return p;
      }, {})
    : null;

  return {
    errorFields: errorByFields,
    errorMessages: errors[0].field ? flatten(errors.map((e) => e.errors, {})) : errors,
    errorTitle: 'Error',
    errorIncludedFields:
      errorByFields &&
      Object.keys(errorByFields).reduce((p, c) => {
        p.push(`${c} - ${errorByFields[c].replace(/\n/gi, ',')}`);
        return p;
      }, []),
  };
};

const parseResponseErrorMessage = (error) => {
  let errorMessages = [error.message];
  const errorData = error.response;
  if (get(errorData, 'data.message')) {
    errorMessages = [get(errorData, 'data.message')];
  }
  if (get(errorData, 'data.data') && get(errorData, 'data.data').length > 0) {
    errorMessages = get(errorData, 'data.data')
      .reduce((prev, cur) => {
        const errors = get(cur, 'value.errors').map((errDt) => {
          return get(errDt, 'error.exception.InnerException.Message') || errDt.errorMessage;
        });
        const field = camelCase(cur.key.replace('model.', '').replace(/\[\d+\]/gi, ''));

        prev.push({
          field,
          errors,
        });
        return prev;
      }, [])
      .map((prev, cur) => {
        prev.push(cur.errorMessage);
        return prev;
      }, []);
  }

  return errorMessages.join('\n');
};

const parseErrorFields = (error) => {
  let fields = {};
  const errorData = error.response;
  if (get(errorData, 'data.data') && get(errorData, 'data.data').length > 0) {
    fields = get(errorData, 'data.data').reduce((prev, cur) => {
      const field = camelCase(cur.key.replace('model.', ''));
      if (field) {
        const errMsg = cur.value.errors.map((e) => e.errorMessage).join('\n');
        prev[field] = errMsg;
      }

      return prev;
    }, {});
  }
  return Object.keys(fields).length === 0 ? null : fields;
};

// eslint-disable-next-line import/prefer-default-export
export { parseError, parseResponseErrorMessage, parseErrorFields };
