import * as PatientCreateActions from '../actions/create.actions';
import { DAA_FUNDING } from '../../constants';

const initialState = {
  id: '',
  data: {
    email: '',
    dob: null,
    firstName: '',
    lastName: '',
    preferredName: '',
    packingStatus: '',
    patientStatus: '',
    photoId: '',
    patientGroupId: '',
    patientGroupName: '',
    patientGroupType: '',
    area: '',
    facilityWard: '',
    room: '',
    urn: '',
    ihi: '',
    photoTakenDate: null,
    suspendFromDate: null,
    suspendToDate: null,
    medicareNumber: '',
    dvaNum: '',
    diagnosis: [],
    gender: '',
    daaFunding: DAA_FUNDING.CPA_VARIOUS,
    street: '',
    suburb: '',
    state: '',
    country: '',
    postalCode: '',
    ward: '',
    phone: '',
    concessionNum: '',
    safetyNetNum: '',
    diabeticNum: '',
    repatNum: '',
    externalPatientId: '',
    mediCareExpiry: null,
    dateImported: null,
    title: '',
    concession: 0,
    isVisionImpaired: 'false',
    days30Dispensing: 'false',
    isAnySimilarNamePatient: false,
  },
  loading: false,
  error: null,
};

const create = (state = initialState, action) => {
  switch (action.type) {
    case PatientCreateActions.CREATE_PATIENT: {
      return {
        ...state,
        loading: true,
      };
    }
    case PatientCreateActions.CREATE_PATIENT_SUCCESS: {
      return {
        ...state,
        id: action.payload.patientId,
        loading: false,
      };
    }
    case PatientCreateActions.CREATE_PATIENT_FAIL: {
      return {
        ...state,
        error: action.payload.message,
        loading: false,
      };
    }
    case PatientCreateActions.UPDATE_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload.data,
        },
      };
    case PatientCreateActions.CLEAR_CREATE_FORM:
      return initialState;
    default: {
      return state;
    }
  }
};

export default create;
