import FuseUtils from '@fuse/utils/FuseUtils';

class EventService extends FuseUtils.EventEmitter {
  constructor() {
    super();
  }
}

const instance = new EventService();

export default instance;
