import { HttpService } from 'app/services';
import * as AppActions from 'app/store/actions';
import { parseError } from 'helpers/Error';

export const SET_USERS_FILTER = '@USER/SET_USERS_FILTER';
export const RESET_USERS_FILTER = '@USER/RESET_USERS_FILTER';

export const REMOVE_USER = '@USER/REMOVE_USER';
export const REMOVE_USER_SUCCESS = '@USER/REMOVE_USER_SUCCESS';
export const REMOVE_USER_FAIL = '@USER/REMOVE_USER_FAIL';

export const setUsersFilter = (filter) => {
  return (dispatch) => {
    dispatch({ type: SET_USERS_FILTER, payload: { filter } });
  };
};

export const resetUsersFilter = () => {
  return (dispatch) => {
    dispatch({ type: RESET_USERS_FILTER });
  };
};

export const removeUser = (user) => {
  return (dispatch) => {
    HttpService.abort('removeUser');
    dispatch({ type: REMOVE_USER });
    HttpService.delete(`api/users/${user}`, { cancelToken: 'removeUser' })
      .then(() => {
        dispatch(
          AppActions.showMessage({
            message: 'user:ARCHIVE_USER_SUCCESS',
          }),
        );
        dispatch({ type: REMOVE_USER_SUCCESS });
        dispatch(AppActions.dataLoader_reload(`GET:/api/users`));
      })
      .catch((err) => {
        const { errorFields, errorMessages, errorTitle } = parseError(err);
        dispatch({
          type: REMOVE_USER_FAIL,
          payload: { message: errorMessages.join('\n'), error: errorFields },
        });
        dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
      });
  };
};
