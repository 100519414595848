import React from 'react';
import { authRoles, PERMISSION } from 'app/auth';
import i18next from 'i18next';
import { MENU_ENUM } from 'app/main/role/constants';
import en from '../i18n/en';

i18next.addResourceBundle('en', 'doctor', en);

const DoctorListConfig = {
  settings: {
    layout: {
      config: {},
    },
    nav: {
      title: 'Doctors',
    },
  },
  auth: authRoles.user,
  key: MENU_ENUM.DOCTOR,
  permission: PERMISSION.READ,
  routes: [
    {
      path: '/doctor/list',
      component: React.lazy(() => import('./DoctorList')),
    },
  ],
};

export default DoctorListConfig;
