import subMonths from 'date-fns/subMonths';
import * as PrintReportActions from '../actions/print.actions';

const defaultQuickPrintReport = {
  daaFunding: 0,
  facilityWard: '',
  firstName: '',
  lastName: '',
  packingStatus: 0,
  patientGroupId: '',
  patientGroupName: '',
  patientId: '',
  patientStatus: 0,
  photoId: '',
  room: '',
};

const initialState = {
  data: {
    fromDate: new Date(subMonths(Date.now(), 1)),
    toDate: new Date(),
    isIncludePacked: true,
    isIncludeNonePacked: true,
    isIncludePackedPRN: true,
    isIncludeNonePackedPRN: true,
    isPrintIndications: true,
    patientGroupIds: [],
    wards: [],
    listPatientIds: [],
    reportSettingId: '',
    reportTitle: '',
    printDate: new Date(),
  },
  submitting: false,
  error: null,
  message: '',
  fileName: '',
  fileUrl: '',
  quickPrintReport: defaultQuickPrintReport,
};

const print = (state = initialState, action) => {
  switch (action.type) {
    case PrintReportActions.PRINT_REPORT:
      return {
        ...state,
        submitting: true,
        error: null,
        message: '',
        fileName: '',
        fileUrl: '',
      };
    case PrintReportActions.PRINT_REPORT_SUCCESS:
      return {
        ...state,
        submitting: false,
        fileName: action.payload.data.fileName,
        fileUrl: action.payload.data.fileUrl,
      };
    case PrintReportActions.PRINT_REPORT_FAIL:
      return {
        ...state,
        submitting: false,
        error: action.payload.error,
        message: action.payload.message,
      };
    case PrintReportActions.CHANGE_PRINT_REPORT_FORM:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload.data,
        },
      };
    case PrintReportActions.RESET_PRINT_REPORT_FORM:
      return initialState;
    case PrintReportActions.SET_QUICK_PRINT_REPORT:
      return {
        ...state,
        data: {
          ...state.data,
          listPatientIds: [action.payload.data.patientId],
        },
        quickPrintReport: action.payload.data,
      };
    case PrintReportActions.CLEAR_QUICK_PRINT_REPORT:
      return { ...state, quickPrintReport: defaultQuickPrintReport };
    case PrintReportActions.CLEAR_HISTORY_FILE:
      return { ...state, fileName: null, fileUrl: null };
    default: {
      return state;
    }
  }
};

export default print;
