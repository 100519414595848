import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as AppActions from 'app/store/actions';
import { useTranslation } from 'react-i18next';
import { defDialogValue } from 'app/store/reducers/dialog-message.reducer';

const useDialogMessage = (id) => {
  const dispatch = useDispatch();
  const [dialogId, setDialogId] = useState(() => {
    if (id) return id;
    return new Date().getTime();
  });
  const { open, action, callback } = useSelector((state) => state.dialogMessage.dialogs[dialogId] || defDialogValue);

  const { t } = useTranslation();

  const openDialog = () => {
    // dispatch();
  };

  // const init = () => {
  // 	dispatch(AppActions.newDialogMessageInstance(dialogId, { hook: true }));
  // }

  const closeDialog = () => {
    dispatch(AppActions.closeDialogMessage(dialogId));
  };

  const warning = (message, title) => {
    dispatch(AppActions.createDialogMessage('warning', title, message, { dialogId }));
  };

  const error = (message, title) => {
    dispatch(AppActions.createDialogMessage('error', title, message, { dialogId }));
  };

  const success = (message, title) => {
    dispatch(AppActions.createDialogMessage('success', title, message, { dialogId }));
  };

  const info = (message, title) => {
    dispatch(AppActions.createDialogMessage('info', title, message, { dialogId }));
  };

  const destroy = () => {
    dispatch(AppActions.clearDialogMessage(dialogId));
  };

  const confirm = (
    message,
    title,
    options = {
      buttons: [{ label: t('BUTTON_CANCEL') }, { label: t('BUTTON_CONFIRM'), color: 'primary', variant: 'contained' }],
    },
    type = 'error',
  ) => {
    dispatch(AppActions.createDialogMessage(type, title, message, { options, dialogId }));
    return {
      // eslint-disable-next-line no-return-assign
      on: (cb) => dispatch(AppActions.setDialogMessageCallback(dialogId, cb)),
    };
  };

  const deletedAndReverse = (
    message,
    title,
    options = {
      buttons: [{ label: t('BUTTON_CANCEL') }, { label: t('BUTTON_CONFIRM'), color: 'primary', variant: 'contained' }],
    },
    type = 'error',
  ) => {
    dispatch(AppActions.createDialogMessage(type, title, message, { options, dialogId }));
    return {
      on: (cb) => dispatch(AppActions.setDialogMessageCallback(dialogId, cb)),
    };
  };

  const skipAndDelete = (
    message,
    title,
    options = {
      buttons: [
        { label: t('BUTTON_CANCEL') },
        { label: t('BUTTON_SKIP'), color: 'primary', theme: 'automation', variant: 'contained' },
        { label: t('BUTTON_DELETE'), color: 'primary', theme: 'cinnamon', variant: 'contained' },
      ],
    },
    type = 'error',
  ) => {
    dispatch(AppActions.createDialogMessage(type, title, message, { options, dialogId }));
    return {
      on: (cb) => dispatch(AppActions.setDialogMessageCallback(dialogId, cb)),
    };
  };

  const errorDeletedPack = (
    message,
    title,
    options = {
      buttons: [{ label: t('BUTTON_CLOSE') }],
    },
    type = 'error',
  ) => {
    dispatch(AppActions.createDialogMessage(type, title, message, { options, dialogId }));
    return {
      on: (cb) => dispatch(AppActions.setDialogMessageCallback(dialogId, cb)),
    };
  };

  useEffect(() => {
    if (!open && callback) {
      callback(action);
    }

    // eslint-disable-next-line
  }, [open]);

  // // Create new dialog instance
  // useEffect(() => {
  // 	init()

  // 	return () => {
  // 		destroy()
  // 	}
  // }, []);

  return {
    isOpen: open,
    open: openDialog,
    warning,
    error,
    success,
    info,
    confirm,
    destroy,
    close: closeDialog,
    deletedAndReverse,
    skipAndDelete,
    errorDeletedPack,
  };
};

export default useDialogMessage;
