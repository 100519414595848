import FuseUtils from '@fuse/utils/FuseUtils';
import _ from '@lodash';

/* eslint-disable camelcase */

class AuthService extends FuseUtils.EventEmitter {
  constructor() {
    super();
    this.store = sessionStorage;
  }

  setStore(store) {
    this.store = store;
  }

  getToken() {
    return this.store.getItem('token');
  }

  getRefreshToken() {
    return this.store.getItem('refreshToken');
  }

  setToken(token) {
    this.store.setItem('token', token);
  }

  setRefreshToken(token) {
    this.store.setItem('refreshToken', token);
  }

  clearToken() {
    this.store.removeItem('token');
    this.store.removeItem('refreshToken');
  }

  isAuth() {
    return !_.isEmpty(this.getToken());
  }

  checkAuth() {
    // set localStorage as default to verify if user has remember their password or not
    this.setStore(localStorage);

    if (!this.isAuth()) {
      // Token not found
      this.emit('checkFailed');
      this.setStore(sessionStorage);
    } else {
      this.emit('checkSuccess', this.getToken());
    }
  }
}

const instance = new AuthService();

export default instance;
