import { HttpService } from 'app/services';
import * as AppActions from 'app/store/actions';
import { isNull, omitBy, isArray, compact, toString } from 'lodash';
import { parseError } from '../../../../../helpers/Error';

export const CREATE_PATIENT = '@PATIENT/CREATE_PATIENT';
export const CREATE_PATIENT_SUCCESS = '@PATIENT/CREATE_PATIENT_SUCCESS';
export const CREATE_PATIENT_FAIL = '@PATIENT/CREATE_PATIENT_FAIL';

export const UPDATE_DATA = '@PATIENT/UPDATE_DATA';

export const CLEAR_CREATE_FORM = '@PATIENT/CLEAR_CREATE_FORM';

export function createPatient() {
  return (dispatch, getState) => {
    const { data } = getState().patient.create;
    const postData = omitBy(
      {
        ...data,
        isVisionImpaired: data.isVisionImpaired === 'true',
        days30Dispensing: data.days30Dispensing,
        externalPatientId: data.externalPatientId || 0,
      },
      isNull,
    ); // remove null date fields to prevent post error
    if (isArray(postData.diagnosis)) postData.diagnosis = compact(postData.diagnosis);

    const cb = () => {
      dispatch({ type: CREATE_PATIENT });
      HttpService.post('/api/patients', postData)
        .then((res) => {
          dispatch({ type: CREATE_PATIENT_SUCCESS, payload: { patientId: res.data.patientId } });
          dispatch(
            AppActions.showMessage({
              message: 'patient:CREATE_PATIENT_SUCCESSFULLY_MESSAGE',
              autoHideDuration: 4000,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'success',
            }),
          );
        })
        .catch((err) => {
          const { errorFields, errorMessages } = parseError(err);
          dispatch({ type: CREATE_PATIENT_FAIL, payload: { message: errorMessages.join('\n'), error: errorFields } });
          dispatch(AppActions.createDialogMessage('error', 'Error', errorMessages));
        });
    };

    if (toString(postData.externalPatientId) !== '0') {
      dispatch(externalCheck(null, postData.externalPatientId || null, cb));
    } else {
      cb();
    }
  };
}

export function clearCreateForm() {
  return (dispatch) => {
    dispatch({ type: CLEAR_CREATE_FORM });
  };
}

export function updateData(data) {
  return (dispatch) => {
    dispatch({ type: UPDATE_DATA, payload: { data } });
  };
}

export function externalCheck(patientId, externalPatientId, cb) {
  return (dispatch) =>
    HttpService.post('/api/patients/external-check', { patientId, externalPatientId })
      .then((resp) => {
        if (!resp.isSuccess) throw resp;

        cb();
      })
      .catch((err) => {
        const { errorTitle, errorMessages } = parseError(err);
        dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
      });
}
