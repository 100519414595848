// import { FREQUENCIES_VALUE } from 'app/main/patient/constants';
import * as PrintActions from '../actions';
import { PRINT_ONLY, DOSE_TIME } from '../../constants';

export const defaultPrintFoilForm = {
  frequency: [],
  patientGroupId: [],
  wardId: [],
  foils: [],
  foildIds: [],
  packStartDate: null,
  numberOfWeek: '1',
  partWeekPack: '',
  printOnly: PRINT_ONLY.STANDARD,
  expiryDate: null,
  doseTimeOption: [DOSE_TIME.BREAKFAST, DOSE_TIME.LUNCH, DOSE_TIME.DINNER, DOSE_TIME.BEDTIME],
  printPrescriptionIds: [],
  printDate: '', // Initialized in component (print\components\PrintFoil\PrintFoil.js)
};

const defaultQuickPrintFoil = {
  facilityWard: '',
  firstName: '',
  foilId: '',
  foilLabel: '',
  lastName: '',
  note: null,
  packType: null,
  patientGroupId: '',
  patientGroupName: '',
  patientId: '',
  printPartial: false,
  room: '',
  suspendFromDate: '',
  suspendToDate: '',
  inCompleteProfileOrNoDoses: false,
};

const initialState = {
  submitting: false,
  submitSuccess: false,
  submitJobSuccess: false,
  error: null,
  message: '',
  loading: false,
  printFoilForm: { ...defaultPrintFoilForm },
  fileUrl: '',
  fileName: '',
  quickPrintFoil: defaultQuickPrintFoil,
};

const printFoil = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    // PRINT_FOIL
    case PrintActions.PRINT_FOIL:
      return { ...state, submitting: true, submitSuccess: false, fileName: '' };
    case PrintActions.PRINT_FOIL_SUCCESS:
      return {
        ...state,
        submitting: false,
        submitSuccess: true,
        fileName: payload.data.fileName,
        fileUrl: payload.data.fileUrl,
      };
    case PrintActions.PRINT_FOIL_FAIL:
      return {
        ...state,
        submitting: false,
        submitSuccess: false,
        message: payload.message,
        error: payload.error,
        fileUrl: '',
      };
    // UPDATE_PRINT_FOIL_FORM
    case PrintActions.UPDATE_PRINT_FOIL_FORM:
      return { ...state, loading: false, printFoilForm: { ...state.printFoilForm, ...payload.data } };
    // SET_QUICK_PRINT_FOIL
    case PrintActions.SET_QUICK_PRINT_FOIL:
      return { ...state, quickPrintFoil: payload.data };
    case PrintActions.CLEAR_QUICK_PRINT_FOIL:
      return { ...state, quickPrintFoil: defaultQuickPrintFoil };
    // CREATE_JOB
    case PrintActions.CREATE_JOB:
      return { ...state, submitting: true, submitJobSuccess: false };
    case PrintActions.CREATE_JOB_SUCCESS:
      return { ...state, submitting: false, submitJobSuccess: true };
    case PrintActions.CREATE_JOB_FAIL:
      return { ...state, submitting: false, submitJobSuccess: false, message: payload.message, error: payload.error };
    // CLEAR_HISTORY_FILE
    case PrintActions.CLEAR_HISTORY_FILE:
      return { ...state, fileName: null, fileUrl: null };
    // CLEAR_PRINT_FOIL_DATA
    case PrintActions.CLEAR_PRINT_FOIL_DATA:
      return initialState;
    default:
      return state;
  }
};

export default printFoil;
