import * as vpbActions from '../actions';

const initialState = {
  loading: false,
  submitting: false,
  submitSuccess: false,
  // To distinguish which action is executed,
  // for now it is used in ManualAdjustModal
  lastAction: null,
  filter: {
    fromDate: null,
    toDate: null,
  },
  data: {
    fullName: '',
    currentBalanceQty: '',
    medication: '',
  },
};

export default function history(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case vpbActions.VPB_HISTORY_CHANGE_FILTER:
      return { ...state, filter: { ...state.filter, ...payload } };
    case vpbActions.VPB_HISTORY_RESET_DATA:
      return initialState;
    case vpbActions.VPB_HISTORY_UPDATE_DATA:
      return { ...state, data: { ...state.data, ...payload } };
    // Get VPB
    case vpbActions.VPB_HISTORY_GET_VPB:
      return { ...state, loading: true };
    case vpbActions.VPB_HISTORY_GET_VPB_SUCCESS:
      return { ...state, loading: false, data: { ...state.data, ...payload } };
    case vpbActions.VPB_HISTORY_GET_VPB_FAIL:
      return { ...state, loading: false };
    // Manual Adjust
    case vpbActions.VPB_HISTORY_MANUAL_ADJUST:
      return { ...state, submitting: true, submitSuccess: false, lastAction: null };
    case vpbActions.VPB_HISTORY_MANUAL_ADJUST_SUCCESS:
      return { ...state, submitting: false, submitSuccess: true, lastAction: payload };
    case vpbActions.VPB_HISTORY_MANUAL_ADJUST_FAIL:
      return { ...state, submitting: false, submitSuccess: false };
    // Revert history
    case vpbActions.VPB_HISTORY_REVERT:
      return { ...state, submitting: true, submitSuccess: false, lastAction: null };
    case vpbActions.VPB_HISTORY_REVERT_SUCCESS:
      return { ...state, submitting: false, submitSuccess: true, lastAction: payload };
    case vpbActions.VPB_HISTORY_REVERT_FAIL:
      return { ...state, submitting: false, submitSuccess: false };
    default:
      return state;
  }
}
