import React from 'react';
import i18next from 'i18next';
import { authRoles } from 'app/auth';
import en from './i18n/en';

i18next.addResourceBundle('en', 'login', en);

const LoginConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  auth: authRoles.onlyGuest,
  routes: [
    {
      path: '/auth/login',
      component: React.lazy(() => import('./Login')),
    },
  ],
};

export default LoginConfig;
