import i18next from 'i18next';
import { HttpService } from 'app/services';
import * as AppActions from 'app/store/actions';
import { compact, isArray, isNull, omitBy, find, toString } from 'lodash';
import { parseError } from 'helpers/Error';

import { externalCheck } from './create.actions';

export const UPDATE_PATIENT_DETAILS_DATA = '@PATIENT/UPDATE_PATIENT_DETAILS_DATA';
// export const UPDATE_PATIENT_DETAILS = '@PATIENT/UPDATE_PATIENT_DETAILS';
export const UPDATE_PATIENT_DETAILS_START = '@PATIENT/UPDATE_PATIENT_DETAILS_START';
export const UPDATE_PATIENT_DETAILS_SUCCESS = '@PATIENT/UPDATE_PATIENT_DETAILS_SUCCESS';
export const UPDATE_PATIENT_DETAILS_FAILED = '@PATIENT/UPDATE_PATIENT_DETAILS_FAILED';

export const UPDATE_PAITENT_PACKING_STATUS = '@PATIENT/UPDATE_PAITENT_PACKING_STATUS';

export const UPDATE_REMOVE_PATIENT = '@PATIENT/UPDATE_REMOVE_PATIENT';
export const UPDATE_REMOVE_PATIENT_SUCCESS = '@PATIENT/UPDATE_REMOVE_PATIENT_SUCCESS';
export const UPDATE_REMOVE_PATIENT_FAIL = '@PATIENT/UPDATE_REMOVE_PATIENT_FAIL';

export function updatePatientDetailsData(data) {
  return (dispatch) => {
    dispatch({ type: UPDATE_PATIENT_DETAILS_DATA, payload: { data } });
  };
}

export function updatePatientDetails(id) {
  return (dispatch, getState) => {
    const { details } = getState().patient.update;
    const { data: wardsData } = getState().dataLoader.dataLoaders['GET:WARDS'];
    const postData = omitBy(
      {
        ...details.data,
        isVisionImpaired: details.data.isVisionImpaired === 'true',
        externalPatientId: details.data.externalPatientId || 0,
      },
      isNull,
    ); // remove null date fields to prevent post error
    if (isArray(postData.diagnosis)) postData.diagnosis = compact(postData.diagnosis);

    const cb = () => {
      dispatch({ type: UPDATE_PATIENT_DETAILS_START });
      HttpService.put(`/api/patients/${id}`, postData)
        .then(() => {
          // Transform ward from id to label
          const _data = {
            ...details.data,
            facilityWard: find(wardsData, { id: details.data?.facilityWard })?.name || details.data.facilityWard,
          };
          dispatch({ type: UPDATE_PATIENT_DETAILS_SUCCESS, payload: { data: _data } });
          dispatch(
            AppActions.showMessage({
              message: 'patient:UPDATE_PATIENT_DETAILS_SUCCESSFUL',
              autoHideDuration: 4000,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              variant: 'success',
            }),
          );
        })
        .catch((err) => {
          const { errorMessages, errorFields } = parseError(err);
          dispatch({
            type: UPDATE_PATIENT_DETAILS_FAILED,
            payload: { message: errorMessages.join('\n'), error: errorFields },
          });
          if (errorMessages?.toString().includes('group')) {
            dispatch(
              AppActions.createDialogMessage('error', 'patient:UPDATE_PATIENT_DETAILS_INACTIVE_GROUP', errorMessages, {
                options: { buttons: [{ label: i18next.t('BUTTON_OK') }] },
              }),
            );
          } else {
            dispatch(AppActions.createDialogMessage('error', 'patient:UPDATE_PATIENT_DETAILS_FAIL', errorMessages));
          }
        });
    };

    if (toString(postData.externalPatientId) !== '0') {
      dispatch(externalCheck(id, postData.externalPatientId || null, cb));
    } else {
      cb();
    }
  };
}

export const updateRemovePatient = (patientId, isConfirm = false) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_REMOVE_PATIENT });
    const deleteApi = `api/patients/${patientId}?isConfirm=${isConfirm}`;
    HttpService.abort(`removePatient`);
    return HttpService.delete(deleteApi, { cancelToken: `removePatient` })
      .then(() => {
        dispatch(
          AppActions.showMessage({
            message: 'patient:ARCHIVE_PATIENT_SUCCESS',
          }),
        );
        dispatch({ type: UPDATE_REMOVE_PATIENT_SUCCESS });
        return true;
      })
      .catch((err) => {
        const { errorMessages } = parseError(err);
        dispatch({ type: UPDATE_REMOVE_PATIENT_FAIL, payload: { message: errorMessages.join('\n') } });
        // dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
      });
  };
};

export const updatePatientPackingStatus = (packingStatus) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_PAITENT_PACKING_STATUS, payload: { packingStatus } });
  };
};
