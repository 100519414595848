import * as PharmacyCreateActions from '../actions/create.actions';

const initialState = {
  id: '',
  data: {
    pharmacyCode: '',
    name: '',
    phoneNumber: [],
    fax: [],
    email: [],
    pharmacyGuid: '',
    approvalNumber: '',
    street: '',
    suburb: '',
    state: '',
    postalCode: '',
    isDoseAdd: 'false',
    timezone: 'AUS Eastern Standard Time',
    isIntegrator: 'false',
    integratorGuids: [],
    logoId: null,
  },
  loading: false,
  error: null,
  message: '',
};

const create = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case PharmacyCreateActions.CREATE_PHARMACY:
      return {
        ...state,
        loading: true,
        error: null,
        message: '',
      };
    case PharmacyCreateActions.CREATE_PHARMACY_SUCCESS:
      return {
        ...state,
        id: payload.pharmacyId,
        loading: false,
      };
    case PharmacyCreateActions.CREATE_PHARMACY_FAIL:
      return {
        ...state,
        message: payload.message,
        error: payload.error,
        loading: false,
      };
    case PharmacyCreateActions.CHANGE_PHARMACY_CREATE_FORM:
      return {
        ...state,
        data: {
          ...state.data,
          ...payload.data,
        },
      };
    case PharmacyCreateActions.CLEAR_CREATE_FORM:
      return initialState;
    default: {
      return state;
    }
  }
};

export default create;
