import * as PrescriptionsActions from '../actions/update.prescriptions.actions';
import {
  PRESCRIPTION_DOSE_TYPES,
  PRESCRIPTION_ROUTE_DEFAULT_VALUE,
  PRESCRIPTION_FREQUENCY_ENUM,
  PACK_TYPE_PRESCRIPTION_TYPES,
} from '../../constants';

function generateDefaultForm() {
  return {
    isCytotoxic: false,
    isDoNotCrush: true,
    packType: PACK_TYPE_PRESCRIPTION_TYPES.PACKED,
    drugName: '',
    drugCode: '',
    direction: '',
    route: { code: PRESCRIPTION_ROUTE_DEFAULT_VALUE },
    genericCode: '',
    genericName: '',
    indication: '',
    sameAs: '',
    type: '',
    description: '',
    note: '',
    doseType1: PRESCRIPTION_DOSE_TYPES.STANDARD,
    doseType2: '',
    tabletTime: '',
    dose: [...Array(8)].fill(''),
    shortCourseTimeslot: [...Array(8)].fill(''),
    frequency: PRESCRIPTION_FREQUENCY_ENUM.DAILY,
    prnDose: '',
    packedWeekDay: [],
    onDays: '',
    offDays: '',
    freqXDay: '',
    startDate: new Date(),
    endDate: '',
    drugId: '',
    prescriptionId: '',
    foilId: '',
    fontColor: '',
    imageId: '',
    useEndDate: false,
    headings: [...Array(8)].fill(''),
    unit: '',
    userComment: '',
    dispenseStagingId: '',
    isPreventBrandswap: false,
  };
}

const initialState = {
  data: [],
  loading: false,
  submitting: false,
  submitSuccess: false,
  message: '',
  error: null,
  gettingDrugDetail: false,
  prescriptionForm: generateDefaultForm(),
  gettingPrescriptionDetail: false,
  heading: [],
  prescriptionId: null,
  isImport: false,
  memoryPrescription: generateDefaultForm(),
  stagingPrescription: false,
};

const prescriptions = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case PrescriptionsActions.GET_PRESCRIPTIONS:
      return { ...state, loading: true };
    case PrescriptionsActions.GET_PRESCRIPTIONS_SUCCESS:
      return { ...state, loading: false, data: payload.data };
    case PrescriptionsActions.GET_PRESCRIPTIONS_FAIL:
      return { ...state, loading: false, message: payload.message };
    case PrescriptionsActions.UPDATE_PRESCRIPTION_FORM:
      return { ...state, prescriptionForm: { ...state.prescriptionForm, ...payload.data } };
    case PrescriptionsActions.RESET_PRESCRIPTION_FORM:
      return {
        ...state,
        prescriptionForm: generateDefaultForm(),
        submitSuccess: false,
        prescriptionId: null,
        error: null,
        message: '',
        isImport: false,
      };
    case PrescriptionsActions.GET_PRESCRIPTION_DETAIL:
      return { ...state, gettingPrescriptionDetail: true };
    case PrescriptionsActions.GET_PRESCRIPTION_DETAIL_SUCCESS:
      return { ...state, gettingPrescriptionDetail: false, prescriptionForm: payload.data };
    case PrescriptionsActions.GET_PRESCRIPTION_DETAIL_FAIL:
      return { ...state, gettingPrescriptionDetail: false, message: payload.message };
    case PrescriptionsActions.DELETE_PRESCRIPTION:
      return { ...state, submitting: true };
    case PrescriptionsActions.DELETE_PRESCRIPTION_SUCCESS:
      return { ...state, submitting: false, data: state.data.filter((i) => i.prescriptionId !== payload.data) };
    case PrescriptionsActions.DELETE_PRESCRIPTION_FAIL:
      return { ...state, submitting: false, message: payload.message };
    case PrescriptionsActions.UPDATE_PRESCRIPTION:
      return { ...state, submitting: true };
    case PrescriptionsActions.UPDATE_PRESCRIPTION_SUCCESS:
      return { ...state, submitting: false, submitSuccess: true, prescriptionId: null };
    case PrescriptionsActions.UPDATE_PRESCRIPTION_FAIL:
      return { ...state, submitting: false, submitSuccess: false, message: payload.message };
    case PrescriptionsActions.CREATE_PRESCRIPTION:
      return { ...state, submitting: true, submitSuccess: false, error: null };
    case PrescriptionsActions.CREATE_PRESCRIPTION_SUCCESS:
      return {
        ...state,
        submitting: false,
        submitSuccess: true,
        stagingPrescription: false,
        memoryPrescription: generateDefaultForm(),
      };
    case PrescriptionsActions.EDIT_PRESCRIPTION:
      return { ...state, prescriptionId: payload.id };
    case PrescriptionsActions.IMPORT_PRESCRIPTION:
      return { ...state, prescriptionId: payload.id, isImport: true };
    case PrescriptionsActions.ADD_PRESCRIPTION:
      return { ...state, prescriptionForm: { ...generateDefaultForm(), ...payload } };
    case PrescriptionsActions.CREATE_PRESCRIPTION_FAIL:
      return {
        ...state,
        submitting: false,
        submitSuccess: false,
        message: action.payload.message,
        error: action.payload.error,
      };
    case PrescriptionsActions.CEASE_PRESCRIPTION:
      return { ...state, submitting: true };
    case PrescriptionsActions.CEASE_PRESCRIPTION_SUCCESS:
      return {
        ...state,
        submitting: false,
      };
    case PrescriptionsActions.CEASE_PRESCRIPTION_FAIL:
      return { ...state, submitting: false, message: payload.message };
    case PrescriptionsActions.CHECK_EXCEEDED_PRESCRIPTION_START:
      return { ...state, submitting: true };
    case PrescriptionsActions.CHECK_EXCEEDED_PRESCRIPTION_DONE:
      return { ...state, submitting: false };
    case PrescriptionsActions.CREATE_ADDITIONAL_FOIL_START:
      return { ...state, submitting: true };
    case PrescriptionsActions.CREATE_ADDITIONAL_FOIL_DONE:
      return {
        ...state,
        submitting: false,
        memoryPrescription: { ...state.prescriptionForm, foilId: action.payload },
        stagingPrescription: true,
      };
    default:
      return state;
  }
};

export default prescriptions;
