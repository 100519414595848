import React from 'react';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { makeStyles } from '@material-ui/core/styles';

library.add(fas);

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: (props) => (props.size ? `${props.size}em` : '0.9em'),
    width: (props) => props.width && `${props.width}em !important`,
    color: (props) => !props.color && props.icon === 'trash-alt' && '#F44336',
  },
}));

const MyIcon = (props) => {
  const classes = useStyles(props);
  const { className, size, width, ...rest } = props;
  return <FontAwesomeIcon {...rest} className={clsx(classes.icon, className)} />;
};

export default MyIcon;
