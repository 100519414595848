// http://mcg.mbitson.com/
const palette = {
  50: '#f0f1f2',
  100: '#dadddd',
  200: '#c1c6c7',
  300: '#a8afb1',
  400: '#959da0',
  500: '#828c8f',
  600: '#7a8487',
  700: '#6f797c',
  800: '#656f72',
  900: '#525c60',
  A100: '#cbeefc',
  A200: '#9bdffa',
  A400: '#63d2ff',
  A700: '#49cbff',
};

export default palette;
