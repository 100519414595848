import React from 'react';
import { List } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { get, isArray } from 'lodash';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as AppActions from 'app/store/actions';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import Icon from 'components/Icon';
import Button from 'components/Button';
import IconButton from 'components/IconButton';
import i18n from '../../i18n';

const useStyles = makeStyles((theme) => ({
  'title-error': { color: theme.palette.error[900] },
  'title-custom': { color: theme.palette.primary[900] },
  closeIcon: {
    height: 26,
    width: 26,
    padding: 0,
  },
  message: {
    whiteSpace: 'pre-line',
  },
  button: {
    justifyContent: 'center',
  },
}));

const DialogMessage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { dialogs } = useSelector(({ dialogMessage }) => dialogMessage);

  const onClick = (dialog, button) => {
    if (button.label === '') {
      // Close icon
      closeDialog(dialog, button);
    }

    if (dialog.callback) {
      // Has callback handler
      dialog.callback(button.label);
    } else {
      closeDialog(dialog, button);
    }
  };

  const closeDialog = (dialog, button) => {
    dispatch(AppActions.closeDialogMessage(dialog.dialogId, button.label));
  };

  const renderButtons = (dialog) => {
    const buttons = get(dialog, 'options.buttons');
    if (buttons && isArray(buttons)) {
      return buttons.map((button) => {
        const { label, ...rest } = button;
        return (
          <Button {...rest} key={label} onClick={() => onClick(dialog, button)}>
            {label}
          </Button>
        );
      });
    }

    return (
      <Button onClick={() => onClick(dialog, { label: '' })} color="grey">
        {i18n.t('BUTTON_CLOSE')}
      </Button>
    );
  };

  return (
    <>
      {Object.keys(dialogs).map((dialogId) => {
        const _dialog = dialogs[dialogId];
        return (
          <Dialog open={_dialog.open} key={dialogId}>
            <DialogTitle id="alert-dialog-title" className={clsx(classes.title, classes[`title-${_dialog.type}`])}>
              <div className="flex items-center justify-between">
                <span>{i18n.t(_dialog.title)}</span>
                {_dialog.options?.showCloseButton && (
                  <IconButton
                    onClick={() => onClick({ ..._dialog, dialogId }, { label: '' })}
                    className={clsx(classes.closeIcon)}
                  >
                    <Icon icon="times" />
                  </IconButton>
                )}
              </div>
            </DialogTitle>
            <DialogContent className={clsx(classes.content)}>
              {Array.isArray(_dialog.messages) && (
                <List className="p-0">
                  {_dialog.messages.map((m) => (
                    <ListItem className={clsx('px-0', classes.message)} key={m}>
                      <ListItemText primary={m} />
                    </ListItem>
                  ))}
                </List>
              )}
              {typeof _dialog.messages === 'string' && (
                <Typography className={classes.message} variant="body2">
                  {i18n.t(_dialog.messages)}
                </Typography>
              )}
              {typeof _dialog.messages === 'object' && (
                <Typography className={classes.message} variant="body2">
                  {i18n.t(_dialog.messages.message, _dialog.messages.options)}
                </Typography>
              )}
            </DialogContent>
            <DialogActions className={clsx(_dialog.type === 'error' && 'justify-end')}>
              {renderButtons({ ..._dialog, dialogId })}
            </DialogActions>
          </Dialog>
        );
      })}
    </>
  );
};

export default DialogMessage;
