import * as ResetPasswordActions from '../actions/resetPassword.actions';

const initialState = {
  loading: false,
  error: '',
  success: false,
};

const resetPass = (state = initialState, action) => {
  switch (action.type) {
    case ResetPasswordActions.SEND_REQUEST:
      return { ...state, loading: true };
    case ResetPasswordActions.SEND_REQUEST_SUCCESS:
      return { ...state, success: true, loading: false };
    case ResetPasswordActions.SEND_REQUEST_FAIL:
      return { ...state, error: action.payload.error, loading: false };
    case ResetPasswordActions.RESET_STATE:
      return initialState;
    default:
      return state;
  }
};

export default resetPass;
