import FuseUtils from '@fuse/utils';
import Icon from '@material-ui/core/Icon';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import * as Actions from 'app/store/actions';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { PERMISSION } from 'app/auth';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import FuseNavBadge from '../FuseNavBadge';

const useStyles = makeStyles((theme) => ({
  item: () => ({
    height: 50,
    width: 'calc(100% - 16px)',
    // borderRadius: '0 20px 20px 0',
    // paddingRight: 12,
    // paddingLeft: props.itemPadding > 80 ? 80 : props.itemPadding,
    // width: 50,
    borderRadius: '25px',
    padding: 15,
    marginLeft: 6,
    '&.active': {
      backgroundColor: theme.palette.secondary.main,
      color: `${theme.palette.secondary.contrastText}!important`,
      pointerEvents: 'none',
      transition: 'border-radius .15s cubic-bezier(0.4,0.0,0.2,1)',
      '& .list-item-text-primary': {
        color: 'inherit',
      },
      '& .list-item-icon': {
        color: 'inherit',
      },
    },
    '& .list-item-icon': {
      marginRight: 16,
    },
    '& .list-item-text': {},
    color: theme.palette.text.primary,
    textDecoration: 'none!important',
  }),
}));

function FuseNavVerticalLink(props) {
  const dispatch = useDispatch();
  const { role, permission } = useSelector(({ auth }) => auth.user);
  const { item, nestedLevel, onClick } = props;
  const classes = useStyles({
    itemPadding: nestedLevel > 0 ? 40 + nestedLevel * 16 : 24,
  });
  const { t } = useTranslation('navigation');

  if (!FuseUtils.hasPermission(item.auth, role, item.id, PERMISSION.READ, permission)) {
    return null;
  }

  return (
    <ListItem
      button
      component="a"
      href={item.url}
      target={item.target ? item.target : '_blank'}
      className={clsx(classes.item, 'list-item')}
      onClick={() => {
        if (item.id === 'log-out') onClick();
        dispatch(Actions.navbarCloseMobile());
        dispatch(Actions.navbarCloseFolded());
      }}
      role="button"
    >
      {item.icon && typeof item.icon === 'string' && (
        <Icon className="list-item-icon text-17 flex-shrink-0" color="action">
          {item.icon}
        </Icon>
      )}

      {item.icon && typeof item.icon !== 'string' && <div className="list-item-icon">{item.icon}</div>}

      <ListItemText
        className="list-item-text"
        primary={item.translate ? t(item.translate) : item.title}
        classes={{ primary: 'text-15 list-item-text-primary' }}
      />

      {item.badge && <FuseNavBadge badge={item.badge} />}
    </ListItem>
  );
}

FuseNavVerticalLink.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    url: PropTypes.string,
    target: PropTypes.string,
  }),
};
FuseNavVerticalLink.defaultProps = {};

const NavVerticalLink = withRouter(React.memo(FuseNavVerticalLink));

export default NavVerticalLink;
