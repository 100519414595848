// http://mcg.mbitson.com/
const palette = {
  50: '#f2f9e4',
  100: '#def1bb',
  200: '#c8e78e',
  300: '#b1dd61',
  400: '#a1d63f',
  500: '#90cf1d',
  600: '#88ca1a',
  700: '#7dc315',
  800: '#73bd11',
  900: '#61b20a',
  A100: '#edffde',
  A200: '#d3ffab',
  A400: '#b8ff78',
  A700: '#aaff5e',
  contrastText: '#ffffff',
};

export default palette;
