const locale = {
  TITLE: 'RESET PASSWORD',
  USERNAME_PLACEHOLDER: 'Username',
  FORM_USERNAME_REQUIRE_ERROR: 'Username is required',
  BUTTON_SEND_LINK: 'Reset Password',
  LINK_BACK_TO_LOGIN: 'Go back to login',
  NOTI_RESET_PASSWORD:
    'Please enter your username below to receive a password reset link at your registered email address',
  REQUEST_FAIL_DIALOG_TITLE: 'Opps! Something has happened',
  REQUEST_SUCCESS_MESSAGE: 'We have sent you an email with a link to reset your password',
  REQUEST_FAIL_MESSAGE: 'Invalid Username, please try again',
};

export default locale;
