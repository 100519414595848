import shortid from 'shortid';
import * as DispenseTrackerListActions from '../actions/list.actions';
import { TAB, SCRIPT_TYPE } from '../../constants';

const defaultFilter = {
  patientGroupId: [],
  patientId: null,
  drugCode: null,
  dateFrom: null,
  dateTo: null,
  dispenseStatus: null,
  scriptStatus: null,
  packType: null,
  scriptType: SCRIPT_TYPE.MAPPED,
  dispenseScriptType: [],
};

const initializeState = {
  loading: false,
  activeTab: TAB.MAPPED,
  submitSuccess: false,
  random: null,
  hasData: false,
  filter: {
    ...defaultFilter,
  },
  fileUrl: null,
  fileName: null,
};

const list = (state = initializeState, action) => {
  const { type, payload } = action;
  switch (type) {
    // Filter
    case DispenseTrackerListActions.CHANGE_FILTER:
      return { ...state, filter: { ...state.filter, ...payload } };
    case DispenseTrackerListActions.CLEAR_FILTER:
      return { ...state, filter: { ...state.filter, ...defaultFilter, scriptType: state.filter.scriptType } };
    // random
    case DispenseTrackerListActions.CHANGE_RANDOM:
      return { ...state, random: shortid() };
    // Tab
    case DispenseTrackerListActions.CHANGE_TAB:
      switch (payload) {
        case TAB.MAPPED:
          return {
            ...state,
            activeTab: payload,
            random: null,
            filter: { ...state.filter, scriptType: SCRIPT_TYPE.MAPPED },
          };
        case TAB.UNMAPPED:
          return {
            ...state,
            activeTab: payload,
            random: null,
            filter: { ...state.filter, scriptType: SCRIPT_TYPE.UNMAPPED },
          };
        case TAB.IGNORED:
          return {
            ...state,
            activeTab: payload,
            random: null,
            filter: { ...state.filter, scriptType: SCRIPT_TYPE.IGNORED },
          };
        default:
          return { ...state, activeTab: payload, random: null };
      }
    // Submit
    case DispenseTrackerListActions.SUBMIT:
      return { ...state, loading: true, submitSuccess: false };
    case DispenseTrackerListActions.SUBMIT_SUCCESS:
      return { ...state, loading: false, submitSuccess: true };
    case DispenseTrackerListActions.SUBMIT_FAIL:
      return { ...state, loading: false, submitSuccess: false };

    // print report
    case DispenseTrackerListActions.PRINT_REPORT_MAPPED:
      return { ...state, loading: true, fileName: null, fileUrl: null };
    case DispenseTrackerListActions.PRINT_REPORT_MAPPED_SUCCESS:
      return { ...state, loading: false, fileName: payload.fileName, fileUrl: payload.fileUrl };
    case DispenseTrackerListActions.PRINT_REPORT_MAPPED_FAILED:
      return { ...state, loading: false, fileName: null, fileUrl: null };
    case DispenseTrackerListActions.CLEAR_FILE_REPORT_MAPPED:
      return { ...state, fileName: null, fileUrl: null };

    case DispenseTrackerListActions.HAS_DATA:
      return { ...state, hasData: true };
    case DispenseTrackerListActions.EMPTY_DATA:
      return { ...state, hasData: false };
    // Clear data
    case DispenseTrackerListActions.CLEAR_DATA:
      return initializeState;
    default:
      return state;
  }
};

export default list;
