import { toInteger, findIndex, get } from 'lodash';
import { HttpService } from 'app/services';
import * as AppActions from 'app/store/actions';
import { parseError } from 'helpers/Error';
import { isFractionNumber } from 'helpers/Validators';
import { parseFractionNumber } from 'helpers/Number';
import { ZERO_DATE } from 'app/constants/DateTimeFormat';
import {
  PRESCRIPTION_DOSE_TYPES,
  PACK_TYPE_PRESCRIPTION_ENUM,
  PRESCRIPTION_FREQUENCY_ENUM,
  PRESCRIPTION_STATE_ENUM,
  DUPLICATE_PRESCRIPTION,
  PRESCRIPTION_ROUTE_DEFAULT_VALUE,
} from '../../constants';

import { SET_SELECTED_FOIL_INDEX, ADD_FOIL_SUCCESS, hidePrescriptionForm } from './update.medicationProfiles.actions';
import { getPatient } from './update.base.actions';

export const GET_PRESCRIPTIONS = '@PATIENT/GET_PRESCRIPTIONS';
export const GET_PRESCRIPTIONS_SUCCESS = '@PATIENT/GET_PRESCRIPTIONS_SUCCESS';
export const GET_PRESCRIPTIONS_FAIL = '@PATIENT/GET_PRESCRIPTIONS_FAIL';

export const UPDATE_PRESCRIPTION_FORM = '@PATIENT/UPDATE_PRESCRIPTION_FORM';
export const RESET_PRESCRIPTION_FORM = '@PATIENT/RESET_PRESCRIPTION_FORM';

export const CREATE_PRESCRIPTION = '@PATIENT/CREATE_PRESCRIPTION';
export const CREATE_PRESCRIPTION_SUCCESS = '@PATIENT/CREATE_PRESCRIPTION_SUCCESS';
export const CREATE_PRESCRIPTION_FAIL = '@PATIENT/CREATE_PRESCRIPTION_FAIL';

export const GET_PRESCRIPTION_DETAIL = '@PATIENT/GET_PRESCRIPTION_DETAIL';
export const GET_PRESCRIPTION_DETAIL_SUCCESS = '@PATIENT/GET_PRESCRIPTION_DETAIL_SUCCESS';
export const GET_PRESCRIPTION_DETAIL_FAIL = '@PATIENT/GET_PRESCRIPTION_DETAIL_FAIL';

export const DELETE_PRESCRIPTION = '@PATIENT/DELETE_PRESCRIPTION';
export const DELETE_PRESCRIPTION_SUCCESS = '@PATIENT/DELETE_PRESCRIPTION_SUCCESS';
export const DELETE_PRESCRIPTION_FAIL = '@PATIENT/DELETE_PRESCRIPTION_FAIL';

export const UPDATE_PRESCRIPTION = '@PATIENT/UPDATE_PRESCRIPTION';
export const UPDATE_PRESCRIPTION_SUCCESS = '@PATIENT/UPDATE_PRESCRIPTION_SUCCESS';
export const UPDATE_PRESCRIPTION_FAIL = '@PATIENT/UPDATE_PRESCRIPTION_FAIL';

export const EDIT_PRESCRIPTION = '@PATIENT/EDIT_PRESCRIPTION';
export const ADD_PRESCRIPTION = '@PATIENT/ADD_PRESCRIPTION';
export const IMPORT_PRESCRIPTION = '@PATIENT/IMPORT_PRESCRIPTION';

export const CEASE_PRESCRIPTION = '@PATIENT/CEASE_PRESCRIPTION';
export const CEASE_PRESCRIPTION_SUCCESS = '@PATIENT/CEASE_PRESCRIPTION_SUCCESS';
export const CEASE_PRESCRIPTION_FAIL = '@PATIENT/CEASE_PRESCRIPTION_FAIL';

export const CHECK_EXCEEDED_PRESCRIPTION_START = '@PATIENT/CHECK_EXCEEDED_PRESCRIPTION_START';
export const CHECK_EXCEEDED_PRESCRIPTION_DONE = '@PATIENT/CHECK_EXCEEDED_PRESCRIPTION_DONE';

export const CREATE_ADDITIONAL_FOIL_START = '@PATIENT/CREATE_ADDITIONAL_FOIL_START';
export const CREATE_ADDITIONAL_FOIL_DONE = '@PATIENT/CREATE_ADDITIONAL_FOIL_DONE';

const MAP_DOSE_TYPE = {
  [PRESCRIPTION_DOSE_TYPES.STANDARD]: {
    param: 'isStandard=true',
    category: 'patient:PRESCRIPTION_PACK_DOSES_TYPE_STANDARD',
  },
  [PRESCRIPTION_DOSE_TYPES.CYTOTOCIX]: {
    param: 'isCytotoxic=true',
    category: 'patient:PRESCRIPTION_PACK_DOSES_TYPE_CYTOTOXIC',
  },
  [PRESCRIPTION_DOSE_TYPES.INSULIN]: {
    param: 'isInsulin=true',
    category: 'patient:PRESCRIPTION_PACK_DOSES_TYPE_INSULIN',
  },
  [PRESCRIPTION_DOSE_TYPES.IS_S4N]: { param: 'isS4N=true', category: 'patient:PRESCRIPTION_PACK_DOSES_TYPE_ISS4N' },
  [PRESCRIPTION_DOSE_TYPES.S8_DRUG]: {
    param: 'isS8Drug=true',
    category: 'patient:PRESCRIPTION_PACK_DOSES_TYPE_S8DRUG',
  },
  [PRESCRIPTION_DOSE_TYPES.SHORTCOURSE]: {
    param: 'isShortCourse=true',
    category: 'patient:PRESCRIPTION_PACK_DOSES_TYPE_SHORTCOURSE',
  },
  [PRESCRIPTION_DOSE_TYPES.SINEMET]: {
    param: 'isSinemet=true',
    category: 'patient:PRESCRIPTION_PACK_DOSES_TYPE_SINEMET',
  },
  [PRESCRIPTION_DOSE_TYPES.WARFARIN]: {
    param: 'isWarfarin=true',
    category: 'patient:PRESCRIPTION_PACK_DOSES_TYPE_WARFARIN',
  },
};

export const getPrescriptions = ({ foilId, sort }) => {
  return (dispatch) => {
    dispatch({ type: GET_PRESCRIPTIONS });
    HttpService.get(`api/foils/${foilId}/prescriptions`, {
      ...(sort && { sortField: sort.field, sortOrder: sort.order }),
    })
      .then((resp) => {
        dispatch({
          type: GET_PRESCRIPTIONS_SUCCESS,
          payload: { data: resp.data },
        });
      })
      .catch((err) => {
        const { errorMessages } = parseError(err);
        dispatch({ type: GET_PRESCRIPTIONS_FAIL, payload: { message: errorMessages.join('\n') } });
        dispatch(AppActions.createDialogMessage('error', 'Error', errorMessages));
      });
  };
};

export const createPrescription = (isConfirm = false, patientId) => {
  // isConfirm: Reference src\app\main\patient\components\MedicationProfiles\MedicationProfiles.js
  return (dispatch, getState) => {
    dispatch({ type: CREATE_PRESCRIPTION });
    const { prescriptionForm, isImport, stagingPrescription, memoryPrescription } =
      getState().patient.update.prescriptions;
    // MC-285221 Due to user can change prescription's foil
    // => need to update selectedFoilIndex
    const { data } = getState().patient.update.medicationProfiles;
    const newSelectedFoilIndex = findIndex(data, { foilId: prescriptionForm.foilId });

    const postData = parseToDBModel(stagingPrescription ? memoryPrescription : prescriptionForm);

    const url = isImport
      ? `/api/prescriptions/dispense/${prescriptionForm.dispenseStagingId}?IsConfirm=${isConfirm}`
      : `api/prescriptions?IsConfirm=${isConfirm}`;

    HttpService.post(url, postData, { resolveStatus400: true })
      .then((resp) => {
        // MC-344573
        if (resp.isSuccess) {
          dispatch(
            AppActions.showMessage({
              message: isImport
                ? 'Prescription has been imported successfully'
                : 'Prescription has been created successfully',
            }),
          );

          dispatch({
            type: SET_SELECTED_FOIL_INDEX,
            payload: { index: newSelectedFoilIndex },
          });

          dispatch({
            type: CREATE_PRESCRIPTION_SUCCESS,
            payload: { data: resp.data },
          });

          dispatch(resetPrescriptionForm());
        } else {
          dispatch({
            type: CREATE_PRESCRIPTION_FAIL,
            payload: { error: DUPLICATE_PRESCRIPTION },
          });
        }

        dispatch(getPatient(patientId));
        // dispatch(getPrescriptions({ foilId: foil.foilId }));
      })
      .catch((err) => {
        const { errorFields, errorMessages, errorTitle } = parseError(err);
        dispatch({
          type: CREATE_PRESCRIPTION_FAIL,
          payload: { message: errorMessages.join('\n'), error: errorFields },
        });
        dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
      });
  };
};

export const updatePrescription = ({ patientId }) => {
  return (dispatch, getState) => {
    dispatch({ type: UPDATE_PRESCRIPTION });
    const { prescriptionForm } = getState().patient.update.prescriptions;
    // MC-285221 Due to user can change prescription's foil
    // => need to update selectedFoilIndex
    const { data } = getState().patient.update.medicationProfiles;
    const newSelectedFoilIndex = findIndex(data, { foilId: prescriptionForm.foilId });

    const postData = parseToDBModel(prescriptionForm);
    HttpService.put(`api/prescriptions/${postData.prescriptionId}`, postData)
      .then((resp) => {
        dispatch(
          AppActions.showMessage({
            message: 'Prescription has been updated successfully',
          }),
        );

        dispatch({
          type: SET_SELECTED_FOIL_INDEX,
          payload: { index: newSelectedFoilIndex },
        });

        dispatch({
          type: UPDATE_PRESCRIPTION_SUCCESS,
          payload: { data: resp.data },
        });
        dispatch(getPatient(patientId));
        // dispatch(getPrescriptions({ foilId: foil.foilId }));
      })
      .catch((err) => {
        const { errorMessages } = parseError(err);
        dispatch({ type: UPDATE_PRESCRIPTION_FAIL, payload: { message: errorMessages.join('\n') } });
        dispatch(AppActions.createDialogMessage('error', 'Error', errorMessages));
      });
  };
};

export const updatePrescriptionForm = (data) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_PRESCRIPTION_FORM, payload: { data } });
  };
};

export const resetPrescriptionForm = () => {
  return (dispatch) => {
    dispatch({ type: RESET_PRESCRIPTION_FORM });
  };
};

export const editPrescription = (id) => {
  return (dispatch) => {
    dispatch({ type: EDIT_PRESCRIPTION, payload: { id } });
    dispatch({ type: GET_PRESCRIPTION_DETAIL });
    HttpService.get(`api/prescriptions/${id}`)
      .then((resp) => {
        const formData = parseToFormObject(resp.data);
        dispatch({
          type: GET_PRESCRIPTION_DETAIL_SUCCESS,
          payload: { data: formData },
        });
      })
      .catch((err) => {
        const { errorMessages } = parseError(err);
        dispatch({ type: GET_PRESCRIPTION_DETAIL_FAIL, payload: { message: errorMessages.join('\n') } });
        dispatch(AppActions.createDialogMessage('error', 'Error', errorMessages));
      });
  };
};

export const getStagingPrescription = (id, foilId) => {
  return (dispatch) => {
    dispatch({ type: IMPORT_PRESCRIPTION, payload: { id } });
    dispatch({ type: GET_PRESCRIPTION_DETAIL });
    HttpService.get(`/api/dispense/prescriptions/${id}`)
      .then((resp) => {
        const formData = parseToFormObject(resp.data);
        dispatch({
          type: GET_PRESCRIPTION_DETAIL_SUCCESS,
          payload: { data: { ...formData, foilId, dispenseStagingId: formData.prescriptionId } },
        });
      })
      .catch((err) => {
        const { errorMessages } = parseError(err);
        dispatch({ type: GET_PRESCRIPTION_DETAIL_FAIL, payload: { message: errorMessages.join('\n') } });
        dispatch(AppActions.createDialogMessage('error', 'Error', errorMessages));
      });
  };
};

export const addPrescription = (drugData) => {
  return (dispatch) => {
    dispatch({ type: ADD_PRESCRIPTION, payload: drugData });
  };
};

export const setCeasedPrescription = (prescriptionId, data) => {
  return (dispatch) => {
    dispatch({ type: CEASE_PRESCRIPTION });
    HttpService.put(`api/prescriptions/${prescriptionId}/status?state=${PRESCRIPTION_STATE_ENUM.CEASED}`, data)
      .then(() => {
        dispatch(
          AppActions.showMessage({
            message: 'Prescription has been updated successfully',
          }),
        );
        dispatch({ type: CEASE_PRESCRIPTION_SUCCESS });
        dispatch(hidePrescriptionForm()); // MC-971857 back to medication profile page after cease drug
      })
      .catch((err) => {
        const { errorMessages } = parseError(err);
        dispatch({ type: CEASE_PRESCRIPTION_FAIL, payload: { message: errorMessages.join('\n') } });
        dispatch(AppActions.createDialogMessage('error', 'Error', errorMessages));
      });
  };
};

// export const getPrescriptionDetail = id => {
// 	return dispatch => {
// 		dispatch({ type: GET_PRESCRIPTION_DETAIL });
// 		HttpService.get(`api/prescriptions/${id}`)
// 			.then(resp => {
// 				const formData = parseToFormObject(resp.data);
// 				delete formData.direction;
// 				dispatch({
// 					type: GET_PRESCRIPTION_DETAIL_SUCCESS,
// 					payload: { data: formData }
// 				});
// 			})
// 			.catch(err => {
// 				const { errorMessages } = parseError(err);
// 				dispatch({ type: GET_PRESCRIPTION_DETAIL_FAIL, payload: { message: errorMessages.join('\n') } });
// 				dispatch(AppActions.createDialogMessage('error', 'Error', errorMessages));
// 			});
// 	};
// };

export const deletePrescription = (prescriptionId, comment, callback) => {
  return (dispatch) => {
    dispatch({ type: DELETE_PRESCRIPTION });
    HttpService.delete(`api/prescriptions/${prescriptionId}`, { data: comment })
      .then(() => {
        dispatch(
          AppActions.showMessage({
            message: 'Prescription has been deleted successfully',
          }),
        );
        dispatch({
          type: DELETE_PRESCRIPTION_SUCCESS,
          payload: { data: prescriptionId },
        });
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        const { errorMessages } = parseError(err);
        dispatch({ type: DELETE_PRESCRIPTION_FAIL, payload: { message: errorMessages.join('\n') } });
        dispatch(AppActions.createDialogMessage('error', 'Error', errorMessages));
      });
  };
};

export const checkExceededPrescriptions = () => {
  return (dispatch, getState) => {
    const {
      prescriptionForm: { foilId, doseType1, doseType2, packType },
    } = getState().patient.update.prescriptions;

    if (packType !== PACK_TYPE_PRESCRIPTION_ENUM.PACKED) {
      return new Promise((resolve) => {
        resolve([true]);
      });
    }

    const doseType = doseType1 || doseType2 || PRESCRIPTION_DOSE_TYPES.STANDARD;
    dispatch({ type: CHECK_EXCEEDED_PRESCRIPTION_START });
    return HttpService.get(`/api/foils/${foilId}/packed?${MAP_DOSE_TYPE[doseType].param}`, null, {
      resolveStatus400: true,
    })
      .then((resp) => {
        dispatch({ type: CHECK_EXCEEDED_PRESCRIPTION_DONE });
        return [resp.isSuccess, { foilId, category: MAP_DOSE_TYPE[doseType].category }];
      })
      .catch((err) => {
        const { errorMessages } = parseError(err);
        dispatch({ type: CHECK_EXCEEDED_PRESCRIPTION_DONE });
        dispatch(AppActions.createDialogMessage('error', 'Error', errorMessages));
      });
  };
};

export const createAdditionalFoil = (originalFoil) => {
  return (dispatch, getState) => {
    dispatch({ type: CREATE_ADDITIONAL_FOIL_START });
    // 1. Call API generate foil name
    // 2. Call API create new foil
    // 3. Call API create new prescription
    return HttpService.get(
      `/api/foils/next-label?patientId=${originalFoil.patientId}&foilType=${originalFoil.foilType}`,
    )
      .then((nextLabel) => {
        const newFoil = {
          ...originalFoil,
          label: nextLabel,
          foilId: undefined,
        };
        return HttpService.post(`api/foils`, newFoil).then((resp) => {
          dispatch({
            type: ADD_FOIL_SUCCESS,
            payload: {
              newFoil: {
                ...resp.data,
              },
            },
          });
          return resp.data;
        });
      })
      .then((_newFoil) => {
        const { prescriptionForm, isImport } = getState().patient.update.prescriptions;
        const newPrescription = parseToDBModel({ ...prescriptionForm, foilId: _newFoil.foilId });
        const url = isImport
          ? `/api/prescriptions/dispense/${prescriptionForm.dispenseStagingId}?IsConfirm=true`
          : `api/prescriptions?IsConfirm=false`;

        return HttpService.post(url, newPrescription).then(() => {
          const { data } = getState().patient.update.medicationProfiles;
          const newSelectedFoilIndex = findIndex(data, { foilId: _newFoil.foilId });
          dispatch({
            type: SET_SELECTED_FOIL_INDEX,
            payload: { index: newSelectedFoilIndex },
          });
          dispatch({ type: CREATE_ADDITIONAL_FOIL_DONE, payload: _newFoil.foilId });
          dispatch(hidePrescriptionForm());
          dispatch(createPrescription(false));
        });
      })
      .catch((err) => {
        const { errorMessages } = parseError(err);
        dispatch({ type: CREATE_ADDITIONAL_FOIL_DONE });
        dispatch(AppActions.createDialogMessage('error', 'Error', errorMessages));
      });
  };
};

export const checkExistAllergy = ({ patientId }) => {
  return (dispatch, getState) => {
    const {
      prescriptionForm: { drugId },
    } = getState().patient.update.prescriptions;

    // dispatch({ type: CHECK_EXCEEDED_PRESCRIPTION_START });
    return HttpService.get(`/api/patients/${patientId}/allergies/check?drugId=${drugId}`, null)
      .then((resp) => {
        // dispatch({ type: CHECK_EXCEEDED_PRESCRIPTION_DONE });
        return resp?.data?.isExist;
      })
      .catch((err) => {
        const { errorMessages } = parseError(err);
        // dispatch({ type: CHECK_EXCEEDED_PRESCRIPTION_DONE });
        dispatch(AppActions.createDialogMessage('error', 'Error', errorMessages));
      });
  };
};

const parseToDBModel = (data) => {
  const packedWeekDay =
    data.packedWeekDay && data.frequency === PRESCRIPTION_FREQUENCY_ENUM.SPECIFIC_DAYS
      ? [...Array(7).keys()].map((i) => data.packedWeekDay.includes(i.toString()))
      : []; // parse ["4", "1", "3", "2"] to [false, true, true, true, true, false, false]

  const dose = [PACK_TYPE_PRESCRIPTION_ENUM.PRN, PACK_TYPE_PRESCRIPTION_ENUM.UNPACKED_PRN].includes(data.packType)
    ? isFractionNumber(data.prnDose)
      ? [parseFractionNumber(data.prnDose)]
      : [data.prnDose]
    : data.doseType1
    ? [...Array(data.dose?.length).keys()].map((i) =>
        isFractionNumber(data.dose[i]) ? parseFractionNumber(data.dose[i]) : data.dose[i] || '0',
      )
    : isFractionNumber(data.tabletTime)
    ? [parseFractionNumber(data.tabletTime)]
    : [data.tabletTime];

  const getFreqXDay = (frequencyType, freqXDay) => {
    switch (frequencyType) {
      case PRESCRIPTION_FREQUENCY_ENUM.EVERY_X_DAYS:
        return freqXDay;
      case PRESCRIPTION_FREQUENCY_ENUM.EVERY_28_DAYS:
        return 28;
      case PRESCRIPTION_FREQUENCY_ENUM.FORTNIGHTLY:
        return 14;
      default:
        return 0;
    }
  };

  const getFrequency = (frequencyType) => {
    switch (frequencyType) {
      case PRESCRIPTION_FREQUENCY_ENUM.EVERY_X_DAYS:
      case PRESCRIPTION_FREQUENCY_ENUM.EVERY_28_DAYS:
      case PRESCRIPTION_FREQUENCY_ENUM.FORTNIGHTLY:
        return PRESCRIPTION_FREQUENCY_ENUM.EVERY_X_DAYS;
      default:
        return frequencyType;
    }
  };

  // Update state to 1 (UPDATED) if updating prescriptions and state === 0 (NEW)
  let { state } = data;
  if (data.prescriptionId && toInteger(data.state) === toInteger(PRESCRIPTION_STATE_ENUM.NEW)) {
    state = toInteger(PRESCRIPTION_STATE_ENUM.UPDATED);
  }
  // Create or import set state to 0 (NEW)
  if (!data.prescriptionId) {
    state = toInteger(PRESCRIPTION_STATE_ENUM.NEW);
  }

  return {
    packType: data.packType,
    foilId: data.foilId,
    route: data.route || {},
    drugId: data.drugId,
    drugName: data.drugName,
    drugCode: data.drugCode,
    type: data.type,
    genericCode: data.genericCode,
    description: data.description,
    indication: data.indication,
    sameAs: data.sameAs,
    note: data.note,
    direction: data.direction || [],
    isStandard: data.doseType1 === PRESCRIPTION_DOSE_TYPES.STANDARD,
    isInsulin: data.doseType1 === PRESCRIPTION_DOSE_TYPES.INSULIN,
    isS8Drug: data.doseType1 === PRESCRIPTION_DOSE_TYPES.S8_DRUG,
    isCytotoxic: data.doseType1 === PRESCRIPTION_DOSE_TYPES.CYTOTOCIX,
    isWarfarin: data.doseType1 === PRESCRIPTION_DOSE_TYPES.WARFARIN,
    isS4N: data.doseType1 === PRESCRIPTION_DOSE_TYPES.IS_S4N,
    isShortCourse: data.doseType2 === PRESCRIPTION_DOSE_TYPES.SHORTCOURSE,
    isSinemet: data.doseType2 === PRESCRIPTION_DOSE_TYPES.SINEMET,
    frequency: getFrequency(data.frequency),
    startDate: data.startDate ? data.startDate : ZERO_DATE,
    endDate: data.endDate ? data.endDate : ZERO_DATE,
    packedWeekDay,
    onDays: data.onDays === '' ? 0 : data.onDays,
    offDays: data.offDays === '' ? 0 : data.offDays,
    isDoNotCrush: data.isDoNotCrush,
    fontColor: data.fontColor,
    dose: dose.every((i) => !i) ? [] : dose.map((i) => (i ? i.toString() : '0')),
    shortCourseTimeslot: data.shortCourseTimeslot,
    prescriptionId: data.prescriptionId,
    freqXDay: getFreqXDay(data.frequency, data.freqXDay),
    isNoEndDate: !data.useEndDate,
    unit: data.unit || '',
    state,
    userComment: data.userComment || '',
    isPreventBrandswap: data.isPreventBrandswap || false,
  };
};

const parseToFormObject = (data) => {
  const doseType1Obj = {
    [PRESCRIPTION_DOSE_TYPES.STANDARD]: data.isStandard,
    [PRESCRIPTION_DOSE_TYPES.INSULIN]: data.isInsulin,
    [PRESCRIPTION_DOSE_TYPES.S8_DRUG]: data.isS8Drug,
    [PRESCRIPTION_DOSE_TYPES.CYTOTOCIX]: data.isCytotoxic,
    [PRESCRIPTION_DOSE_TYPES.WARFARIN]: data.isWarfarin,
    [PRESCRIPTION_DOSE_TYPES.IS_S4N]: data.isS4N,
  };

  const doseType2Obj = {
    [PRESCRIPTION_DOSE_TYPES.SHORTCOURSE]: data.isShortCourse,
    [PRESCRIPTION_DOSE_TYPES.SINEMET]: data.isSinemet,
  };

  const doseType1 = Object.keys(doseType1Obj).find((i) => doseType1Obj[i]);
  const doseType2 = Object.keys(doseType2Obj).find((i) => doseType2Obj[i]);

  const getFrequency = (days, frequencyType) => {
    switch (days) {
      case PRESCRIPTION_FREQUENCY_ENUM.EVERY_28_DAYS:
        return PRESCRIPTION_FREQUENCY_ENUM.EVERY_28_DAYS;
      case PRESCRIPTION_FREQUENCY_ENUM.FORTNIGHTLY:
        return PRESCRIPTION_FREQUENCY_ENUM.FORTNIGHTLY;
      default:
        return frequencyType.toString();
    }
  };

  return {
    ...data,
    genericCode: data.genericCode || '',
    directionCode: data.directionCode || '',
    imageId: data.imageId || '',
    imageName: data.imageName || '',
    fontColor: data.fontColor || '',
    type: data.type || '',
    direction: data.direction || [],
    frequency: getFrequency(data.freqXDay, data.frequency),
    route: get(data, 'route.code', null) ? data.route : { code: PRESCRIPTION_ROUTE_DEFAULT_VALUE },
    doseType1: doseType1 || PRESCRIPTION_DOSE_TYPES.STANDARD,
    doseType2,
    tabletTime: doseType2Obj && get(data, 'dose', null) ? data.dose[0]?.toString() : '',
    prnDose: [2, 3].includes(data.packType) && get(data, ['dose', 0], null) ? data.dose[0]?.toString() : '',
    dose: doseType1 && get(data, 'dose', null) ? data.dose.map((i) => (i ? i.toString() : '0')) : [],
    shortCourseTimeslot:
      doseType2 && get(data, 'shortCourseTimeslot', null)
        ? data.shortCourseTimeslot.map((i) => (i ? i.toString() : ''))
        : [],
    packType: data.packType.toString(),
    packedWeekDay: data.packedWeekDay
      .map((i, idx) => (data.packedWeekDay[idx] === true ? idx.toString() : ''))
      .filter((i) => i !== ''),
    freqXDay: data.freqXDay.toString(),
    onDays: data.onDays.toString(),
    offDays: data.offDays.toString(),
    useEndDate: !data.isNoEndDate,
    headings: get(data, 'headings', [...Array(8)].fill('')),
    startDate: data.startDate === ZERO_DATE ? new Date() : data.startDate,
    endDate: data.endDate,
    isPreventBrandswap: data.isPreventBrandswap || false,
  };
};
