import { HttpService } from 'app/services';
import * as AppActions from 'app/store/actions';
import { parseResponseErrorMessage, parseErrorFields, parseError } from 'helpers/Error';

export const CREATE_DRUG_APN = '@DRUG/CREATE_DRUG_APN';
export const CREATE_DRUG_APN_SUCCESS = '@DRUG/CREATE_DRUG_APN_SUCCESS';
export const CREATE_DRUG_APN_FAIL = '@DRUG/CREATE_DRUG_APN_FAIL';

export const UPDATE_DRUG_APN = '@DRUG/UPDATE_DRUG_APN';
export const UPDATE_DRUG_APN_SUCCESS = '@DRUG/UPDATE_DRUG_APN_SUCCESS';
export const UPDATE_DRUG_APN_FAIL = '@DRUG/UPDATE_DRUG_APN_FAIL';

export const ARCHIVE_DRUG_APN = '@DRUG/ARCHIVE_DRUG_APN';
export const ARCHIVE_DRUG_APN_SUCCESS = '@DRUG/ARCHIVE_DRUG_APN_SUCCESS';
export const ARCHIVE_DRUG_APN_FAIL = '@DRUG/ARCHIVE_DRUG_APN_FAIL';

export const SET_SELECTED_APN = '@DRUG/SET_SELECTED_APN';

export const RESET_DRUG_APN = '@DRUG/RESET_DRUG_APN';

export function createDrugApn(drugApn) {
  return (dispatch, getState) => {
    const { drugId } = getState().drug.update.base.data;
    dispatch({ type: CREATE_DRUG_APN });
    HttpService.post(`api/drugs/${drugId}/apn`, { drugApn })
      .then((resp) => {
        dispatch(
          AppActions.showMessage({
            message: 'Drug APN has been added successfully',
          }),
        );
        dispatch({ type: CREATE_DRUG_APN_SUCCESS, payload: { drugApn: resp.data } });
        dispatch(AppActions.dataLoader_reload('DrugAPN'));
      })
      .catch((err) => {
        const { errorMessages } = parseError(err);
        dispatch({
          type: CREATE_DRUG_APN_FAIL,
          payload: { message: errorMessages, error: errorMessages },
        });
        dispatch(
          AppActions.showMessage({
            message: errorMessages,
            variant: 'error',
          }),
        );
      });
  };
}

export function updateDrugApn(apn) {
  return (dispatch, getState) => {
    const { drugId } = getState().drug.update.base.data;
    const { drugApnId, drugApn } = apn;
    dispatch({ type: UPDATE_DRUG_APN });
    HttpService.put(`api/drugs/${drugId}/apn/${drugApnId}`, { drugApn })
      .then(() => {
        dispatch(
          AppActions.showMessage({
            message: 'Drug APN has been updated successfully',
          }),
        );
        dispatch({ type: UPDATE_DRUG_APN_SUCCESS, payload: { drugApn, drugApnId } });
        dispatch(AppActions.dataLoader_reload('DrugAPN'));
      })
      .catch((err) => {
        dispatch({
          type: UPDATE_DRUG_APN_FAIL,
          payload: { message: parseResponseErrorMessage(err), error: parseErrorFields(err) },
        });
        dispatch(
          AppActions.showMessage({
            message: parseResponseErrorMessage(err),
            variant: 'error',
          }),
        );
      });
  };
}

export function archiveDrugApn(drugApnId) {
  return (dispatch, getState) => {
    const { drugId } = getState().drug.update.base.data;
    dispatch({ type: ARCHIVE_DRUG_APN });
    HttpService.delete(`api/drugs/${drugId}/apn/${drugApnId}`)
      .then(() => {
        dispatch(
          AppActions.showMessage({
            message: 'Drug APN has been deleted successfully',
          }),
        );
        dispatch({ type: ARCHIVE_DRUG_APN_SUCCESS, payload: { drugApnId } });
        dispatch(AppActions.dataLoader_reload('DrugAPN'));
      })
      .catch((err) => {
        dispatch({
          type: ARCHIVE_DRUG_APN_FAIL,
          payload: { message: parseResponseErrorMessage(err), error: parseErrorFields(err) },
        });
        dispatch(
          AppActions.showMessage({
            message: parseResponseErrorMessage(err),
            variant: 'error',
          }),
        );
      });
  };
}

export function setSelectedApn(data) {
  return (dispatch) => {
    dispatch({ type: SET_SELECTED_APN, payload: { data } });
  };
}

export function clearDrugApn() {
  return (dispatch) => {
    dispatch({ type: RESET_DRUG_APN });
  };
}
