import * as UpdateDetailsActions from '../actions/update.details.actions';
import * as UpdateBaseActions from '../actions/update.base.actions';
import { DRUG_ROUTE_DEFAULT_VALUE } from '../../constants';

const initialState = {
  data: {
    drugCode: '',
    genericCode: '',
    drugName: '',
    imageId: '',
    imageName: '',
    supplier: '',
    strength: '',
    description: '',
    type: '',
    genericName: '',
    altBrand: '',
    schedule: '',
    isCytotoxic: false,
    isDoNotCrush: 'true',
    route: DRUG_ROUTE_DEFAULT_VALUE,
    packSize: '',
    shelfLife: '',
    drugGroupId: null,
    authorized: 0,
    usePrintingDrugName: false,
    printingDrugName: '',
    presentation: '',
    warning: '',
    direction: '',
    color: '',
    shape: '',
  },
  loading: false,
  error: null,
  message: '',
  fileName: null,
  fileUrl: null,
};

const updateDetails = (state = initialState, action) => {
  switch (action.type) {
    case UpdateBaseActions.GET_DRUG_SUCCESS:
      return {
        ...initialState,
        data: {
          drugId: action.payload.data.drugId,
          drugCode: action.payload.data.drugCode,
          genericCode: action.payload.data.genericCode || '',
          drugName: action.payload.data.drugName || '',
          imageId: action.payload.data.imageId || '', // prevent warning when there's no drug image
          imageName: action.payload.data.imageName || '',
          supplier: action.payload.data.supplier || '',
          strength: action.payload.data.strength || '',
          description: action.payload.data.description || '',
          type: action.payload.data.type || '',
          genericName: action.payload.data.genericName || '',
          altBrand: action.payload.data.altBrand || '',
          schedule: action.payload.data.schedule || '',
          isCytotoxic: action.payload.data.isCytotoxic.toString(),
          isDoNotCrush: action.payload.data.isDoNotCrush.toString(),
          route: action.payload.data.route || DRUG_ROUTE_DEFAULT_VALUE,
          packSize: action.payload.data.packSize || '',
          shelfLife: action.payload.data.shelfLife || '',
          drugGroupId: action.payload.data.drugGroup?.drugGroupId,
          authorized: action.payload.data.authorized?.toString() || '0',
          usePrintingDrugName: action.payload.data.usePrintingDrugName,
          printingDrugName: action.payload.data.printingDrugName || '',
          presentation: action.payload.data.presentation || '',
          warning: action.payload.data.warning || '',
          direction: action.payload.data.direction || '',
          color: action.payload.data.color || '',
          shape: action.payload.data.shape || '',
        },
      };
    case UpdateDetailsActions.UPDATE_DRUG_DETAILS:
      return { ...state, loading: true };
    case UpdateDetailsActions.UPDATE_DRUG_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          ...action.payload.data,
          isCytotoxic: action.payload.data.isCytotoxic.toString(),
          authorized: action.payload.data.authorized?.toString() || '0',
        },
      };
    case UpdateDetailsActions.UPDATE_DRUG_DETAILS_FAILED:
      return { ...state, loading: false, message: action.payload.message, error: action.payload.error };
    case UpdateDetailsActions.UPDATE_DRUG_DETAILS_DATA:
      return { ...state, data: { ...state.data, ...action.payload.data } };
    case UpdateBaseActions.CLEAR_DRUG_DATA:
      return initialState;
    case UpdateDetailsActions.UPDATE_DRUG_ID:
      return { ...state, data: { ...state.data, drugId: action.payload.newId } };
    // UPDATE_DRUG_USAGE_REPORT
    case UpdateDetailsActions.UPDATE_DRUG_USAGE_REPORT:
      return { ...state, loading: true, message: '', error: null };
    case UpdateDetailsActions.UPDATE_DRUG_USAGE_REPORT_SUCCESS:
      return { ...state, loading: false, fileName: action.payload.fileName, fileUrl: action.payload.fileUrl };
    case UpdateDetailsActions.UPDATE_DRUG_USAGE_REPORT_FAILED:
      return { ...state, loading: false, message: action.payload.message, error: action.payload.error };
    // UPDATE_CLEAR_FILE_HISTORY
    case UpdateDetailsActions.UPDATE_CLEAR_FILE_HISTORY:
      return { ...state, fileName: null, fileUrl: null };
    default:
      return state;
  }
};

export default updateDetails;
