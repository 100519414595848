import * as JobCheckActions from '../actions/check.actions';
import { CHECKING_STATUS } from '../../constants';

const initState = {
  loading: false,
  dose: {
    jobNumber: '',
    packStartDate: '',
    patientGroupName: '',
    patientName: '',
    doseAllocated: {},
    prescriptions: [],
    checkChangePrescriptions: [],
    pageCount: 0,
    rowHeadings: [],
    pageHeadings: [],
    numberOfWeek: 0,
    clientCode: 0,
    foilLabel: '',
    packType: 0,
    patientPhotoId: '',
  },
  prescriptionId: null,
  error: false,
  message: '',
};

export default function packReducer(state = initState, action) {
  const { type, payload } = action;

  switch (type) {
    // CHECK_GET_DOSE
    case JobCheckActions.CHECK_GET_DOSE:
      return { ...state, loading: true, error: false, message: '' };
    case JobCheckActions.CHECK_GET_DOSE_SUCCESS:
      return { ...state, loading: false, dose: payload.data };
    case JobCheckActions.CHECK_GET_DOSE_FAIL:
      return { ...state, loading: false, error: true, message: payload.message };
    // CHECK_SHOW_PRESCRIPTION
    case JobCheckActions.CHECK_SHOW_PRESCRIPTION:
      return { ...state, prescriptionId: payload.prescriptionId };
    // CHECK_SEARCH_BY_APN
    case JobCheckActions.CHECK_SEARCH_BY_APN:
      return { ...state, loading: true, error: false, message: '' };
    case JobCheckActions.CHECK_SEARCH_BY_APN_SUCCESS:
      return { ...state, loading: false, prescriptionId: payload.data, matchedAPNId: payload.data };
    case JobCheckActions.CHECK_SEARCH_BY_APN_FAIL:
      return { ...state, loading: false, error: true, message: payload.message };
    // CHECK_REJECT_MEDICATION
    case JobCheckActions.CHECK_REJECT_MEDICATION:
      return { ...state, loading: true, error: false, message: '' };
    case JobCheckActions.CHECK_REJECT_MEDICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        dose: {
          ...state.dose,
          prescriptions: state.dose?.prescriptions.map((pres) => {
            if (pres.id === payload.prescriptionId) {
              return {
                ...pres,
                checkingStatus: payload.data.status,
                checkingComments: payload.data.comments,
              };
            }

            return pres;
          }),
          checkChangePrescriptions: state.dose?.checkChangePrescriptions,
        },
      };
    case JobCheckActions.CHECK_REJECT_MEDICATION_FAIL:
      return { ...state, loading: false, error: true, message: payload.message };
    // CHECK_CONFIRM_PASS
    case JobCheckActions.CHECK_CONFIRM_PASS:
      return { ...state, loading: true, error: false, message: '' };
    case JobCheckActions.CHECK_CONFIRM_PASS_SUCCESS:
      return { ...state, loading: false };
    case JobCheckActions.CHECK_CONFIRM_PASS_FAIL:
      return { ...state, loading: false, error: true, message: payload.message };
    // CHECK_CORRECTION_REQUIRED
    case JobCheckActions.CHECK_CORRECTION_REQUIRED:
      return { ...state, loading: true, error: false, message: '' };
    case JobCheckActions.CHECK_CORRECTION_REQUIRED_SUCCESS:
      return { ...state, loading: false };
    case JobCheckActions.CHECK_CORRECTION_REQUIRED_FAIL:
      return { ...state, loading: false, error: true, message: payload.message };
    // CHECK_RESET_DATA
    case JobCheckActions.CHECK_RESET_DATA:
      return initState;
    default:
      return state;
  }
}
