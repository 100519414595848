import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ThemeProvider } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

const MyIconButton = (props) => {
  const { themes } = useSelector(({ fuse }) => fuse.settings);
  const { progress = false, children, theme, ...rest } = props;
  const btnTheme = themes[theme] || themes.default;

  const buildButton = () => (
    <IconButton {...rest}>
      {progress && <CircularProgress size={20} />}
      {!progress && children}
    </IconButton>
  );

  return <ThemeProvider theme={btnTheme}>{buildButton()}</ThemeProvider>;
};

export default MyIconButton;
