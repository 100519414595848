import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as AppActions from 'app/store/actions';

const useNextState = (key) => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.nextState);
  const [nextState, setNextState] = useState(null);

  const addNextState = (_data) => {
    dispatch(AppActions.addNextState({ key, data: _data }));
  };

  const removeLastState = () => {
    dispatch(AppActions.removeLastState(key));
  };

  useEffect(() => {
    if (data && data[key]) {
      setNextState(data[key]);
    }
  }, []);

  return {
    addNextState,
    removeLastState,
    nextState,
  };
};

export default useNextState;
