import React, { useMemo, forwardRef } from 'react';
import { TableRow } from '@material-ui/core';

const MemorizedTableRow = forwardRef((props, ref) => {
  // title, tooltip: tooltip props => do not pass to TableRow
  const { editable, rowDeps = [], rowStyles, columnCount, rowChecked, item, checkAll, title, tooltip, ...rest } = props;
  const depValues = rowDeps.map((r) => JSON.stringify(item[r])).join(',');
  const memorized = useMemo(() => {
    return <TableRow {...rest} ref={ref} />;
  }, [rest.id, editable, depValues, rowStyles, columnCount, rowChecked, checkAll]);

  return memorized;
});

export default MemorizedTableRow;
