import { HttpService } from 'app/services';
import * as AppActions from 'app/store/actions';
import { parseError } from 'helpers/Error';

export const SET_CONTACT_FORM = '@PATIENT/SET_CONTACT_FORM';

export const CREATE_CONTACT = '@PATIENT/CREATE_CONTACT';
export const CREATE_CONTACT_SUCCESS = '@PATIENT/CREATE_CONTACT_SUCCESS';
export const CREATE_CONTACT_FAIL = '@PATIENT/CREATE_CONTACT_FAIL';

export const ARCHIVE_CONTACT = '@PATIENT/ARCHIVE_CONTACT';
export const ARCHIVE_CONTACT_SUCCESS = '@PATIENT/ARCHIVE_CONTACT_SUCCESS';
export const ARCHIVE_CONTACT_FAIL = '@PATIENT/ARCHIVE_CONTACT_FAIL';

export const UPDATE_CONTACT = '@PATIENT/UPDATE_CONTACT';
export const UPDATE_CONTACT_SUCCESS = '@PATIENT/UPDATE_CONTACT_SUCCESS';
export const UPDATE_CONTACT_FAIL = '@PATIENT/UPDATE_CONTACT_FAIL';

export function createContact(contact) {
  return (dispatch, getState) => {
    const { patientId } = getState().patient.update.base.data;
    const payload = { ...contact };
    payload.phone = [payload.phone];
    payload.email = [payload.email];
    delete payload.contactId;
    dispatch({ type: CREATE_CONTACT });
    HttpService.post(`/api/patients/${patientId}/contacts`, payload)
      .then(() => {
        dispatch({ type: CREATE_CONTACT_SUCCESS });
        dispatch(
          AppActions.showMessage({
            message: 'Contact has been added successfully',
            autoHideDuration: 4000,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'success',
          }),
        );
        dispatch(AppActions.dataLoader_reload('PatientContacts'));
      })
      .catch((err) => {
        const { errorMessages, errorTitle } = parseError(err);
        dispatch({ type: CREATE_CONTACT_FAIL, payload: { message: errorMessages.join('\n') } });
        dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
      });
  };
}

export function archiveContact(contactId) {
  return (dispatch, getState) => {
    const { patientId } = getState().patient.update.base.data;
    dispatch({ type: ARCHIVE_CONTACT });
    HttpService.delete(`/api/patients/${patientId}/contacts/${contactId}`)
      .then(() => {
        dispatch({ type: ARCHIVE_CONTACT_SUCCESS, payload: { contactId } });
        dispatch(
          AppActions.showMessage({
            message: 'Contact has been removed successfully',
            autoHideDuration: 4000,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'success',
          }),
        );
        dispatch(AppActions.dataLoader_reload('PatientContacts'));
      })
      .catch((err) => {
        const { errorMessages, errorTitle } = parseError(err);
        dispatch({ type: ARCHIVE_CONTACT_FAIL, payload: { message: errorMessages.join('\n') } });
        dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
      });
  };
}

export function updateContact(contact) {
  return (dispatch, getState) => {
    const { patientId } = getState().patient.update.base.data;
    const payload = { ...contact };
    const { contactId } = payload;
    payload.phone = [payload.phone];
    payload.email = [payload.email];
    delete payload.id;
    dispatch({ type: UPDATE_CONTACT });
    HttpService.put(`/api/patients/${patientId}/contacts/${contactId}`, payload)
      .then(() => {
        dispatch({ type: UPDATE_CONTACT_SUCCESS });
        dispatch(
          AppActions.showMessage({
            message: 'Contact has been updated successfully',
            autoHideDuration: 4000,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'success',
          }),
        );
        dispatch(AppActions.dataLoader_reload('PatientContacts'));
      })
      .catch((err) => {
        const { errorMessages, errorTitle } = parseError(err);
        dispatch({ type: UPDATE_CONTACT_FAIL, payload: { message: errorMessages.join('\n') } });
        dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
      });
  };
}

export function setSelectedContact(contact) {
  return (dispatch) => {
    dispatch({ type: SET_CONTACT_FORM, payload: { contact } });
  };
}
