const locale = {
  LOGIN_FAIL_DIALOG_TITLE: 'Login Failed',
  GET_PROFILE_FAIL_DIALOG_TITLE: 'Get Profile Failed',
  GET_PROFILE_FAIL_DIALOG_MESSAGE: 'Invalid signature. Please login again.',
  TITLE: 'Welcome Back',
  DESCRIPTION:
    'Australia’s leading-edge, end-to-end solution for Dose Administration Aids. All you need to seamlessly manage & grow DAA professional services.',
  LOGIN_FORM_USERNAME: 'Username',
  LOGIN_FORM_PASSWORD: 'Password',
  LOGIN_FORM_LOGIN_BTN: 'LOG IN',
  FORGOT_PASSWORD: 'Forgot Username/Password?',
  PRIVACY_POLICY: 'Privacy Policy',
  REMEMBER_ME: 'Remember Me',
};

export default locale;
