import * as UpdateBaseActions from '../actions/update.base.actions';
import * as UpdateConsiderationsActions from '../actions/update.considerations.actions';

const defaultConsideration = {
  cognitiveImpairment: false,
  dexterityDifficulties: false,
  nilByMouth: false,
  other: '',
  resistiveToMedicines: false,
  selfAdminister: false,
  swallowingDifficulties: false,
  haveOther: false,
};

const initialState = {
  loading: false,
  values: defaultConsideration,
};

const considerations = (state = initialState, action) => {
  switch (action.type) {
    case UpdateBaseActions.GET_PATIENT_DETAIL_SUCCESS:
      return {
        ...initialState,
        values: {
          ...(action.payload.data.considerations || defaultConsideration),
          other: action.payload.data.considerations.other || '',
          haveOther: Boolean(action.payload.data.considerations.other),
        },
      };
    case UpdateConsiderationsActions.UPDATE_CONSIDERATIONS:
      return {
        ...state,
        loading: true,
      };
    case UpdateConsiderationsActions.UPDATE_CONSIDERATIONS_FAIL:
      return {
        ...state,
        loading: false,
      };
    case UpdateConsiderationsActions.UPDATE_CONSIDERATIONS_SUCCESS:
      return {
        ...state,
        values: {
          ...action.payload.data,
          other: action.payload.data.other || '',
          haveOther: Boolean(action.payload.data.other),
        },
        loading: false,
      };
    case UpdateBaseActions.CLEAR_PATIENT_DATA:
      return initialState;
    default:
      return state;
  }
};

export default considerations;
