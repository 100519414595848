import * as JobDistributeActions from '../actions/distribute.actions';
import { CHECKING_STATUS } from '../../constants';

const initState = {
  loading: false,
  dose: {
    jobNumber: '',
    packStartDate: '',
    patientGroupName: '',
    patientName: '',
    doseAllocated: {},
    prescriptions: [],
    checkChangePrescriptions: [],
    pageCount: 0,
    rowHeadings: [],
    pageHeadings: [],
    numberOfWeek: 0,
    clientCode: 0,
    foilLabel: '',
    packType: 0,
    patientPhotoId: '',
    distribution: 0,
  },
  error: false,
  message: '',
};

export default function distributeReducer(state = initState, action) {
  const { type, payload } = action;

  switch (type) {
    // DISTRIBUTE_GET_DOSE
    case JobDistributeActions.DISTRIBUTE_GET_DOSE:
      return { ...state, loading: true, error: false, message: '' };
    case JobDistributeActions.DISTRIBUTE_GET_DOSE_SUCCESS:
      return { ...state, loading: false, dose: payload.data };
    case JobDistributeActions.DISTRIBUTE_GET_DOSE_FAIL:
      return { ...state, loading: false, error: true, message: payload.message };
    // DISTRIBUTE_COMPLETE
    case JobDistributeActions.DISTRIBUTE_COMPLETE:
      return { ...state, loading: true, error: false, message: '' };
    case JobDistributeActions.DISTRIBUTE_COMPLETE_SUCCESS:
      return { ...state, loading: false };
    case JobDistributeActions.DISTRIBUTE_COMPLETE_FAIL:
      return { ...state, loading: false, error: true, message: payload.message };
    // DISTRIBUTE_UPDATE_COMMENT
    case JobDistributeActions.DISTRIBUTE_UPDATE_COMMENT:
      return { ...state, loading: true, error: false, message: '' };
    case JobDistributeActions.DISTRIBUTE_UPDATE_COMMENT_SUCCESS:
      return { ...state, loading: false };
    case JobDistributeActions.DISTRIBUTE_UPDATE_COMMENT_FAIL:
      return { ...state, loading: false, error: true, message: payload.message };
    // DISTRIBUTE_RESET_DATA
    case JobDistributeActions.DISTRIBUTE_RESET_DATA:
      return initState;
    default:
      return state;
  }
}
