import fs from 'fs';
import path from 'path';
import temp from 'temp';
import HttpService from '../httpService/HttpService';
import Config from '../../Config';

const isElectronEnv = Config.REACT_APP_APP_ENV === 'electron';
const isElectronPakaged = Config.REACT_APP_ELECTRON_PACKAGED === 'packaged';
let ptp;
if (isElectronEnv) {
  ptp = require('pdf-to-printer');
}

class PrintService {
  downloadFileFromUrl(fileName) {
    return new Promise((resolve, reject) => {
      temp.mkdir('mypak-foil', (err, dirPath) => {
        if (!err) {
          this.filePath = path.join(dirPath, `${fileName}.pdf`);

          HttpService.downloadFile(`/api/packjobs/pdf/download/${fileName}`, 'get').then(async (response) => {
            const buffer = Buffer.from(await response.data.arrayBuffer());
            fs.writeFile(this.filePath, buffer, (error) => {
              if (error) {
                reject();
              } else {
                resolve();
              }
            });
          });
        } else {
          reject();
        }
      });
    });
  }

  print(fileName) {
    return new Promise((resolve, reject) => {
      this.downloadFileFromUrl(fileName).then(() => {
        const options = {
          printer: this.printer,
        };
        if (isElectronPakaged) {
          options.path = 'resources\\app.asar.unpacked\\node_modules\\pdf-to-printer\\dist';
        }
        ptp
          .print(this.filePath, options)
          .then(resolve)
          .catch(reject)
          .finally(() => {
            temp.track();
            this.filePath = '';
          });
      });
    });
  }

  defaultPrinter(printer) {
    return new Promise((resolve, reject) => {
      ptp.getPrinters().then((printers) => {
        this.printers = printers;
        if (this.printers.indexOf(printer) > -1) {
          this.printer = printer;
          resolve();
        } else {
          reject();
        }
      });
    });
  }

  getPrinters() {
    return new Promise((resolve, reject) => {
      ptp.getPrinters().then(
        (printers) => {
          this.printers = printers;
          resolve(printers);
        },
        (err) => {
          reject(err);
        },
      );
    });
  }
}

const instance = new PrintService();

export default instance;
