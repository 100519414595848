export const NEXT_STATE_ADD = '@NEXT_STATE/ADD';
export const NEXT_STATE_REMOVE = '@NEXT_STATE/REMOVE';

export const addNextState = (data) => {
  return (dispatch) => {
    dispatch({ type: NEXT_STATE_ADD, payload: data });
  };
};

export const removeLastState = (key) => {
  return (dispatch) => {
    dispatch({ type: NEXT_STATE_REMOVE, payload: { key } });
  };
};
