import React, { useEffect, useState, useRef } from 'react';
import { useIdle, useTimeoutFn } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import throttle from 'lodash/throttle';
import { useDialogMessage } from 'app/hooks';
import { AuthService } from 'app/services/authService';
import * as authActions from 'app/auth/store/actions';
import { toNumber } from 'lodash';

const EVENTS = ['mousemove', 'mousedown', 'resize', 'keydown', 'touchstart', 'wheel'];

const IdleTimer = (props) => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.pharmacy.update.configuration);
  const logOut = () => {
    dialog.close();
    AuthService.clearToken();
    dispatch(authActions.logoutUser());
    window.location.reload(); // Should re-init app after automatically logout
  };
  const isIdle = useIdle(60000 * toNumber(data.logoutInactivityTime ?? 30)); // 30 minutes before showing idle popup
  const [isReady, _, reset] = useTimeoutFn(() => {
    if (isReady() && dialog.isOpen) logOut();
  }, 60000 * 5); // 5 minutes before automatic log out after the idle popup shown
  const dialog = useDialogMessage('idle');
  const { t } = useTranslation();
  const isFocus = useRef(true);

  useEffect(() => {
    const hadToken = AuthService.getToken();

    if (isIdle && hadToken) {
      reset();
      dialog
        .confirm(
          t('IDLE_DIALOG_MESSAGE'),
          t('IDLE_DIALOG_TITLE'),
          {
            buttons: [{ label: t('BUTTON_CONTINUE') }],
            showCloseButton: false,
          },
          'error',
        )
        .on((label) => {
          if (label === t('BUTTON_CONTINUE')) {
            reset();
            dialog.close();
          }
        });
    }
  }, [isIdle]);

  useEffect(() => {
    // Using localStorage to detect user is still using app or not in multiple tab
    const onEvent = throttle(() => {
      window.localStorage.setItem('lastTime', Date.now());
    }, 1000);

    EVENTS.forEach((ev) => {
      document.addEventListener(ev, onEvent);
    });

    return () => {
      EVENTS.forEach((ev) => {
        document.removeEventListener(ev, onEvent);
      });
    };
  }, []);

  useEffect(() => {
    const setFocus = () => {
      isFocus.current = true;
    };
    const setBlur = () => {
      isFocus.current = false;
    };

    const autoClosePopup = throttle(() => {
      const isLoginPage = window.location.pathname === '/auth/login';
      const isLogined = window.localStorage.getItem('isLogined') === 'true';
      const hadToken = AuthService.getToken();

      if (isLogined && !isFocus.current) {
        // Automatically close warning popup if user still using app in other tab
        reset();
        dialog.close();

        // Force reload when current page is login and user logined in another tab
        // ---> Redirect to homepage
        if (isLoginPage) window.location.reload();
      }

      if (!isLogined && !hadToken && !isLoginPage) {
        // Automatically logout if user had logged out in other tab
        logOut();
      }
    }, 100);

    window.addEventListener('focus', setFocus);
    window.addEventListener('blur', setBlur);
    window.addEventListener('storage', autoClosePopup);

    return () => {
      window.removeEventListener('focus', setFocus);
      window.removeEventListener('blur', setBlur);
      window.removeEventListener('storage', autoClosePopup);
    };
  }, []);

  return props.children;
};

export default IdleTimer;
