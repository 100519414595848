import React from 'react';
import { NavLink } from 'react-router-dom';

const NavLinkAdapter = React.forwardRef((props, ref) => (
  <NavLink
    innerRef={ref}
    {...props}
    isActive={(match, location) => {
      if (match) {
        return true;
      }
      // Not match route
      const navModule = props.to.match(/([a-zA-Z0-9-])+/g);
      const presentModule = location.pathname.match(/([a-zA-Z0-9-])+/g);
      if (navModule && presentModule) {
        // Same module
        return navModule[0] === presentModule[0];
      }

      return false;
    }}
  />
));

export default NavLinkAdapter;
