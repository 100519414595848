class StorageService {
  _storage = localStorage;

  setStorage(storage) {
    // store: localStorage | sessionStorage
    this._storage = storage;
  }

  setValue(key, value) {
    this._storage.setItem(key, value);
  }

  getValue(key) {
    return this._storage.getItem(key);
  }

  clear() {
    this._storage.clear();
  }
}

const instance = new StorageService();

export default instance;
