import * as UpdateNotesActions from '../actions/update.notes.actions';
import * as UpdateBaseActions from '../actions/update.base.actions';
import { NOTE_USAGE } from '../../constants';

const initialState = {
  selectedNote: {
    noteId: '',
    note: '',
    noteUsage: NOTE_USAGE.NONE,
    createdBy: '',
    createdDate: new Date(),
  },
  submitting: false,
  error: null,
};

const updateNote = (state = initialState, action) => {
  switch (action.type) {
    case UpdateNotesActions.SUBMIT_PATIENT_NOTE:
      return {
        ...state,
        submitting: true,
        error: null,
      };
    case UpdateNotesActions.SUBMIT_PATIENT_NOTE_SUCCESS:
      return {
        ...state,
        submitting: false,
      };
    case UpdateNotesActions.SUBMIT_PATIENT_NOTE_FAIL:
      return {
        ...state,
        submitting: false,
        error: action.payload.message,
      };
    case UpdateNotesActions.ARCHIVE_PATIENT_NOTE:
      return {
        ...state,
        submitting: true,
        error: null,
      };
    case UpdateNotesActions.ARCHIVE_PATIENT_NOTE_SUCCESS:
      return {
        ...state,
        submitting: false,
      };
    case UpdateNotesActions.ARCHIVE_PATIENT_NOTE_FAIL:
      return {
        ...state,
        submitting: false,
        error: action.payload.message,
      };
    case UpdateNotesActions.UPDATE_PATIENT_NOTE:
      return {
        ...state,
        submitting: true,
        error: null,
      };
    case UpdateNotesActions.UPDATE_PATIENT_NOTE_SUCCESS:
      return {
        ...state,
        submitting: false,
      };
    case UpdateNotesActions.UPDATE_PATIENT_NOTE_FAIL:
      return {
        ...state,
        submitting: false,
        error: action.payload.message,
      };
    case UpdateNotesActions.SET_SELECTED_NOTE:
      return {
        ...state,
        selectedNote: action.payload.note,
      };
    case UpdateBaseActions.CLEAR_PATIENT_DATA:
      return initialState;
    default:
      return state;
  }
};

export default updateNote;
