import * as JobCorrectActions from '../actions/correct.actions';

const initState = {
  loading: false,
  dose: {
    jobNumbers: [],
    packStartDate: '',
    patientGroupName: '',
    patientName: '',
    doseAllocated: {},
    prescriptions: [],
    pageCount: 0,
    rowHeadings: [],
    pageHeadings: [],
    numberOfWeek: 0,
    clientCode: 0,
    foilLabel: '',
    packType: 0,
    patientPhotoId: '',
  },
  error: false,
  message: '',
};

export default function correctReducer(state = initState, action) {
  const { type, payload } = action;

  switch (type) {
    // CORRECT_GET_DOSE
    case JobCorrectActions.CORRECT_GET_DOSE:
      return { ...state, loading: true, error: false, message: '' };
    case JobCorrectActions.CORRECT_GET_DOSE_SUCCESS:
      return { ...state, loading: false, dose: payload.data };
    case JobCorrectActions.CORRECT_GET_DOSE_FAIL:
      return { ...state, loading: false, error: true, message: payload.message };
    // CORRECT_CONFIRM_PRESCRIPTION
    case JobCorrectActions.CORRECT_CONFIRM_PRESCRIPTION:
      return { ...state, loading: true, error: false, message: '' };
    case JobCorrectActions.CORRECT_CONFIRM_PRESCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        dose: {
          ...state.dose,
          prescriptions: state.dose.prescriptions.map((prescription) => {
            if (prescription.id === payload.prescriptionId) {
              return { ...prescription, isCorrectionConfirm: payload.isCorrectionConfirm };
            }

            return prescription;
          }),
        },
      };
    case JobCorrectActions.CORRECT_CONFIRM_PRESCRIPTION_FAIL:
      return { ...state, loading: false, error: true, message: payload.message };
    // CORRECT_COMPLETE
    case JobCorrectActions.CORRECT_COMPLETE:
      return { ...state, loading: true, error: false, message: '' };
    case JobCorrectActions.CORRECT_COMPLETE_SUCCESS:
      return { ...state, loading: false };
    case JobCorrectActions.CORRECT_COMPLETE_FAIL:
      return { ...state, loading: false, error: true, message: payload.message };
    // CORRECT_RESET_DATA
    case JobCorrectActions.CORRECT_RESET_DATA:
      return initState;
    default:
      return state;
  }
}
