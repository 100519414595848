import * as RoleUpdateActions from '../actions/update.actions';
import { MENU } from '../../constants';

const permissions = MENU.map((i) => {
  return {
    ...i,
    read: false,
    create: false,
    update: false,
    delete: false,
  };
});

const initialState = {
  id: '',
  data: {
    name: '',
    permissions,
    isChangeCommentRequired: false,
  },
  loading: false,
  submitting: false,
  error: null,
  message: '',
};

const update = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case RoleUpdateActions.GET_ROLE:
      return {
        ...state,
        loading: true,
      };
    case RoleUpdateActions.GET_ROLE_SUCCESS:
      return {
        ...state,
        id: payload.data.id,
        loading: false,
        data: payload.data.detail,
      };
    case RoleUpdateActions.GET_ROLE_FAIL:
      return {
        ...state,
        loading: false,
        message: payload.message,
        error: payload.error,
      };
    case RoleUpdateActions.CHANGE_ROLE_UPDATE_FORM:
      return {
        ...state,
        data: {
          ...state.data,
          ...payload.data,
        },
      };
    case RoleUpdateActions.UPDATE_ROLE:
      return {
        ...state,
        submitting: true,
      };
    case RoleUpdateActions.UPDATE_ROLE_SUCCESS:
      return {
        ...state,
        submitting: false,
      };
    case RoleUpdateActions.UPDATE_ROLE_FAIL:
      return {
        ...state,
        submitting: false,
        error: payload.error,
        message: payload.message,
      };
    case RoleUpdateActions.CLEAR_UPDATE_FORM:
      return initialState;
    default: {
      return state;
    }
  }
};

export default update;
