import axios from 'axios';
import FuseUtils from '@fuse/utils/FuseUtils';
import { AuthService } from '../authService';
import { StoreService } from '../storeService';
import * as UserActions from '../../auth/store/actions/user.actions';
import Config from '../../Config';
/* eslint-disable camelcase */

class HttpService extends FuseUtils.EventEmitter {
  constructor() {
    super();

    this.http = axios.create({
      baseURL: Config.REACT_APP_API_DOMAIN,
      // timeout: 1000,
      // headers: { 'Content-Type': 'application/json' }
    });

    this.setInterceptors();

    this.requests = {};

    this.refreshTokenReq = null;
  }

  setInterceptors = () => {
    // axios.interceptors.response.use(
    // 	response => {
    // 		return response;
    // 	},
    // 	err => {
    // 		return new Promise((resolve, reject) => {
    // 			if (err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
    // 				// if you ever get an unauthorized response, logout the user
    // 				this.emit('onAutoLogout', 'Invalid access_token');
    // 				this.setSession(null);
    // 			}
    // 			throw err;
    // 		});
    // 	}
    // );
  };

  buildRequest(data) {
    const {
      reqKey,
      options: { resolveStatus400 = false },
    } = data;
    const { CancelToken } = axios;
    const source = CancelToken.source();
    const token = AuthService.getToken();
    const headers = {
      ...(token && { Authorization: token }),
    };

    const req = {
      reqInputs: data,
      execute: (url, method, options) => {
        return new Promise((resolveReq, rejectReq) => {
          this.http
            .request({
              url,
              method,
              cancelToken: source.token,
              headers,
              ...options,
            })
            .then((response) => {
              // request return successful
              resolveReq(response);
            })
            .catch((e) => {
              // request return error
              if (axios.isCancel(e)) {
                return rejectReq(new Error('Request Aborted'));
              }

              if (!e.response) {
                return rejectReq(
                  new Error(
                    'The Server encountered an internal error and was unable to complete your request! Please try again in few minutes. Thank you!',
                  ),
                );
              }

              const { status, config } = e.response;

              if (status === 409 || (resolveStatus400 && status === 400)) {
                resolveReq(e.response);
              }

              if (status === 401) {
                // Token is expired then try to refresh token
                return this.refreshToken().then(
                  () => {
                    // refresh token successful
                    this.retry({ url, ...req.reqInputs, method: config.method.toLowerCase() })
                      .then((res) => {
                        resolveReq({ data: res });
                      })
                      .catch((retryError) => {
                        // retry error
                        AuthService.clearToken(); // clear tokens
                        StoreService.dispatch(UserActions.setRole([])); // redirect user to login page
                        rejectReq(retryError);
                      });
                  },
                  () => {
                    // refresh token failed
                    AuthService.clearToken(); // clear tokens
                    StoreService.dispatch(UserActions.setRole([])); // redirect user to login page
                    rejectReq();
                  },
                );
              }

              // other error cases
              return rejectReq(e);
            })
            .finally(() => {
              delete this.requests[reqKey];
            });
        });
      },
      abort: () => {
        source.cancel('Operation canceled.');
        delete this.requests[reqKey];
      },
    };

    this.requests[reqKey] = req;

    return req;
  }

  abort(key) {
    if (this.requests[key]) this.requests[key].abort();
  }

  async refreshToken() {
    if (this.refreshTokenReq) return this.refreshTokenReq;
    this.refreshTokenReq = new Promise((resolve, reject) => {
      const refreshToken = AuthService.getRefreshToken();
      if (!refreshToken) {
        return reject();
      }
      return axios
        .create({
          baseURL: Config.REACT_APP_API_DOMAIN,
        })
        .request({
          url: '/api/token/refreshtoken',
          method: 'POST',
          data: {
            refreshToken: AuthService.getRefreshToken(),
          },
        })
        .then((response) => {
          const { data } = response;
          AuthService.setToken(data.token);
          AuthService.setRefreshToken(data.refreshToken);
          resolve();
        })
        .catch((e) => {
          console.log('Refresh token failed', e);
          reject();
        });
    });
    return this.refreshTokenReq;
  }

  retry(config) {
    const { method } = config;
    switch (method) {
      case 'get':
        return this.get(config.url, config.params, config.options);
      case 'post':
        return this.post(config.url, config.data, config.options);
      case 'put':
        return this.put(config.url, config.data, config.options);
      case 'patch':
        return this.patch(config.url, config.data, config.options);
      case 'delete':
        return this.delete(config.url, config.options);
      default:
        throw new Error(`Method not supported ${method}`);
    }
  }

  async get(url, params = {}, options = {}) {
    const { cancelToken, ...reqOptions } = options;
    const reqKey = cancelToken ?? `PATCH:${url}`;
    const req = this.buildRequest({ reqKey, params, options });
    const res = await req.execute(url, 'get', { params, ...reqOptions });
    return res.data;
  }

  async post(url, data, options = {}) {
    const { cancelToken, ...reqOptions } = options;
    const reqKey = cancelToken ?? `POST:${url}`;
    const req = this.buildRequest({ reqKey, data, options });
    const res = await req.execute(url, 'post', { data, ...reqOptions });
    return res.data;
  }

  async put(url, data, options = {}) {
    const { cancelToken, ...reqOptions } = options;
    const reqKey = cancelToken ?? `PUT:${url}`;
    const req = this.buildRequest({ reqKey, data, options });
    const res = await req.execute(url, 'put', { data, ...reqOptions });
    return res.data;
  }

  async patch(url, data, options = {}) {
    const { cancelToken, ...reqOptions } = options;
    const reqKey = cancelToken ?? `PATCH:${url}`;
    const req = this.buildRequest({ reqKey, data, options });
    const res = await req.execute(url, 'patch', { data, ...reqOptions });
    return res.data;
  }

  async delete(url, options = {}, params = {}) {
    // TODO: Should refactor parameter to be similar with get method
    const { cancelToken, ...reqOptions } = options;
    const reqKey = cancelToken ?? `DELETE:${url}`;
    const req = this.buildRequest({ reqKey, params, options });
    const res = await req.execute(url, 'delete', { params, ...reqOptions });
    return res.data;
  }

  async downloadFile(url, method, data, options = {}) {
    const { cancelToken, ...reqOptions } = options;
    const reqKey = cancelToken ?? `${method.toUpperCase()}:${url}`;
    const req = this.buildRequest({ reqKey, options });
    const res = await req.execute(url, method, { data, responseType: 'blob', ...reqOptions });
    let fileName = '';
    const disposition = res.headers['content-disposition'];
    if (disposition && disposition.includes('inline')) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        fileName = matches[1].replace(/['"]/g, '');
      }
    }
    return { data: res.data, fileName };
  }
}

const instance = new HttpService();

export default instance;
