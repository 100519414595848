import { HttpService } from 'app/services';
import { parseError } from 'helpers/Error';
import * as AppActions from 'app/store/actions';

export const GET_DRUG = '@DRUG/GET_DRUG';
export const GET_DRUG_SUCCESS = '@DRUG/GET_DRUG_SUCCESS';
export const GET_DRUG_FAIL = '@DRUG/GET_DRUG_FAIL';

export const CLEAR_DRUG_DATA = '@DRUG/CLEAR_DRUG_DATA';

export const UPDATE_BASE_ID = '@DRUG/UPDATE_BASE_ID';

export const getDrug = (drugId) => {
  return (dispatch) => {
    dispatch({ type: GET_DRUG });
    HttpService.get(`/api/drugs/${drugId}`)
      .then((resp) => {
        dispatch({ type: GET_DRUG_SUCCESS, payload: { data: resp.data } });
      })
      .catch((err) => {
        const { errorMessages, errorTitle } = parseError(err);
        dispatch({ type: GET_DRUG_FAIL, payload: { message: errorMessages.join('\n') } });
        dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
      });
  };
};

export const clearDrug = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_DRUG_DATA });
  };
};

export const updateBaseId = (data) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_BASE_ID, payload: { data } });
  };
};
