import React from 'react';
import { authRoles, PERMISSION } from 'app/auth';
import i18next from 'i18next';
import { MENU_ENUM } from 'app/main/role/constants';
import en from '../report/i18n/en';

i18next.addResourceBundle('en', 'report', en);

const SigningSheetsConfig = {
  settings: {
    layout: {
      config: {},
    },
    nav: {
      title: 'Signing Sheets',
    },
  },
  auth: authRoles.user,
  key: MENU_ENUM.REPORT,
  permission: PERMISSION.READ,
  routes: [
    {
      path: '/signing-sheets',
      component: React.lazy(() => import('./SigningSheets')),
    },
  ],
};

export default SigningSheetsConfig;
