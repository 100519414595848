import * as UpdateDoctorActions from '../actions/update.doctor.actions';

const initialState = {
  submitting: false,
  error: null,
  message: '',
};

const doctor = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case UpdateDoctorActions.ADD_DOCTOR_TO_PATIENT:
      return { ...state, submitting: true };
    case UpdateDoctorActions.ADD_DOCTOR_TO_PATIENT_SUCCESS:
      return { ...state, submitting: false };
    case UpdateDoctorActions.ADD_DOCTOR_TO_PATIENT_FAIL:
      return { ...state, submitting: false, error: payload.error, message: payload.message };
    case UpdateDoctorActions.REMOVE_DOCTOR_FROM_PATIENT:
      return { ...state, submitting: true };
    case UpdateDoctorActions.REMOVE_DOCTOR_FROM_PATIENT_SUCCESS:
      return { ...state, submitting: false };
    case UpdateDoctorActions.REMOVE_DOCTOR_FROM_PATIENT_FAIL:
      return { ...state, submitting: false, error: payload.error, message: payload.message };
    default:
      return state;
  }
};

export default doctor;
