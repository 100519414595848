import * as QuickAddDrugActions from '../actions/quickAdd.actions';

const initialState = {
  submitting: false,
  error: null,
  message: '',
  newDrug: null,
};

const quickAdd = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case QuickAddDrugActions.QUICK_ADD_DRUG:
      return { ...state, submitting: true, newDrug: null };
    case QuickAddDrugActions.QUICK_ADD_DRUG_SUCCESS:
      return {
        ...state,
        submitting: false,
        newDrug: payload.data,
      };
    case QuickAddDrugActions.QUICK_ADD_DRUG_FAIL:
      return { ...state, submitting: false, message: payload.message, error: payload.error };
    default:
      return state;
  }
};

export default quickAdd;
