import * as BrandSwapActions from '../actions';

const initialState = {
  submitting: false,
  error: null,
  message: '',
  filter: {
    patientGroupIds: [],
    patientIds: null,
    drugName: null,
    fromDate: null,
    toDate: null,
  },
};

const byDrug = (state = initialState, action) => {
  switch (action.type) {
    case BrandSwapActions.BRAND_SWAP_BY_DRUG:
      return {
        ...state,
        submitting: true,
        error: null,
        message: '',
      };
    case BrandSwapActions.BRAND_SWAP_BY_DRUG_SUCCESS:
      return {
        ...state,
        submitting: false,
      };
    case BrandSwapActions.BRAND_SWAP_BY_DRUG_FAIL:
      return {
        ...state,
        submitting: false,
        error: action.payload.error,
        message: action.payload.message,
      };
    case BrandSwapActions.RESET_BRAND_SWAP_BY_DRUG_FORM:
      return initialState;

    // Filter
    case BrandSwapActions.CHANGE_FILTER:
      return { ...state, filter: { ...state.filter, ...action.payload } };

    default: {
      return state;
    }
  }
};

export default byDrug;
