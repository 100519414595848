import { HttpService } from 'app/services';
import * as AppActions from 'app/store/actions';
import { parseError } from 'helpers/Error';

export const GET_PATIENT_DETAIL = '@PATIENT/GET_PATIENT_DETAIL';
export const GET_PATIENT_DETAIL_SUCCESS = '@PATIENT/GET_PATIENT_DETAIL_SUCCESS';
export const GET_PATIENT_DETAIL_FAIL = '@PATIENT/GET_PATIENT_DETAIL_FAIL';

export const CLEAR_PATIENT_DATA = '@PATIENT/CLEAR_PATIENT_DATA';

// export const CHECK_PROFILE_START = '@PATIENT/CHECK_PROFILE_START';
// export const CHECK_PROFILE_SUCCESS = '@PATIENT/CHECK_PROFILE_SUCCESS';
// export const CHECK_PROFILE_FAILED = '@PATIENT/CHECK_PROFILE_FAILED';

export const getPatient = (patientId) => {
  return (dispatch) => {
    dispatch({ type: GET_PATIENT_DETAIL });
    HttpService.get(`/api/patients/${patientId}`)
      .then((resp) => {
        dispatch({ type: GET_PATIENT_DETAIL_SUCCESS, payload: { data: resp.data } });
      })
      .catch((err) => {
        const { errorMessages } = parseError(err);
        dispatch(AppActions.createDialogMessage('error', 'Failed to get patient', errorMessages));
        dispatch({ type: GET_PATIENT_DETAIL_FAIL, payload: { message: errorMessages.join('\n') } });
      });
  };
};

export const clearUpdateForm = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_PATIENT_DATA });
  };
};

// export const checkProfile = patientId => {
// 	return dispatch => {
// 		dispatch({ type: CHECK_PROFILE_START });
// 		HttpService.put(`/api/patients/${patientId}/check`)
// 			.then(() => {
// 				dispatch({ type: CHECK_PROFILE_SUCCESS });
// 				dispatch(
// 					AppActions.showMessage({
// 						message: 'patient:CHECK_PROFILE_SUCCESS'
// 					})
// 				);
// 				dispatch(getPatient(patientId));
// 			})
// 			.catch(err => {
// 				const { errorMessages, errorFields, errorTitle } = parseError(err);
// 				dispatch({
// 					type: CHECK_PROFILE_FAILED,
// 					payload: { message: errorMessages.join('\n'), error: errorFields }
// 				});
// 				dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
// 			});
// 	};
// };
