import { combineReducers } from 'redux';
import list from './list.reducer';
import create from './create.reducer';
import update from './update.reducer';

const directionReducers = combineReducers({
  list,
  create,
  update,
});

export default directionReducers;
