import React from 'react';
import { authRoles, PERMISSION } from 'app/auth';
import { MENU_ENUM } from 'app/main/role/constants';

const CreateDrugConfig = {
  settings: {
    layout: {
      config: {},
    },
    nav: {
      title: 'Create Drug',
    },
  },
  auth: authRoles.user,
  key: MENU_ENUM.DRUG,
  permission: PERMISSION.CREATE,
  routes: [
    {
      path: '/drug/create',
      component: React.lazy(() => import('./CreateDrug')),
    },
  ],
};

export default CreateDrugConfig;
