import * as DoctorActions from '../actions/update.actions';

const defaultDoctorForm = {
  firstName: '',
  lastName: '',
  address: '',
  fax: '',
  phone: '',
  pbsApproval: '',
  email: '',
  outOfHour: '',
};

const initialState = {
  loading: false,
  submitting: false,
  submitSuccess: false,
  doctorForm: defaultDoctorForm,
  error: null,
  message: '',
};

const update = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    // GET_DOCTOR_DETAIL
    case DoctorActions.GET_DOCTOR_DETAIL:
      return { ...state, loading: true, error: null };
    case DoctorActions.GET_DOCTOR_DETAIL_SUCCESS:
      return { ...state, loading: false, doctorForm: payload.data };
    case DoctorActions.GET_DOCTOR_DETAIL_FAIL:
      return { ...state, loading: false, message: payload.message, error: payload.error };
    // UPDATE_DOCTOR
    case DoctorActions.UPDATE_DOCTOR:
      return { ...state, submitting: true, submitSuccess: false, error: null };
    case DoctorActions.UPDATE_DOCTOR_SUCCESS:
      return { ...state, submitting: false, submitSuccess: true };
    case DoctorActions.UPDATE_DOCTOR_FAIL:
      return { ...state, submitting: false, submitSuccess: false, message: payload.message, error: payload.error };
    // DELETE_DOCTOR
    case DoctorActions.DELETE_DOCTOR:
      return { ...state, loading: true, error: null, message: '' };
    case DoctorActions.DELETE_DOCTOR_SUCCESS:
      return { ...state, loading: false };
    case DoctorActions.DELETE_DOCTOR_FAIL:
      return { ...state, loading: false, message: payload.message, error: payload.error };
    // CLEAR_UPDATE_FORM
    case DoctorActions.CLEAR_UPDATE_FORM:
      return { ...state, doctorForm: defaultDoctorForm };
    // CHANGE_DOCTOR_UPDATE_FORM
    case DoctorActions.CHANGE_DOCTOR_UPDATE_FORM:
      return { ...state, doctorForm: { ...state.doctorForm, ...payload.data } };
    default:
      return state;
  }
};

export default update;
