const locale = {
  TITLE: 'Print Options Management',
  HEADER_TITLE: 'Print Options Management',
  HEADER_BUTTON_NEW_FULL: 'Add A New Print Option',
  HEADER_BUTTON_NEW_SHORT: 'New',
  NAME: 'Name',
  PRINTER: 'Printer',
  TOP_MARGIN: 'Top Margin',
  LEFT_MARGIN: 'Left Margin',
  WIDTH: 'Width',
  HEIGHT: 'Height',
  DRUG_NAME_LENGTH: 'Foil Drug Name Length',
  DIRECTION_LENGTH: 'Alpaca Foil Directions Length',
  LIST_HEADER_ACTIONS: 'Actions',
  YES: 'Yes',
  NO: 'No',
  TYPE: 'Type',
  ADD_PRINT_PROFILE_SUCCESS: 'Print Options has been created successfully',
  UPDATE_PRINT_PROFILE_SUCCESS: 'Print Options has been updated successfully',
  ARCHIVE_PRINT_PROFILE_CONFIRM_CONTENT: 'Are you sure you want to archive print profile {{name}}',
  ARCHIVE_PRINT_PROFILE_CONFIRM_TITLE: 'Archive Print Options?',
  ARCHIVE_PRINT_PROFILE_SUCCESS: 'Print Options has been removed successfully!',
  IS_PACK_DATE_ON_FOIL: 'Pack Dates on Foil',
  IS_PACK_START_DATE: 'Pack Start Date',
  IS_DRUG_WARNING: 'Drug Warning',
  IS_SAME_AS_DRUG_INFORMATION: 'Same As Drug Information',
  IS_PACK_DOSE_TIME_HEADING: 'Pack Dose Time Headings',
  IS_PACK_BARCODE: 'Pack Barcodes',
  IS_UNPACK_PRN_MEDICATION_AREA: 'Non-Packed & PRN Medication Area',
};

export default locale;
