import React from 'react';
// import { authRoles, PERMISSION } from 'app/auth';
import i18next from 'i18next';
// import { MENU_ENUM } from 'app/main/role/constants';
import en from '../i18n/en';

i18next.addResourceBundle('en', 'profile', en);

const ProfileDetailConfig = {
  settings: {
    layout: {
      config: {},
    },
    nav: {
      title: 'Profiles',
    },
  },
  auth: null,
  key: null,
  permission: null,
  routes: [
    {
      path: '/profile/detail',
      component: React.lazy(() => import('./ProfileDetail')),
    },
  ],
};

export default ProfileDetailConfig;
