import { HttpService } from 'app/services';
import * as AppActions from 'app/store/actions';
import { parseError } from 'helpers/Error';

export const CREATE_PRINT_PROFILE = '@PRINT_PROFILE/CREATE_PRINT_PROFILE';
export const CREATE_PRINT_PROFILE_SUCCESS = '@PRINT_PROFILE/CREATE_PRINT_PROFILE_SUCCESS';
export const CREATE_PRINT_PROFILE_FAIL = '@PRINT_PROFILE/CREATE_PRINT_PROFILE_FAIL';

export const CHANGE_PRINT_PROFILE_FORM = '@PRINT_PROFILE/CHANGE_PRINT_PROFILE_FORM';

export const CLEAR_CREATE_FORM = '@PRINT_PROFILE/CLEAR_CREATE_FORM';

export function createPrintProfile() {
  return (dispatch, getState) => {
    const { data } = getState().printProfile.create;
    const postData = {
      name: data.name,
      topMargin: data.topMargin || '0',
      leftMargin: data.leftMargin || '0',
      width: data.width || '0',
      height: data.height || '0',
      foilDrugNameLength: data.foilDrugNameLength || '0',
      alpacaFoilDirectionLength: data.alpacaFoilDirectionLength || '0',
      isPackDateOnFoil: data.isPackDateOnFoil,
      isPackStartDate: data.isPackStartDate,
      isDrugWarning: data.isDrugWarning,
      isSamAsDrugInfo: data.isSamAsDrugInfo,
      isPackDoseTimeHeading: data.isPackDoseTimeHeading,
      isPackBarcode: data.isPackBarcode,
      isUnpackedPrnMedArea: data.isUnpackedPrnMedArea,
      defaultPrinter: data.defaultPrinter,
    };
    dispatch({ type: CREATE_PRINT_PROFILE });
    HttpService.post(`api/printprofiles`, postData)
      .then((resp) => {
        dispatch(
          AppActions.showMessage({
            message: 'printProfile:ADD_PRINT_PROFILE_SUCCESS',
          }),
        );
        dispatch({ type: CREATE_PRINT_PROFILE_SUCCESS, payload: { printProfileId: resp.data.printProfileId } });
      })
      .catch((err) => {
        const { errorFields, errorMessages, errorTitle } = parseError(err);
        dispatch({
          type: CREATE_PRINT_PROFILE_FAIL,
          payload: { message: errorMessages.join('\n'), error: errorFields },
        });
        dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
      });
  };
}

export function clearCreateForm() {
  return (dispatch) => {
    dispatch({ type: CLEAR_CREATE_FORM });
  };
}

export function changePrintProfileCreateForm(data) {
  return (dispatch) => {
    dispatch({ type: CHANGE_PRINT_PROFILE_FORM, payload: { data } });
  };
}
