import { HttpService } from 'app/services';
import * as AppActions from 'app/store/actions';
import { parseError } from 'helpers/Error';

export const CREATE_DIRECTION = '@DIRECTION/CREATE_DIRECTION';
export const CREATE_DIRECTION_SUCCESS = '@DIRECTION/CREATE_DIRECTION_SUCCESS';
export const CREATE_DIRECTION_FAIL = '@DIRECTION/CREATE_DIRECTION_FAIL';

export const CHANGE_DIRECTION_FORM = '@DIRECTION/CHANGE_DIRECTION_FORM';

export const CLEAR_CREATE_FORM = '@DIRECTION/CLEAR_CREATE_FORM';

export function createDirection(data) {
  return (dispatch) => {
    dispatch({ type: CREATE_DIRECTION });
    return HttpService.post(`api/directions`, data)
      .then((resp) => {
        dispatch(
          AppActions.showMessage({
            message: 'direction:ADD_DIRECTION_SUCCESS',
          }),
        );
        dispatch({ type: CREATE_DIRECTION_SUCCESS, payload: { directionId: resp.data.directionId } });
        dispatch(AppActions.dataLoader_reload('GET:DIRECTIONS'));
      })
      .catch((err) => {
        const { errorMessages, errorTitle } = parseError(err);
        dispatch({
          type: CREATE_DIRECTION_FAIL,
          payload: { message: errorMessages.join('\n'), error: errorTitle },
        });
        dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
      });
  };
}

export function clearCreateForm() {
  return (dispatch) => {
    dispatch({ type: CLEAR_CREATE_FORM });
  };
}

export function changeDirectionCreateForm(data) {
  return (dispatch) => {
    dispatch({ type: CHANGE_DIRECTION_FORM, payload: { data } });
  };
}
