import * as DoctorActions from '../actions/create.actions';

const defaultDoctorForm = {
  firstName: '',
  lastName: '',
  address: '',
  fax: '',
  phone: '',
  pbsApproval: '',
  email: '',
  outOfHour: '',
  id: '',
};

const initialState = {
  submitting: false,
  submitSuccess: false,
  doctorForm: defaultDoctorForm,
  error: null,
  message: '',
  id: null,
};

const create = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case DoctorActions.CREATE_DOCTOR:
      return { ...state, submitting: true, submitSuccess: false, error: null };
    case DoctorActions.CREATE_DOCTOR_SUCCESS:
      return { ...state, id: action.payload.doctorId, submitting: false, submitSuccess: true };
    case DoctorActions.CREATE_DOCTOR_FAIL:
      return { ...state, submitting: false, submitSuccess: false, message: payload.message, error: payload.error };
    case DoctorActions.CLEAR_CREATE_FORM:
      return initialState;
    case DoctorActions.CHANGE_DOCTOR_CREATE_FORM:
      return { ...state, doctorForm: { ...state.doctorForm, ...payload.data } };
    default:
      return state;
  }
};

export default create;
