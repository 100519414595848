// http://mcg.mbitson.com/
const palette = {
  50: '#fceee6',
  100: '#f7d4c1',
  200: '#f2b898',
  300: '#ed9b6f',
  400: '#e98550',
  500: '#e57031',
  600: '#e2682c',
  700: '#de5d25',
  800: '#da531f',
  900: '#d34113',
  A100: '#ffffff',
  A200: '#ffd9cf',
  A400: '#ffb09c',
  A700: '#ff9c83',
  contrastText: '#ffffff',
};

export default palette;
