import { HttpService } from 'app/services';
import * as AppActions from 'app/store/actions';
import { parseError } from 'helpers/Error';

export const CREATE_USER = '@USER/CREATE_USER';
export const CREATE_USER_SUCCESS = '@USER/CREATE_USER_SUCCESS';
export const CREATE_USER_FAIL = '@USER/CREATE_USER_FAIL';

export const CHANGE_USER_FORM = '@USER/CHANGE_USER_FORM';
export const CLEAR_CREATE_FORM = '@USER/CLEAR_CREATE_FORM';

export function createUser() {
  return (dispatch, getState) => {
    const { data } = getState().user.create;

    dispatch({ type: CREATE_USER });
    HttpService.post(`api/users`, {
      ...data,
      gender: 0,
      dob: new Date(),
      roleIds: [data.roleIds],
    })
      .then((resp) => {
        dispatch(
          AppActions.showMessage({
            message: 'user:ADD_USER_SUCCESS',
          }),
        );
        dispatch({ type: CREATE_USER_SUCCESS, payload: { userId: resp.data.id } });
      })
      .catch((err) => {
        const { errorFields, errorMessages, errorTitle } = parseError(err);
        dispatch({
          type: CREATE_USER_FAIL,
          payload: { message: errorMessages.join('\n'), error: errorFields },
        });
        dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
      });
  };
}

export function clearCreateForm() {
  return (dispatch) => {
    dispatch({ type: CLEAR_CREATE_FORM });
  };
}

export function changeUserCreateForm(data) {
  return (dispatch) => {
    dispatch({ type: CHANGE_USER_FORM, payload: { data } });
  };
}
