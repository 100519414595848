import { HttpService } from 'app/services';
import * as AppActions from 'app/store/actions';
import { parseError } from 'helpers/Error';

export const PRINT_REPORT = '@REPORT/CREATE_PATIENT_GROUP';
export const PRINT_REPORT_SUCCESS = '@REPORT/PRINT_REPORT_SUCCESS';
export const PRINT_REPORT_FAIL = '@REPORT/PRINT_REPORT_FAIL';

export const CHANGE_PRINT_REPORT_FORM = '@REPORT/CHANGE_PRINT_REPORT_FORM';

export const RESET_PRINT_REPORT_FORM = '@REPORT/RESET_PRINT_REPORT_FORM';

export const SET_QUICK_PRINT_REPORT = '@REPORT/SET_QUICK_PRINT_REPORT';
export const CLEAR_QUICK_PRINT_REPORT = '@REPORT/CLEAR_QUICK_PRINT_REPORT';

export const CLEAR_HISTORY_FILE = '@REPORT/CLEAR_HISTORY_FILE';

export function printReport() {
  return (dispatch, getState) => {
    const { data } = getState().report.print;
    const postData = { ...data };
    delete postData.reportSettingId;
    delete postData.patientGroupIds;
    delete postData.wards;
    delete postData.isPrintIndications;

    dispatch({ type: PRINT_REPORT });
    HttpService.post(`api/reports/export/${data.reportSettingId}`, postData)
      .then((resp) => {
        if (resp.data) {
          dispatch({ type: PRINT_REPORT_SUCCESS, payload: { data: resp.data } });
        } else {
          dispatch({ type: PRINT_REPORT_FAIL });
          dispatch(AppActions.createDialogMessage('info', 'report:NO_CONTENT_TITLE', 'report:NO_CONTENT_MESSAGE'));
        }
      })
      .catch((err) => {
        const { errorFields, errorMessages, errorTitle } = parseError(err);
        dispatch({
          type: PRINT_REPORT_FAIL,
          payload: { message: errorMessages, error: errorFields },
        });
        dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
      });
  };
}

export function changePrintReportForm(data) {
  return (dispatch) => {
    dispatch({ type: CHANGE_PRINT_REPORT_FORM, payload: { data } });
  };
}

export function resetPrintReportForm() {
  return (dispatch) => {
    dispatch({ type: RESET_PRINT_REPORT_FORM });
  };
}

export function setQuickPrintReport(data) {
  return (dispatch) => {
    dispatch({ type: SET_QUICK_PRINT_REPORT, payload: { data } });
  };
}

export function clearQuickPrintReport() {
  return (dispatch) => {
    dispatch({ type: CLEAR_QUICK_PRINT_REPORT });
  };
}

export function clearHistoryFile() {
  return (dispatch) => {
    dispatch({ type: CLEAR_HISTORY_FILE });
  };
}
