import { combineReducers } from 'redux';
import base from './update.base.reducers';
import details from './update.details.reducers';
import drugApn from './update.drugApn.reducers';
import preferredBrand from './update.preferredBrands.reducers';
import header from './update.header.reducers';

const drugUpdateReducers = combineReducers({
  base,
  details,
  drugApn,
  preferredBrand,
  header,
});

export default drugUpdateReducers;
