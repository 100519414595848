import { HttpService } from 'app/services';
import * as AppActions from 'app/store/actions';
import { parseError } from '../../../../../helpers/Error';

export const GET_DRUGS = '@PATIENT/GET_DRUGS';
export const GET_DRUGS_SUCCESS = '@PATIENT/GET_DRUGS_SUCCESS';
export const GET_DRUGS_FAIL = '@PATIENT/GET_DRUGS_FAIL';

export const GET_PREFERRED_DRUGS = '@PATIENT/GET_PREFERRED_DRUGS';
export const GET_PREFERRED_DRUGS_SUCCESS = '@PATIENT/GET_PREFERRED_DRUGS_SUCCESS';
export const GET_PREFERRED_DRUGS_FAIL = '@PATIENT/GET_PREFERRED_DRUGS_FAIL';

export const GET_DRUG_DETAIL = '@PATIENT/GET_DRUG_DETAIL';
export const GET_DRUG_DETAIL_SUCCESS = '@PATIENT/GET_DRUG_DETAIL_SUCCESS';
export const GET_DRUG_DETAIL_FAIL = '@PATIENT/GET_DRUG_DETAIL_FAIL';

export const getDrugs = (textSearch) => {
  return (dispatch) => {
    dispatch({ type: GET_DRUGS });
    HttpService.get(`api/drugs`, { ...(textSearch && { query: textSearch }) })
      .then((resp) => {
        dispatch({
          type: GET_DRUGS_SUCCESS,
          payload: { data: resp.data },
        });
      })
      .catch((err) => {
        const { errorMessages } = parseError(err);
        dispatch({ type: GET_DRUGS_FAIL, payload: { message: errorMessages.join('\n') } });
        dispatch(AppActions.createDialogMessage('error', 'Error', errorMessages));
      });
  };
};

export const getPreferredDrugs = (drugId) => {
  return (dispatch) => {
    dispatch({ type: GET_PREFERRED_DRUGS });
    HttpService.get(`api/drugs/${drugId}/preferredbrands`)
      .then((resp) => {
        dispatch({
          type: GET_PREFERRED_DRUGS_SUCCESS,
          payload: { data: resp.data },
        });
      })
      .catch((err) => {
        const { errorMessages } = parseError(err);
        dispatch({ type: GET_PREFERRED_DRUGS_FAIL, payload: { message: errorMessages.join('\n') } });
        dispatch(AppActions.createDialogMessage('error', 'Error', errorMessages));
      });
  };
};

export const getDrugDetail = (drugId) => {
  return (dispatch) => {
    dispatch({ type: GET_DRUG_DETAIL });
    HttpService.get(`api/drugs/${drugId}`)
      .then((resp) => {
        // const formData = parseDrugToPrescriptionFrom(resp.data);
        dispatch({
          type: GET_DRUG_DETAIL_SUCCESS,
          payload: { data: resp.data },
        });
      })
      .catch((err) => {
        const { errorMessages } = parseError(err);
        dispatch({ type: GET_DRUG_DETAIL_FAIL, payload: { message: errorMessages.join('\n') } });
        dispatch(AppActions.createDialogMessage('error', 'Error', errorMessages));
      });
  };
};
