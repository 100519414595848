import { HttpService } from 'app/services';
import { parseError } from 'helpers/Error';
import * as AppActions from 'app/store/actions';

export const REMOVE_PRINTER_SETTING = '@PHARMACY/REMOVE_PRINTER_SETTING';
export const REMOVE_PRINTER_SETTING_SUCCESS = '@PHARMACY/REMOVE_PRINTER_SETTING_SUCCESS';
export const REMOVE_PRINTER_SETTING_FAIL = '@PHARMACY/REMOVE_PRINTER_SETTING_FAIL';
export const SET_SELECTED_PRINTER_SETTING = '@PHARMACY/SET_SELECTED_PRINTER_SETTING';
export const ADD_PRINTER_SETTING = '@PHARMACY/ADD_PRINTER_SETTING';
export const ADD_PRINTER_SETTING_SUCCESS = '@PHARMACY/ADD_PRINTER_SETTING_SUCCESS';
export const ADD_PRINTER_SETTING_FAIL = '@PHARMACY/ADD_PRINTER_SETTING_FAIL';
export const UPDATE_PRINTER_SETTING = '@PHARMACY/UPDATE_PRINTER_SETTING';
export const UPDATE_PRINTER_SETTING_SUCCESS = '@PHARMACY/UPDATE_PRINTER_SETTING_SUCCESS';
export const UPDATE_PRINTER_SETTING_FAIL = '@PHARMACY/UPDATE_PRINTER_SETTING_FAIL';

export const removePrinterSetting = (pharmacyId, printerSettingId) => {
  return (dispatch) => {
    dispatch(AppActions.closeDialogMessage('printer-setting-remove'));
    const deleteApi = `api/printer-settings/${printerSettingId}`;
    HttpService.abort(`removePrinterSetting`);
    HttpService.delete(deleteApi, { cancelToken: `removePrinterSetting` })
      .then(() => {
        dispatch(
          AppActions.showMessage({
            message: 'pharmacy:REMOVE_PRINTER_SETTING_SUCCESS',
          }),
        );
        dispatch({ type: REMOVE_PRINTER_SETTING_SUCCESS });
        dispatch(AppActions.dataLoader_reload(`GetPrinterSetting`));
      })
      .catch((err) => {
        const { errorMessages, errorTitle } = parseError(err);
        dispatch({ type: REMOVE_PRINTER_SETTING_FAIL, payload: { message: errorMessages.join('\n') } });
        dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
      });
  };
};

export const setSelectedPrinterSetting = (printerSetting) => {
  printerSetting.blisterType =
    printerSetting.clientCode != null && printerSetting.foilPackingType != null
      ? `${printerSetting.clientCode},${printerSetting.foilPackingType}`
      : '';
  return (dispatch) => {
    dispatch({ type: SET_SELECTED_PRINTER_SETTING, payload: { printerSetting } });
  };
};

export const addPrinterSetting = ({ pharmacyId, blisterType, defaultPrinter }) => {
  const blisterTypes = blisterType.split(',');
  return (dispatch) => {
    dispatch({ type: ADD_PRINTER_SETTING });
    HttpService.post(`/api/printer-settings`, {
      clientCode: blisterTypes[0],
      foilPackingType: blisterTypes[1],
      defaultPrinter,
      pharmacyId,
    })
      .then((resp) => {
        dispatch({
          type: ADD_PRINTER_SETTING_SUCCESS,
        });
        dispatch(AppActions.showMessage({ message: 'pharmacy:CREATE_PRINTER_SETTING_SUCCESS' }));
        dispatch(AppActions.dataLoader_reload(`GetPrinterSetting`));
      })
      .catch((err) => {
        const { errorMessages } = parseError(err);
        dispatch({ type: ADD_PRINTER_SETTING_FAIL, payload: { message: errorMessages.join('\n') } });
        dispatch(AppActions.createDialogMessage('error', 'Error', errorMessages));
      });
  };
};

export const updatePrinterSetting = ({ pharmacyId, id, blisterType, defaultPrinter }) => {
  const blisterTypes = blisterType.split(',');
  return (dispatch) => {
    dispatch({ type: UPDATE_PRINTER_SETTING });
    HttpService.put(`/api/printer-settings/${id}`, {
      clientCode: blisterTypes[0],
      foilPackingType: blisterTypes[1],
      defaultPrinter,
      pharmacyId,
    })
      .then((resp) => {
        dispatch({
          type: UPDATE_PRINTER_SETTING_SUCCESS,
        });
        dispatch(AppActions.showMessage({ message: 'pharmacy:UPDATE_PRINTER_SETTING_SUCCESS' }));
        dispatch(AppActions.dataLoader_reload(`GetPrinterSetting`));
      })
      .catch((err) => {
        const { errorMessages } = parseError(err);
        dispatch({ type: UPDATE_PRINTER_SETTING_FAIL, payload: { message: errorMessages.join('\n') } });
        dispatch(AppActions.createDialogMessage('error', 'Error', errorMessages));
      });
  };
};
