export const METHOTREXATE_DRUG_CODE = [
  'EBEW5',
  'EBEW6',
  'EBEW7',
  'LE56',
  'LE78',
  'LED2',
  'LED8',
  'LED9',
  'LEDE1',
  'LEDE2',
  'M110',
  'M111',
  'M112',
  'M113',
  'M567',
  'ME75',
  'ME80',
  'ME88',
  'METH20',
  'METH21',
  'METH50',
  'METH56',
  'METH57',
  'METH58',
  'METH59',
  'METH60',
  'METH61',
  'METH62',
  'METH65',
  'METH66',
  'METH67',
  'METH68',
  'METH69',
  'METH70',
  'METH71',
  'METH78',
  'METH79',
  'METH80',
  'METH81',
  'METH82',
  'MTH1',
  'MTH2',
  'MTH3',
  'MTX1',
  'MTX2',
  'MTX8',
  'MTX9',
  'TREX1',
  'TREX2',
  'TREX3',
  'TREX4',
  'TREX5',
];

export const DUPLICATE_PRESCRIPTION = 'DUPLICATE_PRESCRIPTION';

export const SACHET_NUMBER_OF_HEADING = 8;

export const BLISTER_NUMBER_OF_HEADING = 4;

export const DAA_FUNDING = {
  NOT_ELIGIBLE: '0',
  CPA_VARIOUS: '1',
  LITERACY_LANGUAGE_ISSUES: '2',
  PHYSICAL_DISABILITY: '3',
  COGNITIVE_DIFFICULTIES: '4',
  ABORIGINAL_TORRES_STRAIT_ISLANDER: '5',
};

export const DAA_FUNDING_DATA = [
  {
    label: 'Not Eligible',
    value: DAA_FUNDING.NOT_ELIGIBLE,
  },
  {
    label: 'PPA Various',
    value: DAA_FUNDING.CPA_VARIOUS,
  },
  {
    label: 'Literacy Language Issues',
    value: DAA_FUNDING.LITERACY_LANGUAGE_ISSUES,
  },
  {
    label: 'Physical Disability',
    value: DAA_FUNDING.PHYSICAL_DISABILITY,
  },
  {
    label: 'Cognitive Difficulties',
    value: DAA_FUNDING.COGNITIVE_DIFFICULTIES,
  },
  {
    label: 'Aboriginal/Torres Strait Islander',
    value: DAA_FUNDING.ABORIGINAL_TORRES_STRAIT_ISLANDER,
  },
];

export const GENDER = [
  {
    label: 'Male',
    value: '0',
  },
  {
    label: 'Female',
    value: '1',
  },
  {
    label: 'Not Specified',
    value: '2',
  },
];

export const PATIENT_STATUS_TYPES = {
  PICKING_UP_PACKS: '0',
  DELIVERY_PACKS: '1',
  IN_HOSPITAL: '2',
  SOCIAL_LEAVE: '3',
  IN_RESPITE: '4',
  DECEASED: '5',
  DISCHARGED: '6',
  DELETED: '7',
};

export const PATIENT_STATUS = [
  {
    label: 'Picking up packs',
    value: PATIENT_STATUS_TYPES.PICKING_UP_PACKS,
  },
  {
    label: 'Delivery Packs',
    value: PATIENT_STATUS_TYPES.DELIVERY_PACKS,
  },
  {
    label: 'In Hospital',
    value: PATIENT_STATUS_TYPES.IN_HOSPITAL,
  },
  {
    label: 'Social Leave',
    value: PATIENT_STATUS_TYPES.SOCIAL_LEAVE,
  },
  {
    label: 'In Respite',
    value: PATIENT_STATUS_TYPES.IN_RESPITE,
  },
  {
    label: 'Deceased',
    value: PATIENT_STATUS_TYPES.DECEASED,
  },
  {
    label: 'Discharged',
    value: PATIENT_STATUS_TYPES.DISCHARGED,
  },
  {
    label: 'Deleted',
    value: PATIENT_STATUS_TYPES.DELETED,
    isDisabled: true,
  },
];

export const PACKING_STATUS_TYPES = {
  ACTIVE: '0',
  SUSPENDED: '1',
  INACTIVE: '2',
  NEWLY_IMPORTED: '3',
};

export const PACKING_STATUS = [
  {
    label: 'Active',
    value: PACKING_STATUS_TYPES.ACTIVE,
  },
  {
    label: 'Suspended',
    value: PACKING_STATUS_TYPES.SUSPENDED,
  },
  {
    label: 'Newly Imported',
    value: PACKING_STATUS_TYPES.NEWLY_IMPORTED,
  },
  {
    label: 'Inactive',
    value: PACKING_STATUS_TYPES.INACTIVE,
  },
];

export const CONCESSION_TYPE = {
  EXT: '0',
  PEN: '1',
  SFN: '2',
  VET: '3',
};

export const CONCESSION = [
  {
    label: 'EXT',
    value: CONCESSION_TYPE.EXT,
  },
  {
    label: 'PEN',
    value: CONCESSION_TYPE.PEN,
  },
  {
    label: 'SFN',
    value: CONCESSION_TYPE.SFN,
  },
  {
    label: 'VET',
    value: CONCESSION_TYPE.VET,
  },
];

export const FOIL_TYPE = {
  BLISTER: 0,
  SACHET: 1,
};

export const PACK_TYPE_PRESCRIPTION_TYPES = {
  PACKED: '0',
  UNPACKED: '1',
  PRN: '2',
  UNPACKED_PRN: '3',
};

export const PACK_TYPE_PRESCRIPTION_LABEL = {
  PACKED: 'Packed',
  NON_PACKED: 'Non-Packed',
  PRN: 'Packed PRN',
  NO_PRN: 'Non-Packed PRN',
};

export const PACK_TYPE_PRESCRIPTION = [
  {
    label: PACK_TYPE_PRESCRIPTION_LABEL.PACKED,
    value: PACK_TYPE_PRESCRIPTION_TYPES.PACKED,
  },
  {
    label: PACK_TYPE_PRESCRIPTION_LABEL.NON_PACKED,
    value: PACK_TYPE_PRESCRIPTION_TYPES.UNPACKED,
  },
  {
    label: PACK_TYPE_PRESCRIPTION_LABEL.PRN,
    value: PACK_TYPE_PRESCRIPTION_TYPES.PRN,
  },
  {
    label: PACK_TYPE_PRESCRIPTION_LABEL.NO_PRN,
    value: PACK_TYPE_PRESCRIPTION_TYPES.UNPACKED_PRN,
  },
];

export const PACK_TYPE_PRESCRIPTION_ENUM = {
  PACKED: '0',
  UNPACKED: '1',
  PRN: '2',
  UNPACKED_PRN: '3',
};

export const PRESCRIPTION_ROUTE_DEFAULT_VALUE = 'PO'; // Oral

export const PRESCRIPTION_FREQUENCY = [
  {
    label: 'Daily',
    value: '0',
  },
  {
    label: 'Alt Day',
    value: '1',
  },
  {
    label: 'On-Off Day',
    value: '2',
  },
  {
    label: 'Every 3rd day',
    value: '3',
  },
  {
    label: 'Monthly',
    value: '4',
  },
  {
    label: 'Every X Days',
    value: '5',
  },
  {
    label: 'Specific Days',
    value: '6',
  },
  {
    label: 'Fortnightly',
    value: '14',
  },
  {
    label: 'Every 28 Days',
    value: '28',
  },
];

export const PRESCRIPTION_FREQUENCY_ENUM = {
  DAILY: '0',
  ALT_DAY: '1',
  ON_OFF_DAY: '2',
  EVERY_3RD_DAY: '3',
  MONTHLY: '4',
  EVERY_X_DAYS: '5',
  SPECIFIC_DAYS: '6',
  FORTNIGHTLY: '14',
  EVERY_28_DAYS: '28',
};

export const DAY_OF_WEEK = [
  {
    label: 'Monday',
    value: '1',
  },
  {
    label: 'Tuesday',
    value: '2',
  },
  {
    label: 'Wednesday',
    value: '3',
  },
  {
    label: 'Thursday',
    value: '4',
  },
  {
    label: 'Friday',
    value: '5',
  },
  {
    label: 'Saturday',
    value: '6',
  },
  {
    label: 'Sunday',
    value: '0',
  },
];

export const PRESCRIPTION_DOSE_TYPES = {
  STANDARD: 'Standard',
  INSULIN: 'Insulin',
  S8_DRUG: 'S8 Drug',
  CYTOTOCIX: 'Cytotoxic',
  WARFARIN: 'Warfarin',
  IS_S4N: 'isS4N',
  SHORTCOURSE: 'ShortCourse',
  SINEMET: 'Sinemet',
};

export const MED_CHANGE_HISTORY_STATE_ENUM = {
  0: 'New',
  1: 'Updated',
  2: 'Deleted',
  3: 'Ceased',
};

export const PATIENT_HITORY_STATE_ENUM = {
  0: 'New',
  1: 'Updated',
  2: 'Deleted',
};

export const PATIENT_HITORY_TYPE_ENUM = {
  PATIENT_DETAILS: 0,
  ALLERGIES: 1,
  CONSIDERATIONS: 2,
  DOCTORS: 3,
  CONTACTS: 4,
  NOTES: 5,
  BLISTER_PACK: 6,
  DOCUMENTS: 7,
};

export const PATIENT_HITORY_TYPE_LABEL = {
  [PATIENT_HITORY_TYPE_ENUM.PATIENT_DETAILS]: 'Patient Details ',
  [PATIENT_HITORY_TYPE_ENUM.ALLERGIES]: 'Allergies',
  [PATIENT_HITORY_TYPE_ENUM.CONSIDERATIONS]: 'Considerations',
  [PATIENT_HITORY_TYPE_ENUM.DOCTORS]: 'Doctor',
  [PATIENT_HITORY_TYPE_ENUM.CONTACTS]: 'Emergency Contact',
  [PATIENT_HITORY_TYPE_ENUM.NOTES]: 'Notes',
  [PATIENT_HITORY_TYPE_ENUM.BLISTER_PACK]: 'Blister Pack',
  [PATIENT_HITORY_TYPE_ENUM.DOCUMENTS]: 'Documents',
};

export const STATES = [
  {
    label: 'ACT',
    value: 'ACT',
  },
  {
    label: 'NSW',
    value: 'NSW',
  },
  {
    label: 'NT',
    value: 'NT',
  },
  {
    label: 'QLD',
    value: 'QLD',
  },
  {
    label: 'SA',
    value: 'SA',
  },
  {
    label: 'TAS',
    value: 'TAS',
  },
  {
    label: 'VIC',
    value: 'VIC',
  },
  {
    label: 'WA',
    value: 'WA',
  },
];

export const PACK_TYPES = [
  { label: 'SOLID', value: '0' },
  { label: 'PERF', value: '1' },
  { label: 'TRIFOLD', value: '2' },
  { label: 'MEDICO PAK', value: '3' },
];

export const CLIENTS_LABEL = {
  MYPAK: 'MYPAK',
  PPAK: 'PPAK',
  MEDICO: 'MEDICO',
};

export const CLIENTS = [
  { label: CLIENTS_LABEL.MYPAK, value: '0' },
  { label: CLIENTS_LABEL.PPAK, value: '3' },
  { label: CLIENTS_LABEL.MEDICO, value: '2' },
];

export const CLIENTS_PACK_LABEL = {
  MYPAK_SOLID: 'myPak - Solid',
  MYPAK_PERF: 'myPak - Perf',
  MYPAK_TRIFOLD: 'myPak - Trifold',
  MEDICO: 'Medico Pak',
};

export const CLIENTS_PACK_VALUE = {
  SOLID: '0,0',
  PERF: '0,1',
  TRIFOLD: '0,2',
  MEDICO: '2,3',
};

export const CLIENTS_PACK_TYPE = [
  { label: CLIENTS_PACK_LABEL.MYPAK_SOLID, value: CLIENTS_PACK_VALUE.SOLID },
  { label: CLIENTS_PACK_LABEL.MYPAK_PERF, value: CLIENTS_PACK_VALUE.PERF },
  { label: CLIENTS_PACK_LABEL.MYPAK_TRIFOLD, value: CLIENTS_PACK_VALUE.TRIFOLD },
  { label: CLIENTS_PACK_LABEL.MEDICO, value: CLIENTS_PACK_VALUE.MEDICO },
  // { label: 'PPAK', value: '3,0' } MC-593015
];

export const FREQUENCIES_VALUE = {
  WEEKLY: '0',
  FORTNIGHTLY: '1',
  MONTHLY: '2',
};

export const FREQUENCIES = [
  { label: 'Weekly', value: FREQUENCIES_VALUE.WEEKLY },
  { label: 'Fortnightly', value: FREQUENCIES_VALUE.FORTNIGHTLY },
  { label: 'Monthly', value: FREQUENCIES_VALUE.MONTHLY },
];

export const HEADING_ACTIVE = {
  [FREQUENCIES_VALUE.WEEKLY]: [0, 1, 2, 3], // all time active
  [FREQUENCIES_VALUE.FORTNIGHTLY]: [0, 1], // first 2 time active
  [FREQUENCIES_VALUE.MONTHLY]: [0], // first time active
};

export const DOSE_TYPES = [
  // { label: 'UNIT-DOSE', value: '0' },
  { label: 'Multi - Dose', value: '1' },
];

export const ACTIVE = [
  { label: 'Active', value: 'active' },
  { label: 'Inactive', value: 'inactive' },
];

export const BLISTER_SIZE = [
  { label: '9 mm', value: 9 },
  { label: '12 mm', value: 12 },
  { label: '18 mm', value: 18 },
  { label: '24 mm', value: 24 },
  { label: '30 mm', value: 30 },
];

export const WARNING_LABELS = [
  { label: 'May affect alertness and/or co-ordination', value: 'May affect alertness and/or co-ordination' },
  {
    label: 'May cause drowsiness and increase effects of alcohol',
    value: 'May cause drowsiness and increase effects of alcohol',
  },
  {
    label: 'This preparation is to aid sleep. Drowsiness may continue',
    value: 'This preparation is to aid sleep. Drowsiness may continue',
  },
];

export const PRESCRIPTION_UNIT = {
  ML: 'ml',
  PATCHES: 'patches',
  TABLETS: 'Tablets',
  CAPSULES: 'Capsules',
};

export const PRESCRIPTION_UNIT_DATA = [
  { label: 'Tablets', value: PRESCRIPTION_UNIT.TABLETS },
  { label: 'Capsules', value: PRESCRIPTION_UNIT.CAPSULES },
  { label: 'ml', value: PRESCRIPTION_UNIT.ML },
  { label: 'patches', value: PRESCRIPTION_UNIT.PATCHES },
];

export const PRESCRIPTION_STATE_ENUM = {
  NEW: 0,
  UPDATED: 1,
  CEASED: 2,
};

export const PACK_TYPE_ENUM = {
  SOLID: '0',
  PERF: '1',
  TRIFOLD: '2',
};

export const TITLE_DATA = [
  { label: 'Mast', value: 'Mast' },
  { label: 'Mr', value: 'Mr' },
  { label: 'Mrs', value: 'Mrs' },
  { label: 'Ms', value: 'Ms' },
  { label: 'Miss', value: 'Miss' },
  { label: 'Prof', value: 'Prof' },
  { label: 'Dr', value: 'Dr' },
  { label: 'Sir', value: 'Sir' },
];

export const DISTRIBUTION_STATUS = {
  NONE: '-1',
  PICKUP: '0',
  DELIVERY: '1',
};

export const DISTRIBUTION_STATUS_LABEL = {
  [DISTRIBUTION_STATUS.NONE]: 'None',
  [DISTRIBUTION_STATUS.PICKUP]: 'Pickup',
  [DISTRIBUTION_STATUS.DELIVERY]: 'Delivery',
};

export const DISTRIBUTION_STATUS_DATA = [
  { label: DISTRIBUTION_STATUS_LABEL[DISTRIBUTION_STATUS.PICKUP], value: DISTRIBUTION_STATUS.PICKUP },
  { label: DISTRIBUTION_STATUS_LABEL[DISTRIBUTION_STATUS.DELIVERY], value: DISTRIBUTION_STATUS.DELIVERY },
];

export const NOTE_USAGE = {
  PATIENT_LIST: 'PATIENT_LIST',
  REPORT: 'REPORT',
  DISTRIBUTION: 'DISTRIBUTION',
  NONE: 'NONE',
};

export const NOTE_USAGE_LABEL = {
  [NOTE_USAGE.PATIENT_LIST]: 'Patient List',
  [NOTE_USAGE.REPORT]: 'Report',
  [NOTE_USAGE.DISTRIBUTION]: 'Distribution',
  [NOTE_USAGE.NONE]: 'None',
};

export const DOCUMENT_FILE_TYPE = {
  DOCUMENT: 1,
  IMAGE: 2,
};

export const DOCUMENT_FILE_TYPE_LABEL = {
  [DOCUMENT_FILE_TYPE.DOCUMENT]: 'Document',
  [DOCUMENT_FILE_TYPE.IMAGE]: 'Image',
};

export const HISTORY_VIEW = {
  MEDICATION: 'MEDICATION',
  PATIENT: 'PATIENT',
};

export const LAST_ACTION = {
  OPEN_DOCTOR_MODAL: 'openDoctorModal',
  KEY_ACTION: 'patientAddDoctor',
};
