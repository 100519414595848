export const DIALOG_MESSAGE_CLOSE = '@DIALOG_MESSAGE/CLOSE';
export const DIALOG_MESSAGE_OPEN = '@DIALOG_MESSAGE/OPEN';
export const DIALOG_MESSAGE_SET_CALLBACK = '@DIALOG_MESSAGE/SET_CALLBACK';
export const DIALOG_MESSAGE_CLEAR = '@DIALOG_MESSAGE/CLEAR';
export const DIALOG_MESSAGE_NEW_INSTANCE = '@DIALOG_MESSAGE/NEW_INSTANCE';
export const DIALOG_MESSAGE_CREATE = '@DIALOG_MESSAGE/DIALOG_MESSAGE_CREATE';

export const createDialogMessage = (type, title, messages, otherConfig = {}) => {
  const { dialogId, callback, ...restConfig } = otherConfig;
  return (dispatch) => {
    dispatch({
      type: DIALOG_MESSAGE_CREATE,
      payload: {
        dialogId: dialogId || new Date().getTime(),
        dialog: { type, title, messages, callback, ...restConfig },
      },
    });
  };
};

export const closeDialogMessage = (dialogId) => {
  return (dispatch) => {
    dispatch({ type: DIALOG_MESSAGE_CLOSE, payload: { dialogId } });
  };
};

export const setDialogMessageCallback = (dialogId, callback) => {
  return (dispatch) => {
    dispatch({ type: DIALOG_MESSAGE_SET_CALLBACK, payload: { dialogId, callback } });
  };
};

export const clearDialogMessage = (dialogId) => {
  return (dispatch) => {
    dispatch({ type: DIALOG_MESSAGE_CLEAR, payload: { dialogId } });
  };
};

export const newDialogMessageInstance = (dialogId, dialog) => {
  return (dispatch) => {
    dispatch({ type: DIALOG_MESSAGE_NEW_INSTANCE, payload: { dialogId, dialog } });
  };
};
