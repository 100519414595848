import * as ForgotPassActions from '../actions/forgotPassword.actions';

const initialState = {
  loading: false,
  error: null,
  success: false,
};

const forgotPass = (state = initialState, action) => {
  switch (action.type) {
    case ForgotPassActions.SEND_RECOVER_PASSWORD_REQUEST:
      return { ...state, loading: true, error: null, success: false };
    case ForgotPassActions.SEND_RECOVER_PASSWORD_REQUEST_SUCCESS:
      return { ...state, success: true, loading: false };
    case ForgotPassActions.SEND_RECOVER_PASSWORD_REQUEST_FAILED:
      return { ...state, error: action.payload.error, loading: false, success: false };
    case ForgotPassActions.RESET_STATE:
      return initialState;
    default:
      return state;
  }
};

export default forgotPass;
