export const ACTIVE = [
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
];

export const TIMEZONE_LIST = [
  {
    label: '(UTC+10:00) Hobart',
    value: 'Tasmania Standard Time',
    offset: 'UTC+10:00',
    offsetMinutes: 600,
  },
  {
    label: '(UTC+10:00) Brisbane',
    value: 'E. Australia Standard Time',
    offset: 'UTC+10:00',
    offsetMinutes: 600,
  },
  {
    label: '(UTC+10:00) Canberra, Melbourne, Sydney',
    value: 'AUS Eastern Standard Time',
    offset: 'UTC+10:00',
    offsetMinutes: 600,
  },
  {
    label: '(UTC+08:00) Perth',
    value: 'W. Australia Standard Time',
    offset: 'UTC+08:00',
    offsetMinutes: 480,
  },
  {
    label: '(UTC+09:30) Adelaide',
    value: 'AUS Central Standard Time',
    offset: 'UTC+09:30',
    offsetMinutes: 570,
  },
];

export const DISPENSING_SYSTEM = [
  { label: 'None', value: '-1' },
  { label: 'FRED', value: '0' },
  { label: 'FredPak', value: '1' },
  { label: 'Minfos', value: '9' },
  { label: 'Lots_MSAccess', value: '3' },
  { label: 'Lots_SQL', value: '4' },
  { label: 'Z', value: '5' },
  { label: 'Simple', value: '6' },
  { label: 'MyScript', value: '7' },
  { label: 'AquariusWebService', value: '8' },
  { label: 'FredNXT', value: '10' },
  { label: 'CorumClear', value: '11' },
  { label: 'Dispense Works', value: '12' },
  { label: 'Fred Dispense Plus', value: '13' },
];

export const INTERGRATOR = [
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
];

export const CUSTOM_OPTION_VALUE = '$CUSTOM_OPTION$';

export const VPB_TYPES = {
  YES: 'true',
  NO: 'false',
};
export const QSCRIPT_FEATURE_TYPES = {
  YES: 'true',
  NO: 'false',
};
export const DISPENSE_TRACKER_TYPES = {
  YES: 'true',
  NO: 'false',
};

export const VPB = [
  {
    label: 'Yes',
    value: VPB_TYPES.YES,
  },
  {
    label: 'No',
    value: VPB_TYPES.NO,
  },
];
export const QSCRIPT_FEATURE = [
  {
    label: 'Yes',
    value: QSCRIPT_FEATURE_TYPES.YES,
  },
  {
    label: 'No',
    value: QSCRIPT_FEATURE_TYPES.NO,
  },
];
export const DISPENSE_TRACKER = [
  {
    label: 'Yes',
    value: DISPENSE_TRACKER_TYPES.YES,
  },
  {
    label: 'No',
    value: DISPENSE_TRACKER_TYPES.NO,
  },
];

const NEVER_LOG_OUT = 'NEVER';

export const TIME_ACTIVITY = [
  { label: '15 min', value: 15 },
  { label: '20 min', value: 20 },
  { label: '25 min', value: 25 },
  { label: '30 min', value: 30 },
  { label: '45 min', value: 45 },
  { label: '1 hours', value: 60 },
  { label: '2 hours', value: 120 },
  { label: '3 hours', value: 180 },
  { label: '4 hours', value: 240 },
];
