import queryString from 'query-string';
import { HttpService } from 'app/services';
import * as AppActions from 'app/store/actions';
import { parseError } from 'helpers/Error';

export const GET_MEDICATION_HISTORIES = '@PATIENT/GET_MEDICATION_HISTORIES';
export const GET_MEDICATION_HISTORIES_SUCCESS = '@PATIENT/GET_MEDICATION_HISTORIES_SUCCESS';
export const GET_MEDICATION_HISTORIES_FAIL = '@PATIENT/GET_MEDICATION_HISTORIES_FAIL';

export const GET_MEDICATION_HISTORY_DETAIL = '@PATIENT/GET_MEDICATION_HISTORY_DETAIL';
export const GET_MEDICATION_HISTORY_DETAIL_SUCCESS = '@PATIENT/GET_MEDICATION_HISTORY_DETAIL_SUCCESS';
export const GET_MEDICATION_HISTORY_DETAIL_FAIL = '@PATIENT/GET_MEDICATION_HISTORY_DETAIL_FAIL';

export const SET_MEDICATION_HISTORY_FILTER = '@PATIENT/SET_MEDICATION_HISTORY_FILTER';

export const CLEAR_HISTORY_DETAIL = '@PATIENT/CLEAR_HISTORY_DETAIL';
export const CLEAR_HISTORY_FILTER = '@PATIENT/CLEAR_HISTORY_FILTER';

export const PRINT_HISTORY_REPORT = '@PATIENT/PRINT_HISTORY_REPORT';
export const PRINT_HISTORY_REPORT_SUCCESS = '@PATIENT/PRINT_HISTORY_REPORT_SUCCESS';
export const PRINT_HISTORY_REPORT_FAIL = '@PATIENT/PRINT_HISTORY_REPORT_FAIL';

export const CLEAR_HISTORY_FILE = '@PATIENT/CLEAR_HISTORY_FILE';

export const getMedicationHistories = (filter) => {
  return (dispatch, getStore) => {
    dispatch({ type: GET_MEDICATION_HISTORIES });
    HttpService.get('/api/medchangehistorys')
      .then((resp) => {
        dispatch({
          type: GET_MEDICATION_HISTORIES_SUCCESS,
          payload: { data: { ...resp.data } },
        });
      })
      .catch((err) => {
        dispatch({ type: GET_MEDICATION_HISTORIES_FAIL, payload: { message: err.response.data.message } });
        dispatch(AppActions.createDialogMessage('error', 'Error', err.response.data.message));
      });
  };
};

export const getMedicationHistoryDetail = (historyId) => {
  return (dispatch, getStore) => {
    dispatch({ type: GET_MEDICATION_HISTORY_DETAIL });
    HttpService.get(`/api/medchangehistorys/${historyId}`)
      .then((resp) => {
        dispatch({
          type: GET_MEDICATION_HISTORY_DETAIL_SUCCESS,
          payload: { detail: { ...resp.data } },
        });
      })
      .catch((err) => {
        const { errorFields, errorMessages, errorTitle } = parseError(err);
        dispatch({
          type: GET_MEDICATION_HISTORY_DETAIL_FAIL,
          payload: { message: errorMessages.join('\n'), error: errorFields },
        });
        dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
      });
  };
};

export const printMedicationHistoryReport = (
  patientId,
  fromDate = null,
  toDate = null,
  drugName = null,
  genericName = null,
) => {
  return (dispatch) => {
    dispatch({ type: PRINT_HISTORY_REPORT });
    let url = `/api/medchangehistorys/export/${patientId}`;
    const queryParams = queryString.stringify(
      {
        fromDate: fromDate || null,
        toDate: toDate || null,
        drugName: drugName || null,
        genericName: genericName || null,
      },
      { strict: true, skipNull: true },
    );
    if (queryParams) url = url.concat(`?${queryParams}`);
    HttpService.post(url)
      .then((resp) => {
        dispatch({
          type: PRINT_HISTORY_REPORT_SUCCESS,
          payload: { ...resp.data },
        });
      })
      .catch((err) => {
        const { errorFields, errorMessages, errorTitle } = parseError(err);
        dispatch({
          type: PRINT_HISTORY_REPORT_FAIL,
          payload: { message: errorMessages.join('\n'), error: errorFields },
        });
        dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
      });
  };
};

export const clearHistoryFile = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_HISTORY_FILE });
  };
};

export const setMedicationHistoryFilter = (filter) => {
  return (dispatch) => {
    dispatch({ type: SET_MEDICATION_HISTORY_FILTER, payload: { filter } });
  };
};

export const clearHistoryDetail = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_HISTORY_DETAIL });
  };
};

export const clearHistoryFilter = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_HISTORY_FILTER });
  };
};
