import { HttpService } from 'app/services';
import * as AppActions from 'app/store/actions';
import { parseError } from 'helpers/Error';

export const GET_DIRECTION = '@DIRECTION/GET_DIRECTION_DETAILS';
export const GET_DIRECTION_SUCCESS = '@DIRECTION/GET_DIRECTION_SUCCESS';
export const GET_DIRECTION_FAIL = '@DIRECTION/GET_DIRECTION_FAIL';

export const UPDATE_DIRECTION = '@DIRECTION/UPDATE_DIRECTION';
export const UPDATE_DIRECTION_SUCCESS = '@DIRECTION/UPDATE_DIRECTION_SUCCESS';
export const UPDATE_DIRECTION_FAIL = '@DIRECTION/UPDATE_DIRECTION_FAIL';

export const CHANGE_DIRECTION_UPDATE_FORM = '@DIRECTION/CHANGE_DIRECTION_UPDATE_FORM';

export const CLEAR_UPDATE_FORM = '@DIRECTION/CLEAR_UPDATE_FORM';

export function getDirectionDetails(directionId) {
  return (dispatch) => {
    dispatch({ type: GET_DIRECTION });
    HttpService.get(`api/directions/${directionId}`)
      .then((resp) => {
        dispatch({ type: GET_DIRECTION_SUCCESS, payload: { data: resp.data } });
      })
      .catch((err) => {
        const { errorFields, errorMessages } = parseError(err);
        dispatch({
          type: GET_DIRECTION_FAIL,
          payload: { message: errorMessages.join('\n'), error: errorFields },
        });
        dispatch(AppActions.createDialogMessage('error', 'Error', errorMessages));
      });
  };
}

export function updateDirection(id, data) {
  return (dispatch) => {
    // const { data, id } = getState().direction.update;
    dispatch({ type: UPDATE_DIRECTION });

    HttpService.put(`api/directions/${id}`, data)
      .then(() => {
        dispatch(
          AppActions.showMessage({
            message: 'direction:UPDATE_DIRECTION_SUCCESS',
          }),
        );
        dispatch({ type: UPDATE_DIRECTION_SUCCESS });
        dispatch(AppActions.dataLoader_reload('GET:DIRECTIONS'));
      })
      .catch((err) => {
        const { errorFields, errorMessages } = parseError(err);
        dispatch({
          type: UPDATE_DIRECTION_FAIL,
          payload: { message: errorMessages.join('\n'), error: errorFields },
        });
        dispatch(AppActions.createDialogMessage('error', 'Error', errorMessages));
      });
  };
}

export function clearUpdateForm() {
  return (dispatch) => {
    dispatch({ type: CLEAR_UPDATE_FORM });
  };
}

export function changeDirectionUpdateForm(data) {
  return (dispatch) => {
    dispatch({ type: CHANGE_DIRECTION_UPDATE_FORM, payload: { data } });
  };
}
