import React from 'react';
import { useSelector } from 'react-redux';

import ROLE_LEVEL from 'app/constants/RoleLevel';

import { routes, superAdminRoutes, cacheRoutes } from './fuse-configs/routesConfig';

const AppContext = React.createContext({});

export function AppContextProvider(props) {
  const { children } = props;
  const {
    user: { data },
  } = useSelector((state) => state.auth);

  return (
    <AppContext.Provider
      value={{
        routes: data?.roleLevel === ROLE_LEVEL.SUPER_ADMIN ? superAdminRoutes : routes,
        cacheRoutes,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export default AppContext;
