import { head } from 'lodash';

import { HttpService } from 'app/services';
import { parseError } from 'helpers/Error';
import * as AppActions from 'app/store/actions';

import ROLE_LEVEL from 'app/constants/RoleLevel';

export const DASHBOARD_GET_LIST = '@DASHBOARD/GET_LIST';
export const DASHBOARD_GET_LIST_SUCCESS = '@DASHBOARD/GET_LIST_SUCCESS';
export const DASHBOARD_GET_LIST_FAIL = '@DASHBOARD/GET_LIST_FAIL';

export const getDashboards = (roleLevel) => {
  return (dispatch) => {
    dispatch({ type: DASHBOARD_GET_LIST });
    HttpService.get(`/api/dashboardpermissions/rolelevel/${roleLevel || ROLE_LEVEL.USER}`)
      .then((resp) => {
        dispatch({
          type: DASHBOARD_GET_LIST_SUCCESS,
          payload: head(resp.data)?.charts || [],
        });
      })
      .catch((err) => {
        const { errorMessages, errorTitle } = parseError(err);
        dispatch({ type: DASHBOARD_GET_LIST_FAIL, payload: { message: errorMessages } });
        dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
      });
  };
};
