import FuseNavigation from '@fuse/core/FuseNavigation';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AuthService } from 'app/services';
import * as authActions from 'app/auth/store/actions';

import ROLE_LEVEL from 'app/constants/RoleLevel';
import configs from 'app/Config';
import { makeStyles } from '@material-ui/core';

import LogoFull from './assets/myPak-NG-Logo-Full.png';
import LogoShort from './assets/myPak-NG-Logo-NG.png';

const useStyles = makeStyles(() => ({
  version: {
    color: 'white',
    marginLeft: '24px',
    marginBottom: '8px',
    opacity: 0,
  },
  logo: {
    height: 45,
    '& img': {
      height: 30,
      width: 50,
    },
  },
  logoFull: {
    height: 45,
    '& img': {
      height: 45,
      width: 150,
    },
  },
}));

function Navigation(props) {
  const {
    navigation: { navigationConfig, superAdminNavigationConfig, navigationBottomConfig },
    navbar: { foldedOpen },
  } = useSelector(({ fuse }) => fuse);
  const {
    user: { data },
  } = useSelector((state) => state.auth);

  const classes = useStyles({ foldedOpen });
  const [isActive, setIsActive] = useState(false);

  return (
    <div
      className="flex flex-col justify-between h-full"
      onMouseEnter={() => setIsActive(true)}
      onMouseLeave={() => setIsActive(false)}
    >
      <div className="top">
        <div className={clsx('px-6 flex justify-center items-center', foldedOpen ? classes.logoFull : classes.logo)}>
          <img src={foldedOpen ? LogoFull : LogoShort} alt="myPak" />
        </div>

        <FuseNavigation
          className={clsx('navigation', props.className)}
          navigation={data?.roleLevel === ROLE_LEVEL.SUPER_ADMIN ? superAdminNavigationConfig : navigationConfig}
          layout={props.layout}
          dense={props.dense}
          active={props.active}
        />
      </div>
      <div className="bottom">
        <FuseNavigation
          className={clsx('navigation', props.className)}
          navigation={navigationBottomConfig}
          layout={props.layout}
          dense={props.dense}
          active={props.active}
          isActive={isActive}
        />
        <div
          className={clsx(
            classes.version,
            foldedOpen ? 'opacity-100' : 'opacity-0',
            'text-15',
            'whitespace-no-wrap',
            'cursor-default',
          )}
        >
          V.{configs.REACT_APP_VERSION}
        </div>
      </div>
    </div>
  );
}

Navigation.defaultProps = {
  layout: 'vertical',
};

export default Navigation;
