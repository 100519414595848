export const SET_PHARMACIES_FILTER = '@ROLE/SET_PHARMACIES_FILTER';
export const RESET_PHARMACIES_FILTER = '@ROLE/RESET_PHARMACIES_FILTER';

export const setPharmaciesFilter = (filter) => {
  return (dispatch) => {
    dispatch({ type: SET_PHARMACIES_FILTER, payload: { filter } });
  };
};

export const resetPharmaciesFilter = () => {
  return (dispatch) => {
    dispatch({ type: RESET_PHARMACIES_FILTER });
  };
};
