import { omitBy, isNil, isEmpty } from 'lodash';

import { HttpService } from 'app/services';
import * as AppActions from 'app/store/actions';
import { parseError } from 'helpers/Error';

export const VPB_CHANGE_FILTER = '@VPB/CHANGE_FILTER';
export const VPB_CHANGE_PRINT_OPTIONS = '@VPB/CHANGE_PRINT_OPTIONS';
export const VPB_RESET_DATA = '@VPB/RESET_DATA';
export const VPB_RESET_DATA_PRINT = '@VPB/VPB_RESET_DATA_PRINT';
export const VPB_IMPORT = '@VPB/VPB_IMPORT';
export const VPB_IMPORT_SUCCESS = '@VPB/VPB_IMPORT_SUCCESS';
export const VPB_IMPORT_FAIL = '@VPB/VPB_IMPORT_FAIL';
export const VPB_EXPORT = '@VPB/VPB_EXPORT';
export const VPB_EXPORT_SUCCESS = '@VPB/VPB_EXPORT_SUCCESS';
export const VPB_EXPORT_FAIL = '@VPB/VPB_EXPORT_FAIL';
export const VPB_PRINT_FORECASE_REPORT = '@VPB/PRINT_FORECASE_REPORT';
export const VPB_PRINT_FORECASE_REPORT_SUCCESS = '@VPB/VPB_PRINT_FORECASE_REPORT_SUCCESS';
export const VPB_PRINT_FORECASE_REPORT_FAIL = '@VPB/VPB_PRINT_FORECASE_REPORT_FAIL';
export const VPB_PRINT_REPORT = '@VPB/PRINT_REPORT';
export const VPB_PRINT_REPORT_SUCCESS = '@VPB/VPB_PRINT_REPORT_SUCCESS';
export const VPB_PRINT_REPORT_FAIL = '@VPB/VPB_PRINT_REPORT_FAIL';
export const VPB_CLEAR_HISTORY_FILE = '@VPB/VPB_CLEAR_HISTORY_FILE';
export const GET_FAVORITES = '@VPB/GET_FAVORITES';
export const GET_FAVORITES_SUCCESS = '@VPB/GET_FAVORITES_SUCCESS';
export const UPDATE_FAVORITE = '@VPB/UPDATE_FAVORITE';
export const UPDATE_FAVORITE_SUCCESS = '@VPB/UPDATE_FAVORITE_SUCCESS';
export const GET_DISPLAY_INSUFFICENT_BALANCE = '@VPB/GET_DISPLAY_INSUFFICENT_BALANCE';
export const UPDATE_DISPLAY_INSUFFICENT_BALANCE = '@VPB/UPDATE_DISPLAY_INSUFFICENT_BALANCE';
export const UPDATE_DISPLAY_INSUFFICENT_BALANCE_SUCCESS = '@VPB/UPDATE_DISPLAY_INSUFFICENT_BALANCE_SUCCESS';

export function getFavorites(type) {
  return (dispatch) => {
    dispatch({ type: GET_FAVORITES });
    HttpService.get('api/favoriteItem', type)
      .then((resp) => {
        dispatch({ type: GET_FAVORITES_SUCCESS, payload: resp.data });
      })
      .catch((err) => {
        const { errorMessages, errorTitle } = parseError(err);
        dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
      });
  };
}

export function updateFavorite(favoriteId, isFavorite) {
  return (dispatch) => {
    dispatch({ type: UPDATE_FAVORITE });
    return HttpService.put(`api/favoriteItem/${favoriteId}`, { isFavorite })
      .then(() => {
        dispatch({ type: UPDATE_FAVORITE_SUCCESS });
      })
      .catch((err) => {
        const { errorMessages, errorTitle } = parseError(err);
        dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
      });
  };
}

export const changeFilter = (filter) => {
  return (dispatch) => {
    dispatch({ type: VPB_CHANGE_FILTER, payload: filter });
  };
};

export const changePrintOptions = (options) => {
  return (dispatch) => {
    dispatch({ type: VPB_CHANGE_PRINT_OPTIONS, payload: options });
  };
};

export const resetData = () => {
  return (dispatch) => {
    dispatch({ type: VPB_RESET_DATA });
  };
};

export const resetDataPrint = () => {
  return (dispatch) => {
    dispatch({ type: VPB_RESET_DATA_PRINT });
  };
};

export const importVPB = (file) => {
  return (dispatch) => {
    dispatch({ type: VPB_IMPORT });
    const formData = new FormData();
    formData.append('file', file);
    HttpService.post('/api/vpbbalances/import', formData)
      .then(() => {
        dispatch({ type: VPB_IMPORT_SUCCESS });
        dispatch(
          AppActions.showMessage({
            message: 'vpb:IMPORT_CSV_MESSAGE_SUCCESS',
            autoHideDuration: 4000,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'success',
          }),
        );
      })
      .catch((err) => {
        const { errorMessages, errorTitle } = parseError(err);
        dispatch({ type: VPB_IMPORT_FAIL });
        dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
      });
  };
};

export const exportVPB = (queryParams) => {
  return (dispatch) => {
    HttpService.abort('GET:/api/vpbbalances/export');
    dispatch({ type: VPB_EXPORT });
    return HttpService.downloadFile(`/api/vpbbalances/export?${queryParams}`, 'get')
      .then((resp) => {
        const url = window.URL.createObjectURL(new Blob([resp.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', resp.fileName || 'VPBBalances.csv');
        document.body.appendChild(link);
        link.click();
        dispatch({ type: VPB_EXPORT_SUCCESS });
      })
      .catch((err) => {
        const { errorFields, errorMessages, errorTitle } = parseError(err);
        dispatch({
          type: VPB_EXPORT_FAIL,
          payload: { message: errorMessages.join('\n'), error: errorFields },
        });
        dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
      });
  };
};

export const printForecastReport = () => {
  return (dispatch, getState) => {
    dispatch({ type: VPB_PRINT_FORECASE_REPORT });
    const { filter, printOptions } = getState()?.vpb?.list;
    const params = omitBy({ ...filter, ...printOptions }, (f) => isNil(f) || (Array.isArray(f) && isEmpty(f)));
    return HttpService.post('/api/vpbbalances/forecast', params)
      .then((resp) => {
        dispatch({ type: VPB_PRINT_FORECASE_REPORT_SUCCESS, payload: { ...resp?.data } });
      })
      .catch((err) => {
        const { errorMessages, errorTitle } = parseError(err);
        dispatch({ type: VPB_PRINT_FORECASE_REPORT_FAIL });
        dispatch(AppActions.createDialogMessage('error', 'Warning', errorMessages));
      });
  };
};

export const printPatientReport = (data) => {
  return (dispatch) => {
    dispatch({ type: VPB_PRINT_REPORT });
    return HttpService.post('/api/vpbbalances/report-patient/export', data)
      .then((resp) => {
        dispatch({ type: VPB_PRINT_REPORT_SUCCESS, payload: { ...resp?.data } });
      })
      .catch((err) => {
        const { errorMessages, errorTitle } = parseError(err);
        dispatch({ type: VPB_PRINT_REPORT_FAIL });
        dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
      });
  };
};

export const printMedicationReport = (data) => {
  return (dispatch) => {
    dispatch({ type: VPB_PRINT_REPORT });
    return HttpService.post('/api/vpbbalances/report-medication/export', data)
      .then((resp) => {
        dispatch({ type: VPB_PRINT_REPORT_SUCCESS, payload: { ...resp?.data } });
      })
      .catch((err) => {
        const { errorMessages, errorTitle } = parseError(err);
        dispatch({ type: VPB_PRINT_REPORT_FAIL });
        dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
      });
  };
};

export const clearHistoryFile = () => {
  return (dispatch) => {
    dispatch({ type: VPB_CLEAR_HISTORY_FILE });
  };
};

export const updateDisplayInsufficientBalance = (pharmacyId, isDisplayInsufficentBalance) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_DISPLAY_INSUFFICENT_BALANCE });
    HttpService.put(`api/pharmacies/${pharmacyId}/display-insufficent-balance`, { isDisplayInsufficentBalance })
      .then((resp) => {
        dispatch(getDisplayInsufficientBalance(pharmacyId));
        dispatch(
          AppActions.showMessage({
            message: 'vpb:UPDATE_INSUFFICIENT_PILL_BALANCE_SUCCESS',
            autoHideDuration: 4000,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'success',
          }),
        );
      })
      .catch((err) => {
        const { errorMessages, errorTitle } = parseError(err);
        dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
      });
  };
};

export const getDisplayInsufficientBalance = (pharmacyId) => {
  return (dispatch) => {
    return HttpService.get(`api/pharmacies/${pharmacyId}/display-insufficent-balance`)
      .then((resp) => {
        dispatch({ type: UPDATE_DISPLAY_INSUFFICENT_BALANCE_SUCCESS, payload: resp.data });
      })
      .catch((err) => {
        const { errorMessages, errorTitle } = parseError(err);
        dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
      });
  };
};
