import { combineReducers } from 'redux';
import list from './list.reducers';
import history from './history.reducers';

const vpbReducers = combineReducers({
  list,
  history,
});

export default vpbReducers;
