// http://mcg.mbitson.com/
const palette = {
  50: '#e9e6ef',
  100: '#c7c0d7',
  200: '#a296bc',
  300: '#7d6ba1',
  400: '#614c8c',
  500: '#452c78',
  600: '#3e2770',
  700: '#362165',
  800: '#2e1b5b',
  900: '#1f1048',
  A100: '#9e82ff',
  A200: '#774fff',
  A400: '#4f1cff',
  A700: '#3b03ff',
  contrastText: '#ffffff',
};

export default palette;
