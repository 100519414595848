import * as PrintProfileActions from '../actions/list.actions';

const defaultFilter = {
  textSearch: '',
};

const initialState = {
  error: null,
  message: 'LIST_EMPTY_MESSAGE',
  filter: defaultFilter,
  submitting: false,
};

const list = (state = initialState, action) => {
  switch (action.type) {
    case PrintProfileActions.SET_PRINT_PROFILE_FILTER: {
      return { ...state, filter: action.payload.filter };
    }
    case PrintProfileActions.REMOVE_PRINT_PROFILE:
      return { ...state, submitting: true };
    case PrintProfileActions.REMOVE_PRINT_PROFILE_SUCCESS:
      return { ...state, submitting: false };
    case PrintProfileActions.REMOVE_PRINT_PROFILE_FAIL:
      return { ...state, submitting: false, error: action.payload };
    default: {
      return state;
    }
  }
};

export default list;
