import React from 'react';
import { authRoles, PERMISSION } from 'app/auth';
import i18next from 'i18next';
import { MENU_ENUM } from 'app/main/role/constants';
import en from '../i18n/en';

i18next.addResourceBundle('en', 'patientGroup', en);

const PatientGroupListConfig = {
  settings: {
    layout: {
      config: {},
    },
    nav: {
      title: 'Patient Groups',
    },
  },
  auth: authRoles.user,
  key: MENU_ENUM.PATIENT_GROUP,
  permission: PERMISSION.READ,
  routes: [
    {
      path: '/patient-group/list',
      component: React.lazy(() => import('./PatientGroupList')),
    },
  ],
};

export default PatientGroupListConfig;
