import { HttpService } from 'app/services';
import * as AppActions from 'app/store/actions';
import { parseError } from '../../../../../helpers/Error';

export const UPDATE_CONSIDERATIONS = '@PATIENT/UPDATE_CONSIDERATIONS';
export const UPDATE_CONSIDERATIONS_SUCCESS = '@PATIENT/UPDATE_CONSIDERATIONS_SUCCESS';
export const UPDATE_CONSIDERATIONS_FAIL = '@PATIENT/UPDATE_CONSIDERATIONS_FAIL';

export const updateConsiderations = ({ id, data }) => {
  delete data.haveOther;
  data.other = data.other.trim();
  return (dispatch) => {
    dispatch({ type: UPDATE_CONSIDERATIONS });
    HttpService.put(`/api/patients/${id}/consideration`, data)
      .then((resp) => {
        dispatch({ type: UPDATE_CONSIDERATIONS_SUCCESS, payload: { data } });
        dispatch(AppActions.showMessage({ message: 'Considerations have been updated successfully' }));
      })
      .catch((err) => {
        const { errorMessages } = parseError(err);
        dispatch({ type: UPDATE_CONSIDERATIONS_FAIL, payload: { message: errorMessages.join('\n') } });
        dispatch(AppActions.createDialogMessage('error', 'Error', errorMessages));
      });
  };
};
