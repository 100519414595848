export const LAST_ACTION = {
  MANUAL_ADJUST: 'MANUAL_ADJUST',
  REVERT_HISTORY: 'REVERT_HISTORY',
};

export const TRANS_TYPE = {
  ADJ: '0',
  DIS: '1',
  PAK: '2',
  SWAP: '3',
  MER: '4',
  REV: '5',
};

export const TRANS_TYPE_DATA = {
  [TRANS_TYPE.ADJ]: 'ADJ',
  [TRANS_TYPE.DIS]: 'DIS',
  [TRANS_TYPE.PAK]: 'PAK',
  [TRANS_TYPE.SWAP]: 'SWAP',
  [TRANS_TYPE.MER]: 'MER',
  [TRANS_TYPE.REV]: 'REV',
};

export const ADJUST_TYPE = {
  ADD: '0',
  SUBTRACT: '1',
  OVERRIDE: '2',
};

export const ADDJUST_TYPE_DATA = [
  { value: ADJUST_TYPE.ADD, label: 'Add' },
  { value: ADJUST_TYPE.SUBTRACT, label: 'Subtract' },
  { value: ADJUST_TYPE.OVERRIDE, label: 'Override' },
];

export const FORECAST_REPORT_OPTIONS = [
  { label: '1 week', value: '1' },
  { label: '2 weeks', value: '2' },
  { label: '3 weeks', value: '3' },
  { label: '4 weeks', value: '4' },
  { label: '6 weeks', value: '6' },
  { label: '8 weeks', value: '8' },
];

export const MAP_SORT_FILED = {
  firstName: 'PatientFirstName',
  lastName: 'PatientLastName',
  medication: 'MedicationName',
  lastDispenseBalanceUpdated: 'LastDispenseDate',
};

export const PACKING_STATUS_TYPES = {
  ACTIVE: '0',
  SUSPENDED: '1',
  INACTIVE: '2',
  NEWLY_IMPORTED: '3',
};

export const PACKING_STATUS = [
  {
    label: 'Active',
    value: PACKING_STATUS_TYPES.ACTIVE,
  },
  {
    label: 'Suspended',
    value: PACKING_STATUS_TYPES.SUSPENDED,
  },
  {
    label: 'Inactive',
    value: PACKING_STATUS_TYPES.INACTIVE,
  },
];
