const locale = {
  DETAIL_TITLE: 'Profile Detail',
  DETAIL_FULL_NAME: 'Full Name',
  DETAIL_PHARMACY_NAME: 'Pharmacy Name',
  DETAIL_USER_NAME: 'User Name',
  DETAIL_EMAIL: 'Email',
  DETAIL_DOB: 'DOB',
  DETAIL_ROLE: 'Roles',
  DETAIL_GENDER: 'Gender',
};

export default locale;
