import * as RoleAction from '../actions/list.actions';

const defaultFilter = {
  textSearch: '',
};

const initialState = {
  error: null,
  message: 'LIST_EMPTY_MESSAGE',
  filter: defaultFilter,
  submitting: false,
};

const list = (state = initialState, action) => {
  switch (action.type) {
    case RoleAction.SET_ROLES_FILTER: {
      return { ...state, filter: action.payload.filter };
    }
    case RoleAction.RESET_ROLES_FILTER: {
      return { ...state, filter: defaultFilter };
    }
    case RoleAction.REMOVE_ROLE: {
      return { ...state, submitting: true };
    }
    case RoleAction.REMOVE_ROLE_SUCCESS: {
      return { ...state, submitting: false };
    }
    case RoleAction.REMOVE_ROLE_FAIL: {
      return { ...state, submitting: false, error: action.payload.error, message: action.payload.message };
    }
    case RoleAction.CLONE_ROLE:
      return { ...state, submitting: true };
    case RoleAction.CLONE_ROLE_SUCCESS:
      return { ...state, submitting: false };
    case RoleAction.CLONE_ROLE_FAIL:
      return { ...state, submitting: false, error: action.payload.error, message: action.payload.message };
    default: {
      return state;
    }
  }
};

export default list;
