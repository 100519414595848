import shortid from 'shortid';
import { HttpService } from 'app/services';
import { parseError } from 'helpers/Error';
import * as AppActions from 'app/store/actions';

import { ROLE_LEVEL_ENUM } from '../../constants';

export const GET_PERMISSION_DETAIL = '@DASHBOARD/GET_PERMISSION_DETAIL';
export const GET_PERMISSION_DETAIL_SUCCESS = '@DASHBOARD/GET_PERMISSION_DETAIL_SUCCESS';
export const GET_PERMISSION_DETAIL_FAIL = '@DASHBOARD/GET_PERMISSION_DETAIL_FAIL';
export const UPDATE_PERMISSION_DETAIL = '@DASHBOARD/UPDATE_PERMISSION_DETAIL';
export const UPDATE_PERMISSION_DETAIL_SUCCESS = '@DASHBOARD/UPDATE_PERMISSION_DETAIL_SUCCESS';
export const UPDATE_PERMISSION_DETAIL_FAIL = '@DASHBOARD/UPDATE_PERMISSION_DETAIL_FAIL';

export const RESET = '@DASHBOARD/RESET_PERMISSION_DATA';

export const getPermission = (id) => {
  return (dispatch) => {
    dispatch({ type: GET_PERMISSION_DETAIL });
    HttpService.get(`/api/dashboardpermissions/${id}`)
      .then((resp) => {
        dispatch({
          type: GET_PERMISSION_DETAIL_SUCCESS,
          payload: {
            ...resp.data,
            charts: resp.data.charts.map((chart) => ({ ...chart, _key: shortid() })),
            roleLevel: ROLE_LEVEL_ENUM[resp.data.roleLevel],
          },
        });
      })
      .catch((err) => {
        const { errorMessages, errorTitle } = parseError(err);
        dispatch({ type: GET_PERMISSION_DETAIL_FAIL, payload: { message: errorMessages } });
        dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
      });
  };
};

export const updatePermission = (id, data) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_PERMISSION_DETAIL });
    HttpService.put(`/api/dashboardpermissions/${id}`, data)
      .then(() => {
        dispatch(
          AppActions.showMessage({
            message: 'dashboard:UPDATE_DASHBOARD_PERMISSION_SUCCESS',
          }),
        );
        dispatch({
          type: UPDATE_PERMISSION_DETAIL_SUCCESS,
          payload: {
            ...data,
            charts: data.charts.map((chart) => ({ ...chart, _key: shortid() })),
            roleLevel: ROLE_LEVEL_ENUM[data.roleLevel],
          },
        });
      })
      .catch((err) => {
        const { errorMessages, errorTitle } = parseError(err);
        dispatch({ type: UPDATE_PERMISSION_DETAIL_FAIL, payload: { message: errorMessages } });
        dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
      });
  };
};

export const resetData = () => {
  return (dispatch) => {
    dispatch({ type: RESET });
  };
};
