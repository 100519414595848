export const PATIENT_GROUP_TYPE_ENUM = {
  COMMUNITY: 0,
  NURSING_HOME: 1,
};

export const PATIENT_GROUP_TYPE = [
  {
    label: 'Community',
    value: PATIENT_GROUP_TYPE_ENUM.COMMUNITY,
  },
  {
    label: 'Nursing home',
    value: PATIENT_GROUP_TYPE_ENUM.NURSING_HOME,
  },
];

export const MANIFEST = [
  {
    label: 'Pickup',
    value: 0,
  },
  {
    label: 'Delivery',
    value: 1,
  },
];

export const BLISTER_DAY = [
  {
    label: 'Monday',
    value: '0',
  },
  {
    label: 'Tuesday',
    value: '1',
  },
  {
    label: 'Wednesday',
    value: '2',
  },
  {
    label: 'Thursday',
    value: '3',
  },
  {
    label: 'Friday',
    value: '4',
  },
  {
    label: 'Saturday',
    value: '5',
  },
  {
    label: 'Sunday',
    value: '6',
  },
];

export const BLISTER_OR_SACHET_ENUM = {
  BLISTER: '0',
  SACHET: '1',
  BOTH: '2',
};

export const BLISTER_OR_SACHET = [
  {
    label: 'Blister',
    value: BLISTER_OR_SACHET_ENUM.BLISTER,
  },
  {
    label: 'Sachet',
    value: BLISTER_OR_SACHET_ENUM.SACHET,
  },
  {
    label: 'Both',
    value: BLISTER_OR_SACHET_ENUM.BOTH,
  },
];

export const PATIENT_BY_ROOM_NUMBER = [
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
];

export const DRUG_NAME_AS_GENERIC = [
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
];

export const CUSTOM_OPTION_VALUE = '$CUSTOM_OPTION$';

export const DEFAULT_TIME_SLOT = {
  BFAST: "B'fast",
  LUNCH: 'Lunch',
  DINNER: 'Dinner',
  BTIME: "B'time",
};

export const DEFAULT_TIME_SLOT_DATA = [
  DEFAULT_TIME_SLOT.BFAST,
  DEFAULT_TIME_SLOT.LUNCH,
  DEFAULT_TIME_SLOT.DINNER,
  DEFAULT_TIME_SLOT.BTIME,
];

export const DEFAULT_SHORT_COURSE_SACHET_DATA = [
  '08:00',
  '10:00',
  '12:00',
  '14:00',
  '16:00',
  '18:00',
  '20:00',
  '22:00',
];

export const PACK_TYPE_ENUM = {
  SOLID: '0',
  PERF: '1',
  TRIFOLD: '2',
};

export const PACK_OPTIONS = [
  { label: 'myPak Solid', value: '0' },
  { label: 'myPak Perf', value: '1' },
  { label: 'myPak Trifold', value: '2' },
];

export const DISPLAY_QUALITY = {
  None: '-1',
  PiecesPerTimeslot: '0',
  TabletPetTimeslot: '1',
};

export const DRUG_PRINT_FORMAT = {
  None: '-1',
  TradeStrengthGeneric: '0',
  GenericStrengthTrade: '1',
};

export const AUTOMATION_OPTIONS = [
  { label: 'Enable', value: 'true' },
  { label: 'Disable', value: 'false' },
];

export const GUIDED_FILL_PACKING = {
  None: '-1',
  PortraitPackingDisplayGrid: '0',
  LandscapePackingDisplayGrid: '1',
};

export const DEFAULT_PATIENT_GROUP_PACK_TYPE_ENUM = {
  SOLID: 1,
  PERF: 2,
  TRIFOLD: 3,
  MEDICO_PAK: 4,
};

export const PATIENT_GROUP_PACK_TYPE = [
  {
    label: 'myPak Solid',
    value: DEFAULT_PATIENT_GROUP_PACK_TYPE_ENUM.SOLID,
  },
  {
    label: 'myPak Perforated',
    value: DEFAULT_PATIENT_GROUP_PACK_TYPE_ENUM.PERF,
  },
  {
    label: 'myPak Trifold',
    value: DEFAULT_PATIENT_GROUP_PACK_TYPE_ENUM.TRIFOLD,
  },
  {
    label: 'Medico Pak',
    value: DEFAULT_PATIENT_GROUP_PACK_TYPE_ENUM.MEDICO_PAK,
  },
];

export const WARNING_WARNANME_ALREADY = 'Ward name already exist. Update not permitted';
