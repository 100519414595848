import * as PrinterSettingAction from '../actions/printersetting.actions';

const initialState = {
  error: null,
  submitting: false,
  selectedPrinterSetting: {
    id: 0,
    clientCode: null,
    foilPackingType: null,
    defaultPrinter: '',
    blisterType: '',
  },
};

const printerSetting = (state = initialState, action) => {
  switch (action.type) {
    case PrinterSettingAction.REMOVE_PRINTER_SETTING: {
      return { ...state, submitting: true };
    }
    case PrinterSettingAction.REMOVE_PRINTER_SETTING_SUCCESS: {
      return { ...state, submitting: false };
    }
    case PrinterSettingAction.REMOVE_PRINTER_SETTING_FAIL: {
      return { ...state, submitting: false, error: action.payload.error };
    }
    case PrinterSettingAction.SET_SELECTED_PRINTER_SETTING: {
      return { ...state, selectedPrinterSetting: action.payload.printerSetting };
    }
    default: {
      return state;
    }
  }
};

export default printerSetting;
