import React from 'react';
import { authRoles } from 'app/auth';
import i18next from 'i18next';
import UnAuthorized from './UnAuthorized';
import en from './i18n/en';

i18next.addResourceBundle('en', 'unAuthorized', en);

const UnAuthorizedConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.onlyGuest,
  routes: [
    {
      path: '/un-authorized',
      component: UnAuthorized,
    },
  ],
};

export default UnAuthorizedConfig;
