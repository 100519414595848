import React from 'react';
import { authRoles, PERMISSION } from 'app/auth';
import i18next from 'i18next';
import { MENU_ENUM } from 'app/main/role/constants';
import en from '../i18n/en';

i18next.addResourceBundle('en', 'dispenseTracker', en);

const DispenseTrackerListConfig = {
  settings: {
    layout: {
      config: {},
    },
    nav: {
      title: 'Dispense Tracker',
    },
  },
  auth: authRoles.user,
  key: MENU_ENUM.DISPENSE_TRACKER,
  permission: PERMISSION.READ,
  routes: [
    {
      path: '/dispense-tracker/list',
      component: React.lazy(() => import('./DispenseTrackerList')),
    },
  ],
};

export default DispenseTrackerListConfig;
