import { HttpService } from 'app/services';
import * as AppActions from 'app/store/actions';
import { parseError } from 'helpers/Error';

export const PRINT_REPORT = '@SIGNING_SHEETS/CREATE_PATIENT_GROUP';
export const PRINT_REPORT_SUCCESS = '@SIGNING_SHEETS/PRINT_REPORT_SUCCESS';
export const PRINT_REPORT_FAIL = '@SIGNING_SHEETS/PRINT_REPORT_FAIL';

export const CHANGE_PRINT_REPORT_FORM = '@SIGNING_SHEETS/CHANGE_PRINT_REPORT_FORM';

export const RESET_PRINT_REPORT_FORM = '@SIGNING_SHEETS/RESET_PRINT_REPORT_FORM';

export const SET_QUICK_PRINT_REPORT = '@SIGNING_SHEETS/SET_QUICK_PRINT_REPORT';

export const CLEAR_HISTORY_FILE = '@SIGNING_SHEETS/CLEAR_HISTORY_FILE';

export const GET_HISTORY_OPTIONS = '@GET_HISTORY_OPTIONS';

export function printReport() {
  return (dispatch, getState) => {
    const { data } = getState().signingSheets.print;
    const postData = { ...data };
    delete postData.reportSettingId;
    delete postData.wards;
    delete postData.reportCode;
    dispatch({ type: PRINT_REPORT });
    HttpService.post(`api/reports/export/${data.reportSettingId}`, postData)
      .then((resp) => {
        dispatch({ type: PRINT_REPORT_SUCCESS, payload: { data: resp.data } });
      })
      .catch((err) => {
        const { errorFields, errorMessages } = parseError(err);
        dispatch({
          type: PRINT_REPORT_FAIL,
          payload: { message: errorMessages, error: errorFields },
        });
        const reportType =
          data.reportCode === 'crWarfarinSigningSheet'
            ? 'Warfarin'
            : data.reportCode === 'crPRNSigningSheet'
            ? 'PRN'
            : '';
        dispatch(
          AppActions.createDialogMessage(
            'error',
            'Warning',
            `No data to print - No ${reportType} drugs have been assigned.`,
          ),
        );
      });
  };
}

export function changePrintReportForm(data) {
  return (dispatch) => {
    dispatch({ type: CHANGE_PRINT_REPORT_FORM, payload: { data } });
  };
}

export function resetPrintReportForm() {
  return (dispatch) => {
    dispatch({ type: RESET_PRINT_REPORT_FORM });
  };
}

export function setQuickPrintReport(data) {
  return (dispatch) => {
    dispatch({ type: SET_QUICK_PRINT_REPORT, payload: { data } });
  };
}

export function clearHistoryFile() {
  return (dispatch) => {
    dispatch({ type: CLEAR_HISTORY_FILE });
  };
}

export function getHistoryOptions(pharmacyId) {
  return (dispatch) => {
    HttpService.get(`api/pharmacies/${pharmacyId}/signing-sheet-option`).then((resp) => {
      dispatch({ type: GET_HISTORY_OPTIONS, payload: { data: resp.data } });
    });
  };
}
