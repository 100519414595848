import * as PatientGroupActions from '../actions/create.actions';
import { DEFAULT_TIME_SLOT_DATA, DEFAULT_SHORT_COURSE_SACHET_DATA } from '../../constants';

const initialState = {
  id: '',
  data: {
    code: '',
    name: '',
    dispenseCode: '',
    racfid: '',
    patientGroupType: 0,
    street: '',
    email: '',
    suburb: '',
    state: '',
    postalCode: '',
    fax: '',
    logoId: '',
    beds: 0,
    phone: '',
    manifest: 0,
    isListPatientByRoom: 'false',
    isUseGenericAsDrugName: 'false',
    isAutomation: 'false',
    defaultTimeslot: DEFAULT_TIME_SLOT_DATA,
    sachetTimeslot: DEFAULT_SHORT_COURSE_SACHET_DATA,
    shortCourseTimeslot: [...Array(8)].fill(''),
    activePatients: 0,
    defaulPackType: 1,
  },
  submitting: false,
  error: null,
  message: '',
};

const create = (state = initialState, action) => {
  switch (action.type) {
    case PatientGroupActions.CREATE_PATIENT_GROUP:
      return {
        ...state,
        submitting: true,
        error: null,
        message: '',
      };
    case PatientGroupActions.CREATE_PATIENT_GROUP_SUCCESS:
      return {
        ...state,
        submitting: false,
        id: action.payload.patientGroupId,
      };
    case PatientGroupActions.CREATE_PATIENT_GROUP_FAIL:
      return {
        ...state,
        submitting: false,
        error: action.payload.error,
        message: action.payload.message,
      };
    case PatientGroupActions.CHANGE_PATIENT_GROUP_CREATE_FORM:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload.data,
        },
      };
    case PatientGroupActions.CLEAR_CREATE_FORM:
      return initialState;
    default: {
      return state;
    }
  }
};

export default create;
