export const TAB = {
  MAPPED: 'mapped', // Mapped
  UNMAPPED: 'unmapped',
  IGNORED: 'ignored',
};

export const TAB_DATA = [
  {
    label: 'Mapped',
    value: TAB.MAPPED,
  },
  {
    label: 'Unmapped',
    value: TAB.UNMAPPED,
  },
  {
    label: 'Ignored',
    value: TAB.IGNORED,
  },
];

export const PACK_TYPE = {
  NONE: '-1',
  PACKED: '0',
  UN_PACKED: '1',
  PRN: '2',
  UN_PACKED_PRN: '3',
};

export const DISPENSE_STATUS = {
  NONE: '-1',
  ACTIVE: '0',
  CANCELED: '1',
  SCRIPT_OWING: '2',
  SCRIPT_DEFERRED: '3',
  ON_HOLD: '4',
};

export const DISPENSE_STATUS_LABEL = {
  [DISPENSE_STATUS.NONE]: 'None',
  [DISPENSE_STATUS.ACTIVE]: 'Active',
  [DISPENSE_STATUS.CANCELED]: 'Cancelled',
  [DISPENSE_STATUS.SCRIPT_OWING]: 'Script Owing',
  [DISPENSE_STATUS.SCRIPT_DEFERRED]: 'Script Deferred',
  [DISPENSE_STATUS.ON_HOLD]: 'On Hold',
};

export const SCRIPT_STATUS = {
  NONE: '-1',
  NEW: '0',
  CANCELLED: '1',
  QUANTITY_CHANGED: '2',
  CEASED: '3',
  ACTIVE: '4',
  NA: '5',
};

export const SCRIPT_STATUS_LABEL = {
  [SCRIPT_STATUS.NONE]: 'None',
  [SCRIPT_STATUS.NEW]: 'Add',
  [SCRIPT_STATUS.CANCELLED]: 'Subtract',
  [SCRIPT_STATUS.QUANTITY_CHANGED]: 'Qty Change',
  [SCRIPT_STATUS.CEASED]: 'Hidden',
  [SCRIPT_STATUS.ACTIVE]: 'Imported',
  [SCRIPT_STATUS.NA]: 'Ignored',
};

export const SCRIPT_TYPE = {
  NONE: -1,
  MAPPED: 0,
  UNMAPPED: 1,
  IGNORED: 2,
};

export const PRESCRIPTION_STATE_ENUM = {
  CEASED: 2,
};

export const OPTIONS_SCRIPT_TYPE = [
  { label: 'New', value: 0 },
  { label: 'PRN', value: 1 },
  { label: 'Quantity Changed', value: 2 },
  { label: 'Owing', value: 3 },
  { label: 'Cancelled', value: 4 },
];
