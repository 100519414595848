import React from 'react';
import QuickPanel from 'app/fuse-layouts/shared-components/quickPanel/QuickPanel';

function RightSideLayout1(props) {
  return (
    <>
      <QuickPanel />
    </>
  );
}

export default RightSideLayout1;
