import * as Actions from '../../actions/fuse';

const initialState = {
  state: null,
  options: {
    autoHideDuration: 5000,
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'right',
    },
    variant: 'success',
    message: '',
  },
};

const message = (state = initialState, action) => {
  switch (action.type) {
    case Actions.SHOW_MESSAGE: {
      return {
        state: true,
        options: {
          ...initialState.options,
          ...action.options,
        },
      };
    }
    case Actions.HIDE_MESSAGE: {
      return {
        ...state,
        state: null,
      };
    }
    default: {
      return state;
    }
  }
};

export default message;
