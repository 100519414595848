import { combineReducers } from 'redux';
import list from './list.reducer';
import create from './create.reducer';
import update from './update.reducer';

const userReducers = combineReducers({
  list,
  create,
  update,
});

export default userReducers;
