import * as DirectionActions from '../actions';

const defaultFilter = {
  textSearch: '',
};

const initialState = {
  data: [],
  loading: false,
  message: '',
  filter: defaultFilter,
};

const list = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case DirectionActions.GET_DIRECTIONS:
      return { ...state, loading: true };
    case DirectionActions.GET_DIRECTIONS_SUCCESS:
      return { ...state, loading: false, data: payload.data };
    case DirectionActions.GET_DIRECTIONS_FAIL:
      return { ...state, loading: false, message: payload.message };
    case DirectionActions.REMOVE_DIRECTION:
      return { ...state, loading: true };
    case DirectionActions.REMOVE_DIRECTION_SUCCESS:
      return { ...state, loading: false };
    case DirectionActions.REMOVE_DIRECTION_FAIL:
      return { ...state, loading: false, error: action.payload };
    case DirectionActions.SET_DIRECTION_FILTER:
      return { ...state, filter: action.payload.filter };
    case DirectionActions.RESET_DIRECTION_FILTER:
      return { ...state, filter: defaultFilter };
    default:
      return state;
  }
};

export default list;
