export const PRINT_OPTIONS = {
  INCLUDE_PRN: 'INCLUDE_PRN',
  INCLUDE_NON_PACKED: 'INCLUDE_NON_PACKED',
  INCLUDE_SUSPENDED: 'INCLUDE_SUSPENDED',
  PRINT_PER_PATIENT: 'PRINT_PER_PATIENT',
  PRINT_MEDICATION: 'PRINT_MEDICATION',
};
export const PRINT_OPTIONS_DATA = [
  // mapKey: redux key
  { label: 'Include PRN Drugs (both packed/non-packed)', value: PRINT_OPTIONS.INCLUDE_PRN, mapKey: 'includePRN' },
  { label: 'Include Non-Packed Drugs', value: PRINT_OPTIONS.INCLUDE_NON_PACKED, mapKey: 'includeNonPacked' },
  { label: 'Include Suspended Patients', value: PRINT_OPTIONS.INCLUDE_SUSPENDED, mapKey: 'includeSuspended' },
  { label: 'Print separate request per patient', value: PRINT_OPTIONS.PRINT_PER_PATIENT, mapKey: 'isPrintPerPatient' },
  {
    label: 'Print Medication Profile on Script Request',
    value: PRINT_OPTIONS.PRINT_MEDICATION,
    mapKey: 'isIncludeMedicationDetails',
  },
];

export const PRINT_OPTION_STORAGE_KEY = 'printOptionStorage';
