import { HttpService } from 'app/services';
import * as AppActions from 'app/store/actions';
import { parseError } from 'helpers/Error';
import { TAB } from '../../constants';

export const SUBMIT = '@DISPENSE_TRACKER/SUBMIT';
export const SUBMIT_SUCCESS = '@DISPENSE_TRACKER/SUBMIT_SUCCESS';
export const SUBMIT_FAIL = '@DISPENSE_TRACKER/SUBMIT_FAIL';

export const CHANGE_FILTER = '@DISPENSE_TRACKER/CHANGE_FILTER';
export const CLEAR_FILTER = '@DISPENSE_TRACKER/CLEAR_FILTER';

export const CHANGE_TAB = '@DISPENSE_TRACKER/CHANGE_TAB';

export const UPDATE_QUANTITY = '@DISPENSE_TRACKER/UPDATE_QUANTITY';

export const CLEAR_DATA = '@DISPENSE_TRACKER/CLEAR_DATA';

export const CHANGE_RANDOM = '@DISPENSE_TRACKER/CHANGE_RANDOM';

export const HAS_DATA = '@DISPENSE_TRACKER/HAS_DATA';
export const EMPTY_DATA = '@DISPENSE_TRACKER/EMPTY_DATA';

export const PRINT_REPORT_MAPPED = '@DISPENSE_TRACKER/PRINT_REPORT_MAPPED';
export const PRINT_REPORT_MAPPED_SUCCESS = '@DISPENSE_TRACKER/PRINT_REPORT_MAPPED_SUCCESS';
export const PRINT_REPORT_MAPPED_FAILED = '@DISPENSE_TRACKER/PRINT_REPORT_MAPPED_FAILED';
export const CLEAR_FILE_REPORT_MAPPED = '@DISPENSE_TRACKER/CLEAR_FILE_REPORT_MAPPED';

export const markActive = (selectedTracker) => {
  return (dispatch) => {
    dispatch({ type: SUBMIT });
    HttpService.put('/api/scripttrackings/revert-ignore', { scriptTrackingIds: selectedTracker })
      .then(() => {
        dispatch({ type: SUBMIT_SUCCESS });
        dispatch(
          AppActions.showMessage({
            message: 'dispenseTracker:MESSAGE_MARK_ACTIVE',
            autoHideDuration: 4000,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'success',
          }),
        );
        dispatch(AppActions.dataLoader_reload('GET:DispenseTracker'));
      })
      .catch((err) => {
        const { errorTitle, errorMessages } = parseError(err);
        dispatch({ type: SUBMIT_FAIL });
        dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
      });
  };
};

export const ignoreScript = (selectedTracker) => {
  return (dispatch) => {
    dispatch({ type: SUBMIT });
    HttpService.put('/api/scripttrackings/ignore', { scriptTrackingIds: selectedTracker })
      .then(() => {
        dispatch({ type: SUBMIT_SUCCESS });
        dispatch(
          AppActions.showMessage({
            message: 'dispenseTracker:MESSAGE_IGNORE_SCRIPT',
            autoHideDuration: 4000,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'success',
          }),
        );
        dispatch(AppActions.dataLoader_reload('GET:DispenseTracker'));
      })
      .catch((err) => {
        const { errorTitle, errorMessages } = parseError(err);
        dispatch({ type: SUBMIT_FAIL });
        dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
      });
  };
};

export const printMapped = (ids) => {
  return (dispatch) => {
    dispatch({ type: PRINT_REPORT_MAPPED });
    HttpService.post('/api/scripttrackings/pickingreport', {
      scriptTrackingIds: ids,
      dateTimeImport: new Date(),
    })
      .then((resp) => {
        dispatch({
          type: PRINT_REPORT_MAPPED_SUCCESS,
          payload: { fileName: resp.data.fileName, fileUrl: resp.data.fileUrl },
        });
      })
      .catch((err) => {
        const { errorTitle, errorMessages } = parseError(err);
        dispatch({ type: PRINT_REPORT_MAPPED_FAILED });
        dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
      });
  };
};

export const importScript = (postData) => {
  return (dispatch, getState) => {
    const { activeTab, filter } = getState().dispenseTracker.list;
    let url = null;

    switch (activeTab) {
      case TAB.MAPPED:
        url = '/api/scripttrackings/mapped/import';
        break;
      case TAB.UNMAPPED:
        url = '/api/scripttrackings/unmapped/import';
        break;
      default:
        break;
    }

    if (!url) return;

    dispatch({ type: SUBMIT });
    HttpService.post(url, {
      scriptTrackings: postData,
    })
      .then(() => {
        dispatch({ type: SUBMIT_SUCCESS });
        dispatch(
          AppActions.showMessage({
            message: 'dispenseTracker:MESSAGE_IMPORT_SCRIPT',
            autoHideDuration: 4000,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            variant: 'success',
          }),
        );
        dispatch(AppActions.dataLoader_reload('GET:DispenseTracker'));
        dispatch(changeFilter({ ...filter, patientGroupId: [], patientId: null }));
        dispatch(changeRandom());
      })
      .then(() => {
        if (activeTab === TAB.MAPPED) {
          const ids = postData?.map((i) => i.scriptTrackingId);
          dispatch(printMapped(ids));
        }
      })
      .catch((err) => {
        const { errorTitle, errorMessages } = parseError(err);
        dispatch({ type: SUBMIT_FAIL });
        dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
      });
  };
};

export function changeFilter(filter) {
  return (dispatch) => {
    dispatch({ type: CHANGE_FILTER, payload: filter });
  };
}

export function clearFilter() {
  return (dispatch) => {
    dispatch({ type: CLEAR_FILTER });
  };
}

export function changeTab(tab) {
  return (dispatch) => {
    dispatch({ type: CHANGE_TAB, payload: tab });
  };
}

export function updateQuantity(scriptTrackingId, quantity) {
  return (dispatch) => {
    HttpService.put(`/api/scripttrackings/${scriptTrackingId}/quantity`, { quantity }).catch((err) => {
      const { errorTitle, errorMessages } = parseError(err);
      dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
    });
  };
}

export function changeRandom() {
  return (dispatch) => {
    dispatch({ type: CHANGE_RANDOM });
  };
}

export function hasData() {
  return (dispatch) => {
    dispatch({ type: HAS_DATA });
  };
}

export function emptyData() {
  return (dispatch) => {
    dispatch({ type: EMPTY_DATA });
  };
}

export function clearData() {
  return (dispatch) => {
    dispatch({ type: CLEAR_DATA });
  };
}

export function clearFileReport() {
  return (dispatch) => {
    dispatch({ type: CLEAR_FILE_REPORT_MAPPED });
  };
}
