import React, { useEffect, useState, useRef, useReducer } from 'react';
import { toNumber } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

import * as AppActions from 'app/store/actions';
import { noSyncDataItem } from 'app/fuse-configs/navigationConfig';
import JobMessage from './message/JobMessage';
import SyncMessage from './message/SyncMessage';
import DataMirationMessage from './message/DataMirationMessage';
import PharmacyMessage from './message/PharmacyMessage';
import AppBarMessage from './message/AppBarMessage';
import Config from '../../app/Config';

export const MESSAGE_TYPE = {
  JOB: 0,
  SYNC: 1,
  DATA_MIRATION: 2,
  PHARMACY: 3,
  APP_BAR: 4,
  START_SYNC: 5,
  END_SYNC: 6,
  StartDispenseSyncManual: 7,
  DispenseSyncManualCompleted: 8,
  UploadPharmacy: 9,
  UploadFacility: 10,
  UploadPatient: 11,
  UploadFoil: 12,
  UploadDoctor: 13,
  UploadPrescription: 14,
  UploadMedchangehistory: 15,
  UploadScript: 16,
  StartDispenseSyncAuto: 17,
  DispenseSyncAutoCompleted: 18,
};

export default function WebSocketMessage() {
  const [message, setMessage] = useState(null);
  // https://stackoverflow.com/questions/60476155/is-it-safe-to-use-ref-current-as-useeffects-dependency-when-ref-points-to-a-dom
  const [reRender, triggerRender] = useReducer((r) => !r, false);
  const userWS = useRef(null);
  const pharmacyWS = useRef(null);
  const { id: userId, currentPharmacyId } = useSelector((state) => state.auth.user.data);
  const dispatch = useDispatch();

  useEffect(() => {
    if (userId) {
      // MC-200686: Reinit connection
      initUserWebsocket(userId);
    }

    return () => {
      if (userWS.current) {
        userWS.current.close();
      }
    };
  }, [userId]);

  useEffect(() => {
    if (currentPharmacyId) {
      initPharmacyWebsocket(currentPharmacyId);
    }

    return () => {
      if (pharmacyWS.current) {
        pharmacyWS.current.close();
      }
    };
  }, [currentPharmacyId]);

  useEffect(() => {
    if (!userId) {
      setMessage(null);
    }
  }, [userId]);

  useEffect(() => {
    if (toNumber(message?.Type) === MESSAGE_TYPE.START_SYNC) {
      dispatch(AppActions.resetNavigation());
    } else if (toNumber(message?.Type) === MESSAGE_TYPE.END_SYNC) {
      dispatch(AppActions.updateNavigationItem('sync', noSyncDataItem, 'navigationBottomConfig'));
    }
  }, [message]);

  function initUserWebsocket(_userId) {
    if (_userId && userWS.current === null) {
      userWS.current = new WebSocket(Config.REACT_APP_WEBSOCKET_SERVER);
      userWS.current.onopen = () => {
        userWS.current.send(JSON.stringify({ action: 'joinroom', roomname: `user.${_userId}` }));
      };

      userWS.current.onmessage = (ev) => {
        if (ev.data) {
          try {
            const data = JSON.parse(ev.data);
            setMessage(data);
          } catch (e) {
            // console.error(e);
          }
        }
      };

      userWS.current.onclose = (event) => {
        userWS.current = null;
        // triggerRender();
        if (event.code === 1001) {
          initUserWebsocket(userId);
        }
      };
    }
  }

  function initPharmacyWebsocket(_pharmacyId) {
    if (_pharmacyId && pharmacyWS.current === null) {
      pharmacyWS.current = new WebSocket(Config.REACT_APP_WEBSOCKET_SERVER);
      pharmacyWS.current.onopen = () => {
        pharmacyWS.current.send(JSON.stringify({ action: 'joinroom', roomname: `pharmacy.${_pharmacyId}` }));
      };

      pharmacyWS.current.onmessage = (ev) => {
        if (ev.data) {
          try {
            const data = JSON.parse(ev.data);
            setMessage(data);
          } catch (e) {
            // console.error(e);
          }
        }
      };

      pharmacyWS.current.onclose = (event) => {
        pharmacyWS.current = null;
        if (event.code === 1001) {
          initPharmacyWebsocket(currentPharmacyId);
        }
      };
    }
  }

  switch (toNumber(message?.Type)) {
    // NG-4898 - Temporarily disable the Job Notification
    // case MESSAGE_TYPE.JOB:
    //   return <JobMessage message={message} />;
    case MESSAGE_TYPE.SYNC:
      return <SyncMessage message={message} />;
    case MESSAGE_TYPE.DATA_MIRATION:
      return <DataMirationMessage message={message} />;
    case MESSAGE_TYPE.PHARMACY:
      return <PharmacyMessage message={message} />;
    case MESSAGE_TYPE.APP_BAR:
      return <AppBarMessage message={message} />;
    default:
      return <span />;
  }
}
