class StoreService {
  setStore(store) {
    this._store = store;
  }

  getStore() {
    return this._store;
  }

  dispatch(...rest) {
    if (this._store) {
      this._store.dispatch(...rest);
    }
  }

  getState() {
    if (this._store) {
      return this._store.getState();
    }

    return null;
  }
}

const instance = new StoreService();

export default instance;
