import * as RoleCreateActions from '../actions/create.actions';
import { MENU } from '../../constants';

const permissions = MENU.map((i) => {
  return {
    ...i,
    read: false,
    create: false,
    update: false,
    delete: false,
  };
});

const initialState = {
  id: '',
  data: {
    name: '',
    permissions,
    isChangeCommentRequired: false,
  },
  submitting: false,
  error: null,
  message: '',
};

const create = (state = initialState, action) => {
  switch (action.type) {
    case RoleCreateActions.CREATE_ROLE: {
      return {
        ...state,
        error: null,
        message: '',
        submitting: true,
      };
    }
    case RoleCreateActions.CREATE_ROLE_SUCCESS: {
      return {
        ...state,
        id: action.payload.roleId,
        submitting: false,
      };
    }
    case RoleCreateActions.CREATE_ROLE_FAIL: {
      return {
        ...state,
        message: action.payload.message,
        error: action.payload.error,
        submitting: false,
      };
    }
    case RoleCreateActions.CHANGE_ROLE_FORM:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload.data,
        },
      };
    case RoleCreateActions.CLEAR_CREATE_FORM:
      return initialState;
    default: {
      return state;
    }
  }
};

export default create;
