import { HttpService } from 'app/services';
import * as AppActions from 'app/store/actions';
import { parseError } from 'helpers/Error';
import { MENU } from '../../constants';

export const GET_ROLE = '@ROLE/GET_ROLE_DETAILS';
export const GET_ROLE_SUCCESS = '@ROLE/GET_ROLE_SUCCESS';
export const GET_ROLE_FAIL = '@ROLE/GET_ROLE_FAIL';

export const UPDATE_ROLE = '@ROLE/UPDATE_ROLE';
export const UPDATE_ROLE_SUCCESS = '@ROLE/UPDATE_ROLE_SUCCESS';
export const UPDATE_ROLE_FAIL = '@ROLE/UPDATE_ROLE_FAIL';

export const CHANGE_ROLE_UPDATE_FORM = '@ROLE/CHANGE_ROLE_UPDATE_FORM';

export const CLEAR_UPDATE_FORM = '@ROLE/CLEAR_UPDATE_FORM';

export function getRoleDetails(roleId, callback) {
  return (dispatch) => {
    dispatch({ type: GET_ROLE });
    HttpService.get(`api/roles/${roleId}`)
      .then((resp) => {
        const commentRequired = {
          key: 'isChangeCommentRequired',
          value: [0, resp.data?.isChangeCommentRequired || false, 0, 0],
        };
        const permission = [...resp.data.permissions, commentRequired];
        const permissionObj = permission.reduce((rs, i) => {
          return {
            ...rs,
            [i.key]: {
              key: i.key,
              create: Boolean(i.value[0]),
              read: Boolean(i.value[1]),
              update: Boolean(i.value[2]),
              delete: Boolean(i.value[3]),
            },
          };
        }, {});

        const permissions = MENU.map((m) => {
          return {
            ...m,
            read: permissionObj[m.key] ? permissionObj[m.key].read : false,
            create: permissionObj[m.key] ? permissionObj[m.key].create : false,
            update: permissionObj[m.key] ? permissionObj[m.key].update : false,
            delete: permissionObj[m.key] ? permissionObj[m.key].delete : false,
          };
        });
        const roleDetail = {
          id: resp.data.id,
          detail: {
            name: resp.data.name,
            permissions,
            isChangeCommentRequired: resp.data?.isChangeCommentRequired || false,
          },
        };
        dispatch({ type: GET_ROLE_SUCCESS, payload: { data: roleDetail } });
      })
      .catch((err) => {
        const { errorFields, errorMessages } = parseError(err);
        dispatch({
          type: GET_ROLE_FAIL,
          payload: { message: errorMessages.join('\n'), error: errorFields },
        });
        dispatch(AppActions.createDialogMessage('error', 'Error', errorMessages, { callback }));
      });
  };
}

export function updateRole() {
  return (dispatch, getState) => {
    const { data, id } = getState().role.update;

    const permissions = data.permissions.map((i) => {
      return {
        key: i.key,
        value: [+i.create, +i.read, +i.update, +i.delete],
      };
    });
    const postData = {
      name: data.name,
      permissions,
      isChangeCommentRequired: data.isChangeCommentRequired,
    };

    dispatch({ type: UPDATE_ROLE });

    HttpService.put(`api/roles/${id}`, postData)
      .then(() => {
        dispatch(
          AppActions.showMessage({
            message: 'role:UPDATE_ROLE_SUCCESS',
          }),
        );
        dispatch({ type: UPDATE_ROLE_SUCCESS });
      })
      .catch((err) => {
        const { errorFields, errorMessages } = parseError(err);
        dispatch({
          type: UPDATE_ROLE_FAIL,
          payload: { message: errorMessages.join('\n'), error: errorFields },
        });
        dispatch(AppActions.createDialogMessage('error', 'Error', errorMessages));
      });
  };
}

export function clearUpdateForm() {
  return (dispatch) => {
    dispatch({ type: CLEAR_UPDATE_FORM });
  };
}

export function changeRoleUpdateForm(data) {
  return (dispatch) => {
    dispatch({ type: CHANGE_ROLE_UPDATE_FORM, payload: { data } });
  };
}
