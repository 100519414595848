const locale = {
  LIST_TITLE: 'Dispense Tracker Management',
  FILTER_PATIENT_GROUP: 'Patient Group',
  FILTER_PATIENT: 'Patient',
  FILTER_PATIENT_PLACEHOLDER: 'Select Patient',
  FILTER_MEDICATION: 'Medication',
  FILTER_MEDICATION_PLACEHOLDER: 'All Medications',
  FILTER_DATE_FROM: 'From',
  FILTER_DATE_TO: 'To',
  FILTER_SCRIPT_TYPE: 'All Script Types',
  FILTER_SCRIPT_TYPE_LABEL: 'Selected Script Types',
  APPLY_FILTER: 'Apply',
  TABLE_PATIENT_GROUP: 'Patient Group',
  TABLE_PATIENT_FIRST_NAME: 'First Name',
  TABLE_PATIENT_LAST_NAME: 'Last Name',
  TABLE_PATIENT_SCRIPT_NUMBER: 'Script #',
  TABLE_PATIENT_ORIGINAL_SCRIPT_NUMBER: 'Original Script #',
  TABLE_PATIENT_DISPENSE_STATUS: 'Dispense Status',
  TABLE_PATIENT_DRUG_NAME: 'Drug Name',
  TABLE_PATIENT_INITIAL_QTY: 'Qty',
  TABLE_PATIENT_UPDATED_QTY: 'Updated Qty',
  TABLE_PATIENT_MANUALLY_ADDED_QTY: 'Manually Added Qty',
  TABLE_PATIENT_DATE_DISPENSE: 'Dispensed Date',
  TABLE_PATIENT_SCRIPT_STATUS: 'Script Status',
  TABLE_PATIENT_PROFILE_DRUG: 'Profile Drug',
  TABLE_SCRIPT_TYPE: 'Script Type',
  BUTTON_MARK_ACTIVE: 'Mark selected script as active',
  SHOW_CEASED_PROFILE_DRUG: 'Show Ceased Profile Drugs',
  STATUS_NEW: 'New',
  STATUS_PRN: 'PRN',
  STATUS_QUANTITY_CHANGED: 'Quantity Changed',
  STATUS_OWING: 'Owing',
  STATUS_CANCELLED: 'Cancelled',
  BUTTON_IGNORE_SCRIPT: 'Ignore Selected Scripts',
  BUTTON_IMPORT_SCRIPT: 'Import Selected Scripts',
  MESSAGE_MARK_ACTIVE: 'Mark selected script as active successfully',
  MESSAGE_IGNORE_SCRIPT: 'Ignore selected scripts successfully',
  MESSAGE_IMPORT_SCRIPT: 'Import selected scripts successfully',
  MESSAGE_ERROR_DRUG_PROFILE_TITLE: 'Error',
  MESSAGE_ERROR_DRUG_PROFILE_MESSAGE: 'All selected Scripts must have a Profile Drug assigned to be imported',
  LINK_SELECT: 'Select Drug',
};

export default locale;
