import * as DirectionCreateActions from '../actions/create.actions';

const initialState = {
  id: '',
  data: {
    code: '',
    instruction: '',
    description: '',
  },
  submitting: false,
  error: null,
  message: '',
};

const create = (state = initialState, action) => {
  switch (action.type) {
    case DirectionCreateActions.CREATE_DIRECTION: {
      return {
        ...state,
        error: null,
        message: '',
        submitting: true,
      };
    }
    case DirectionCreateActions.CREATE_DIRECTION_SUCCESS: {
      return {
        ...state,
        id: action.payload.directionId,
        submitting: false,
      };
    }
    case DirectionCreateActions.CREATE_DIRECTION_FAIL: {
      return {
        ...state,
        message: action.payload.message,
        error: action.payload.error,
        submitting: false,
      };
    }
    case DirectionCreateActions.CHANGE_DIRECTION_FORM:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload.data,
        },
      };
    case DirectionCreateActions.CLEAR_CREATE_FORM:
      return initialState;
    default: {
      return state;
    }
  }
};

export default create;
