import { HttpService } from 'app/services';
import * as AppActions from 'app/store/actions';
import { parseError } from 'helpers/Error';

export const GET_DOCTOR_DETAIL = '@DOCTOR/GET_DOCTOR_DETAIL';
export const GET_DOCTOR_DETAIL_SUCCESS = '@DOCTOR/GET_DOCTOR_DETAIL_SUCCESS';
export const GET_DOCTOR_DETAIL_FAIL = '@DOCTOR/GET_DOCTOR_DETAIL_FAIL';

export const UPDATE_DOCTOR = '@DOCTOR/UPDATE_DOCTOR';
export const UPDATE_DOCTOR_SUCCESS = '@DOCTOR/UPDATE_DOCTOR_SUCCESS';
export const UPDATE_DOCTOR_FAIL = '@DOCTOR/UPDATE_DOCTOR_FAIL';

export const DELETE_DOCTOR = '@DOCTOR/DELETE_DOCTOR';
export const DELETE_DOCTOR_SUCCESS = '@DOCTOR/DELETE_DOCTOR_SUCCESS';
export const DELETE_DOCTOR_FAIL = '@DOCTOR/DELETE_DOCTOR_FAIL';

export const CHANGE_DOCTOR_UPDATE_FORM = '@DOCTOR/CHANGE_DOCTOR_UPDATE_FORM';

export const CLEAR_UPDATE_FORM = '@DOCTOR/CLEAR_UPDATE_FORM';

export const getDoctorDetail = (doctorId) => {
  return (dispatch) => {
    dispatch({ type: GET_DOCTOR_DETAIL });
    HttpService.get(`api/doctors/${doctorId}`)
      .then((resp) => {
        dispatch({
          type: GET_DOCTOR_DETAIL_SUCCESS,
          payload: { data: resp.data },
        });
      })
      .catch((err) => {
        const { errorFields, errorMessages, errorTitle } = parseError(err);
        dispatch({
          type: GET_DOCTOR_DETAIL_FAIL,
          payload: { message: errorMessages.join('\n'), error: errorFields },
        });
        dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
      });
  };
};

export const updateDoctor = (doctorId) => {
  return (dispatch, getState) => {
    const { doctorForm } = getState().doctor.update;
    dispatch({ type: UPDATE_DOCTOR });
    HttpService.put(`api/doctors/${doctorId}`, doctorForm)
      .then(() => {
        dispatch(
          AppActions.showMessage({
            message: 'doctor:UPDATE_DOCTOR_SUCCESS',
          }),
        );
        dispatch({ type: UPDATE_DOCTOR_SUCCESS });
      })
      .catch((err) => {
        const { errorFields, errorMessages, errorTitle } = parseError(err);
        dispatch({
          type: UPDATE_DOCTOR_FAIL,
          payload: { message: errorMessages.join('\n'), error: errorFields },
        });
        dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
      });
  };
};

export const deleteDoctor = (doctorId) => {
  return (dispatch) => {
    dispatch({ type: DELETE_DOCTOR });
    return HttpService.delete(`api/doctors/${doctorId}`)
      .then(() => {
        dispatch(
          AppActions.showMessage({
            message: 'doctor:DELETE_DOCTOR_SUCCESS',
          }),
        );
        dispatch({ type: DELETE_DOCTOR_SUCCESS });
        return true;
      })
      .catch((err) => {
        const { errorFields, errorMessages } = parseError(err);
        dispatch({
          type: DELETE_DOCTOR_FAIL,
          payload: { message: errorMessages.join('\n'), error: errorFields },
        });
        dispatch(AppActions.createDialogMessage('error', 'Warning', errorMessages));
      });
  };
};

export function clearUpdateForm() {
  return (dispatch) => {
    dispatch({ type: CLEAR_UPDATE_FORM });
  };
}

export function changeDoctorUpdateForm(data) {
  return (dispatch) => {
    dispatch({ type: CHANGE_DOCTOR_UPDATE_FORM, payload: { data } });
  };
}
